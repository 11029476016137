import React, { Component } from 'react'
import Joi from 'joi-browser'
import { WithContext as ReactTags } from 'react-tag-input'
import Form from './../../common/form/form'
import SignUpLeftBar from '../common/signUpLeftBar'
import AlertSuccess from '../../common/alertSuccess'
import AlertError from '../../common/alertError'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'
import './../../../include/css/tags.css'

import {
  doctorUpdate,
  getDoctor,
  loadDoctorProfile,
} from '../../../store/doctor'

import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { getDoctorToken } from '../../services/localStorageServices'
import {
  aboutmeMax,
  msgMax,
  drPersonalDetailFaild,
  profileUpdatedSuccess,
} from '../../common/misc'
import { Link } from 'react-router-dom'
import SubmitBtn from '../../common/form/submitBtn'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import {
  getMisc,
  loadAreasofintrests,
  loadModilityoftherapies,
} from '../../../store/misc'
import HelpText from './helpText'
const KeyCodes = {
  comma: 188,
  enter: 13,
}

const delimiters = [KeyCodes.comma, KeyCodes.enter]
class Practice extends Form {
  state = {
    langs: [
      { Language: 'English' },
      { Language: 'Asami' },
      { Language: 'Bengali' },
      { Language: 'Gujarati' },
      { Language: 'Hindi' },
      { Language: 'Kannada' },
      { Language: 'Malayalam' },
      { Language: 'Marathi' },
      { Language: 'Oriya' },
      { Language: 'Punjabi' },
      { Language: 'Tamil' },
      { Language: 'Telugu' },
      { Language: 'Urdu' },
    ],
    expertise: [],
    suggestions: [
      { id: 'USA', text: 'USA' },
      { id: 'Germany', text: 'Germany' },
      { id: 'Austria', text: 'Austria' },
      { id: 'Costa Rica', text: 'Costa Rica' },
      { id: 'Sri Lanka', text: 'Sri Lanka' },
      { id: 'Thailand', text: 'Thailand' },
    ],
    btnClass: 'btn btn-primary btn-lg',
    loading: false,
    data: {
      aboutme: '',
      message: '',
      lang: [],
      interest: [],
      therapy: [],
    },
    errors: {},
  }

  schema = {
    aboutme: Joi.string()
      .required()
      .error(() => {
        return { message: 'Field is required' }
      }),
    message: Joi.string()
      .required()
      .error(() => {
        return { message: 'Field is required' }
      }),
    lang: Joi.array()
      .items(Joi.string().required())
      .error(() => {
        return { message: 'Language field is required' }
      }),
    interest: Joi.array()
      .items(Joi.string().required())
      .error(() => {
        return { message: 'Area of Intrest field is required' }
      }),
    therapy: Joi.array()
      .items(Joi.string().required())
      .error(() => {
        return { message: 'Preferred Modality of Therapy field is required' }
      }),
  }

  countWords = str => {
    str = str.replace(/(^\s*)|(\s*$)/gi, '')
    str = str.replace(/[ ]{2,}/gi, ' ')
    str = str.replace(/\n /, '\n')
    return str.split(' ').length
  }

  doSubmit = () => {
    const { aboutme, message, lang, interest, therapy } = this.state.data
    const aboutmeCount = this.countWords(aboutme)
    const messageCount = this.countWords(message)
    const { errors } = this.state
    if (!lang || !lang.length) {
      errors.lang = 'Language field is required'
      return this.setState({ errors })
    }
    if (aboutmeCount > 50) {
      errors.aboutme = aboutmeMax
      return this.setState({ errors })
    }

    if (messageCount > 50) {
      errors.message = msgMax
      return this.setState({ errors })
    }

    const { expertise } = this.state
    const data = {
      practice_preferences: {
        about_me: aboutme,
        message_to_patient: message,
        interest: interest,
        expertise: expertise.map(el => el.text),
        therapy: therapy,
        language: this.state.data.lang,
      },
    }
    if (!this.state.loading) {
      this.setState({
        btnClass: 'btn btn-primary btn-lg disabled',
        loading: true,
      })
      this.props.doctorUpdate(data, this.callBack)
    }
  }

  callBack = res => {
    this.setState({
      btnClass: 'btn btn-primary btn-lg',
      loading: false,
    })
    if (res.status === 200) {
      this.props.history.push('/doctor/sign-up/schedule')
      toast(<AlertSuccess message={profileUpdatedSuccess} />)
      this.props.loadDoctorProfile()
    } else {
      toast(<AlertError message={drPersonalDetailFaild} />)
    }
  }

  componentDidMount = () => {
    if (getDoctorToken('auth')) {
      this.props.loadDoctorProfile()
      this.props.loadAreasofintrests()
      this.props.loadModilityoftherapies()
    } else {
      this.props.history.push('/doctor/sign-up/personal-detail')
    }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.profileLoading &&
      this.props.doctorProfile.practice_preferences &&
      this.props.profileLoading !== prevProps.profileLoading
    ) {
      const {
        expertise,
        interest,
        therapy,
        message_to_patient,
        about_me,
        language,
      } = this.props.doctorProfile.practice_preferences

      const data = {
        aboutme: about_me,
        message: message_to_patient,
        lang: language,
        interest: interest,
        therapy: therapy,
      }

      this.setState({
        data,
        expertise: expertise.map(el => ({ id: el, text: el })),
      })
    }
  }

  changeBodyClass = () => {
    document.body.classList.add('active')
  }

  handleDeleteinterest = i => {
    const { interest } = this.state
    this.setState({
      interest: interest.filter((tag, index) => index !== i),
    })
  }

  handleAdditioninterest = tag => {
    this.setState(state => ({ interest: [...state.interest, tag] }))
  }

  handleDeleteexpertise = i => {
    const { expertise } = this.state
    this.setState({
      expertise: expertise.filter((tag, index) => index !== i),
    })
  }

  handleAdditionexpertise = tag => {
    this.setState(state => ({ expertise: [...state.expertise, tag] }))
  }

  handleDeletetherapy = i => {
    const { therapy } = this.state
    this.setState({
      therapy: therapy.filter((tag, index) => index !== i),
    })
  }

  handleAdditiontherapy = tag => {
    this.setState(state => ({ therapy: [...state.therapy, tag] }))
  }
  animatedComponents = makeAnimated()
  customStyles = {
    indicatorSeparator: styles => ({ display: 'none' }),
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#621ac0',
      },

      'color': state.isSelected ? '#621ac0' : 'black',
      'backgroundColor': state.isSelected
        ? 'rgb(95, 50, 187, 10%)'
        : provided.backgroundColor,
      //backgroundColor: state.isSelected ? "rgb(95, 50, 187, 10%)" : "white",
    }),
    control: (base, state) => ({
      ...base,
      'borderColor': state.isFocused ? '#6119c0' : '#bdbdbd',
      'boxShadow': '0 !important',
      '&:hover': {
        borderColor: '#6119c0',
      },
      '&:focus': {
        borderColor: '#6119c0',
      },
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#6119c0',
      },
    }),
  }

  loadOptions = (inputValue, callback) => {
    callback(
      this.state.langs.map(e => ({ label: e.Language, value: e.Language }))
    )
  }

  handleInputChange = newValue => {
    alert(1)
  }

  render() {
    const { areasofintrests, modilityoftherapies } = this.props.getMisc
    const { interest, expertise, therapy } = this.state
    return (
      <React.Fragment>
        <ReactTooltip className='testPurple' id='practice-tooltip' multiline={true} />

        <SignUpLeftBar />
        <div className='main-container'>
          <div className='main-head'>
            <button className='nav-toggler' onClick={this.changeBodyClass}>
              <i className='fas fa-bars'></i>
            </button>
            <a href='#' className='brand-logo'>
              <img src='include/images/yes-mindy-logo.svg' alt='' />
            </a>
          </div>
          <HelpText />
          <div className='container ml-0'>
            <div className='main-heading'>
              <h1>Practice Preferences</h1>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className='main-content'>
                <div className='row'>
                  <div className='col-xl-10'>
                    <div className='form-group custom-input'>
                      {areasofintrests.length > 0 && (
                        <Select
                          components={{
                            ...this.animatedComponents,
                            IndicatorSeparator: () => null,
                          }}
                          closeMenuOnSelect={
                            this.state.data.interest.length >= 2
                          }
                          onChange={e => {
                            const data = { ...this.state.data }
                            if (e && e.length < 6) {
                              data.interest = e.map(e => e.value)
                            }
                            if (!e) data.interest = []
                            this.setState({ data })
                          }}
                          isMulti
                          placeholder='Area of Interest'
                          styles={this.customStyles}
                          className='form-groupxx'
                          options={areasofintrests.map(e => ({
                            label: e.name,
                            value: e.name,
                          }))}
                          value={(this.state.data.interest || []).map(e => ({
                            value: e,
                            label: e,
                          }))}
                          defaultValue={(this.state.data.interest || []).map(
                            e => ({
                              value: e,
                              label: e,
                            })
                          )}
                        />
                      )}
                      {this.state.errors.interest && (
                        <label class='error'>
                          {this.state.errors.interest}
                        </label>
                      )}
                    </div>
                  </div>
                  <div className='col-xl-1'>
                    <i
                      class='fa fa-question-circle'
                      data-tooltip-id='practice-tooltip'
                      data-tooltip-content='Area Of Interest - eg:- Disorders/Population Only.                      '
                      aria-hidden='true'></i>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-xl-10'>
                    <ReactTags
                      classNames={{
                        tagInput: 'form-group custom-input',
                        tagInputField: 'form-control',
                      }}
                      inline
                      placeholder='Area of Expertise (press enter or comma after every entry)'
                      inputFieldPosition='inline'
                      tags={expertise}
                      suggestions={[{ id: 'Hello', text: 'Hello' }]}
                      handleDelete={this.handleDeleteexpertise}
                      handleAddition={this.handleAdditionexpertise}
                      delimiters={delimiters}
                    />
                    {/* <label className="tagLabel">
                      You can enter multiple values. Please press enter key
                      after adding the values
                    </label> */}
                  </div>
                  <div className='col-xl-1'>
                    <i
                      class='fa fa-question-circle'
                      data-tooltip-id='practice-tooltip'
                      data-tooltip-content='Area Of Expertise (You may choose only three) - Disorder/population (Experience/certification/Pdf/Super-speciality)..                      '
                      aria-hidden='true'></i>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-xl-10'>
                    <div className='form-group custom-input'>
                      {modilityoftherapies.length > 0 && (
                        <Select
                          closeMenuOnSelect={
                            this.state.data.therapy.length >= 2
                          }
                          onChange={e => {
                            const data = { ...this.state.data }
                            if (e && e.length < 4) {
                              data.therapy = e.map(e => e.value)
                            }
                            if (!e) data.therapy = []
                            this.setState({ data })
                          }}
                          isMulti
                          placeholder='Preferred Modality Of Therapy'
                          styles={this.customStyles}
                          className='form-groupxx'
                          components={this.animatedComponents}
                          options={modilityoftherapies.map(e => ({
                            label: e.name,
                            value: e.name,
                          }))}
                          value={(this.state.data.therapy || []).map(e => ({
                            value: e,
                            label: e,
                          }))}
                          defaultValue={(this.state.data.therapy || []).map(
                            e => ({
                              value: e,
                              label: e,
                            })
                          )}
                        />
                      )}
                      {this.state.errors.therapy && (
                        <label class='error'>{this.state.errors.therapy}</label>
                      )}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xl-10'>
                    <div className='form-group custom-input'>
                      {this.props.doctorProfile.practice_preferences && (
                        <Select
                          closeMenuOnSelect={false}
                          onChange={e => {
                            const data = { ...this.state.data }
                            if (e) {
                              console.log('lenggg', e.length)
                              data.lang = e.map(e => e.value)
                            }
                            if (!e) data.lang = []
                            this.setState({ data })
                          }}
                          isMulti
                          placeholder='Language'
                          styles={this.customStyles}
                          className='form-groupxx'
                          components={this.animatedComponents}
                          options={this.state.langs.map(e => ({
                            label: e.Language,
                            value: e.Language,
                          }))}
                          defaultValue={(this.state.data.lang || []).map(e => ({
                            value: e,
                            label: e,
                          }))}
                          value={(this.state.data.lang || []).map(e => ({
                            value: e,
                            label: e,
                          }))}
                        />
                      )}
                      {this.state.errors.lang && (
                        <label class='error'>{this.state.errors.lang}</label>
                      )}
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xl-10'>
                    {this.renderInput(
                      'aboutme',
                      'A Short description about you as a clinician'
                    )}
                  </div>
                  <div className='col-xl-1'>
                    <i
                      class='fa fa-question-circle'
                      data-html={true}
                      data-tooltip-id='practice-tooltip'
                      data-tooltip-content='<p>For example:</p>

                      <p>1. People feel intimidated by psychiatrists initially and I understand your situation. I’m sure you’ll find me empathetic even with a prescription pad, so try me.</p>
                      
                      <p>2. I am inspired by improving the world via mental health. I put myself in your shoes and see the world from your perspective, so we converse on a personal level.</p>'
                      aria-hidden='true'></i>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-xl-10'>
                    {this.renderInput(
                      'message',
                      'Your expression on mental health issues/wellness for patients to read'
                    )}
                  </div>
                  <div className='col-xl-1'>
                    <i
                      class='fa fa-question-circle'
                      data-html={true}
                      data-tooltip-id='practice-tooltip'
                      data-tooltip-content='<p>For example:</p>

                      <p>1. I believe everyone deserves to be heard when they share their story, journey, and thoughts with a supportive voice to showcase a ray of light in the darkest times.</p>
                      
                      <p>2. Hi, thank you for taking the time to view my profile. Let’s turn a new, positive chapter by bringing light in the darkness, which begins with this very step. I look forward to hearing from you.</p>'
                      aria-hidden='true'></i>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-xl-3'>
                    <div className='form-group'>
                      <SubmitBtn
                        label='Save & Continue'
                        loading={this.state.loading}
                        btnClass={this.state.btnClass}
                      />
                    </div>
                  </div>
                  <div className='col-xl-5'>
                    <div className='form-group'>
                      <Link
                        className='add-more-field mb-4 p10-top'
                        to='/doctor/sign-up/schedule'>
                        Skip and fill later
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  profileLoading: getDoctor(state).profileLoading,
  doctorProfile: getDoctor(state).profile,
  getMisc: getMisc(state),
})

const mapDispatchToProps = dispatch => ({
  doctorUpdate: (data, callback) => dispatch(doctorUpdate(data, callback)),
  loadDoctorProfile: () => dispatch(loadDoctorProfile()),
  loadAreasofintrests: () => dispatch(loadAreasofintrests()),
  loadModilityoftherapies: () => dispatch(loadModilityoftherapies()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Practice)
