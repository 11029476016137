import React, { useEffect, useState } from 'react'
import AgoraRTC from 'agora-rtc-sdk-ng'


function LoadScreenShareCall(props) {
    const APP_ID = process.env.REACT_APP_KEY
    const screenClient = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' })
    const [remoteVideo, setRemoteVideo] = useState();

    const handleUserPublish = async (user, mediaType) => {
          // Subscribe to the remote user when the SDK triggers the "user-published" event.
          await screenClient.subscribe(user, mediaType)
        
          let channelParameters = {}
          // Subscribe and play the remote video in the container If the remote user publishes a video track.
          if (mediaType == 'video') {
            // Retrieve the remote video track.
            channelParameters.remoteVideoTrack = user.videoTrack
            // Retrieve the remote audio track.
            channelParameters.remoteAudioTrack = user.audioTrack
            // Save the remote user id for reuse.
            channelParameters.remoteUid = user.uid.toString()
            //setScreenRemoteStreams([channelParameters]);
          }
          // Subscribe and play the remote audio track If the remote user publishes the audio track only.
          if (mediaType == 'audio' && user.audioTrack) {
            // Get the RemoteAudioTrack object in the AgoraRTCRemoteUser object.
            channelParameters.remoteAudioTrack = user.audioTrack
            // Play the remote audio track. No need to pass any DOM element.
            channelParameters.remoteAudioTrack.play()
          }
      }
    screenClient.on('user-published', handleUserPublish);
    screenClient.on('user-unpublished', user => {
        setRemoteVideo(null)
    })

    const loadScreenShareCall = async(channel, token, uid) =>{
        try{ 
            await screenClient.join(APP_ID, channel, token, uid)
            let localScreenTrack = await AgoraRTC.createScreenVideoTrack({
                encoderConfig: "720p"
            }, "auto")
            let audioTrack = await AgoraRTC.createMicrophoneAudioTrack({
                encoderConfig: "music_standard"
              });
            let screenAudioTrack;
            let screenVideoTrack;
            if (localScreenTrack instanceof Array) {
                screenVideoTrack = localScreenTrack[0]
                screenAudioTrack = localScreenTrack[1]
              }
              else {
                screenVideoTrack = localScreenTrack
              }
            // Publish the local audio and video tracks in the channel.
            if (screenAudioTrack == null) {
                await screenClient.publish([screenVideoTrack, audioTrack]);
            }else{
                await screenClient.publish([screenVideoTrack, audioTrack, screenAudioTrack]);
            }
            //await screenClient.publish([localScreenTrack])
            screenVideoTrack.on("track-ended", props.shareScreen);
            setRemoteVideo(screenVideoTrack);
            props.setClient(screenClient);
            let localVideoTrack = props.localMedia[1];
            //props.setLocalMedia([props.localMedia[0],localScreenTrack])
            let localVideoContainer = document.getElementById('agora_local')
            localVideoContainer.innerHTML = ''
            localVideoTrack.play(localVideoContainer);
        }
        catch(error){
          console.log(error);
        }
    }
    useEffect(()=>{
        if (props.channel && props.userToken) {
            loadScreenShareCall(props.channel, props.userToken.token , props.userToken.uid);
        }
    },[props.channel, props.userToken])

    useEffect(()=>{
        if(remoteVideo){
            let localPlayerContainer = document.getElementById('agora_remote-video')
            localPlayerContainer.innerHTML = ''
            remoteVideo.play(localPlayerContainer);
        }
    },[remoteVideo])
    return (
        <>
            {!remoteVideo && (
                <>
                    <div class='doc-image'></div>
                    <h4>Connecting...</h4>
                </>
            )}
                  
            {remoteVideo && 
                (
                    <div
                        class='doc-image'
                        id='agora_remote-video'>

                    </div>
                )
            }
        </>
    )
}
  
export default LoadScreenShareCall;