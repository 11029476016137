import React, { Component, createRef } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import LoadingBar from 'react-top-loading-bar'
import { loadFieldType } from '../../../store/misc'
import {
  getPatient,
  loadPatientProfile,
  setInitialData,
  setShowSuccessData,
} from '../../../store/patient'
import PatientDashboard from '../profile/patientDashboard'
import PersonalDetails from '../profile/personalDetails'
import PostAppointment from './postAppointment'
import correct from '../../../include/images/correct.png'

import { Modal } from 'react-bootstrap'

const ref = createRef(null)

class MainDashboard extends Component {
  state = { loading: true, showModel: true }

  toggleModel = () => this.props.setShowSuccessData(false)

  componentDidMount = () => {
    this.setState({ loading: true })
    ref.current.continuousStart()
    this.props.loadPatientProfile(res => {
      if (res.status == 401) {
        this.setState({ loading: true })
      } else {
        const patData = res.data.data
        const repName =
          patData && patData.type == 2
            ? patData.guardian_first_name
            : patData.nominated_representative_name
        if (((repName && !patData.self) || patData.self) && patData.sex) {
          this.props.setInitialData(false)
          this.setState({ loading: false })
        } else {
          this.setState({ loading: false })
        }
      }
    })
    this.props.loadFieldType({ filter: true })

    this.id = setTimeout(() => {
      this.toggleModel()
    }, 3000)
  }

  componentWillUnmount = () => {
    clearTimeout(this.id)
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!this.props.loading && this.props.loading !== prevProps.loading) {
      ref.current.complete()
      this.setState({ loading: false })
    }
  }

  render() {
    const repName =
      this.props.patientProfile && this.props.patientProfile.type == 2
        ? this.props.patientProfile.guardian_first_name
        : this.props.patientProfile.nominated_representative_name
    console.log("this.props.patientProfile.self", this.props.patientProfile.self);
    console.log("repName", repName);
    console.log("this.props.initialData",this.props.initialData);
    console.log("this.props.patientProfile.Psychiatrist",this.props.patientProfile.Psychiatrist);
    console.log("this.props.patientProfile.Psychologist",this.props.patientProfile.Psychologist);
    return (
      <>
        <Modal
          show={this.props.showSuccess}
          onHide={this.toggleModel}
          centered
          className='modal fade appointment-success-modal'>
          <div className=''>
            <img src={correct} alt='correct' />
            <h4 className=''>Your Appointment is Booked</h4>
            <p className='journey'>Journey To Wellness Begins</p>
            <p>Please make sure to fill your details correctly</p>
          </div>
        </Modal>
        {console.log("repName", repName)}
        <LoadingBar color='#582bb8' ref={ref} />
        {!this.state.loading && (
          <>
            {(!repName && !this.props.patientProfile.self) ||
            (!this.props.patientProfile.self && this.props.initialData) ||
            this.props.initialData ? (
              <PersonalDetails
                setInitial={() => {
                  this.props.setInitialData(false)
                }}
                initial={this.props.initialData}
              />
            ) : null}
            {((repName && !this.props.patientProfile.self) ||
              this.props.patientProfile.self) &&
              !this.props.initialData &&
              (this.props.patientProfile.Psychiatrist ||
                this.props.patientProfile.Psychologist) && <PostAppointment />}
            {((repName && !this.props.patientProfile.self) ||
              this.props.patientProfile.self) &&
              !this.props.initialData &&
              !this.props.patientProfile.Psychiatrist &&
              !this.props.patientProfile.Psychologist && <PatientDashboard />}
          </>
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  patientProfile: getPatient(state).profile,
  patientHistory: getPatient(state).history,
  loading: getPatient(state).loading,
  initialData: getPatient(state).initial,
  showSuccess: getPatient(state).showSuccess,
})
const mapDispatchToProps = dispatch => ({
  loadPatientProfile: callback => dispatch(loadPatientProfile(callback)),
  loadFieldType: param => dispatch(loadFieldType(param)),
  setInitialData: param => dispatch(setInitialData(param)),
  setShowSuccessData: param => dispatch(setShowSuccessData(param)),
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MainDashboard)
)
