import React, { Component } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import AlertSuccess from '../../common/alertSuccess'
import AlertError from '../../common/alertError'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import {
  doctorUpdate,
  getDoctor,
  loadDoctorProfile,
} from '../../../store/doctor'
import { drPersonalDetailFaild, profileUpdatedSuccess } from '../../common/misc'

import SignUpLeftBar from '../common/signUpLeftBar'
import { getDoctorToken } from '../../services/localStorageServices'
import SelectOptions from '../common/selectOptions'
import SubmitBtn from '../../common/form/submitBtn'
import HelpText from './helpText'

class Schedule extends Component {
  state = {
    btnClass: 'btn btn-primary btn-lg',
    loading: false,
    data: {
      sun: false,
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
    },
    timeSlot: {
      sun: [[{ id: '0000' }, { id: '0200' }]],
      mon: [[{ id: '0000' }, { id: '0200' }]],
      tue: [[{ id: '0000' }, { id: '0200' }]],
      wed: [[{ id: '0000' }, { id: '0200' }]],
      thu: [[{ id: '0000' }, { id: '0200' }]],
      fri: [[{ id: '0000' }, { id: '0200' }]],
      sat: [[{ id: '0000' }, { id: '0200' }]],
    },

    onlineClinic: {
      sun: [
        { online: true, clinic: false },
        { online: true, clinic: false },
        { online: true, clinic: false },
        { online: true, clinic: false },
      ],
      mon: [
        { online: true, clinic: false },
        { online: true, clinic: false },
        { online: true, clinic: false },
        { online: true, clinic: false },
      ],
      tue: [
        { online: true, clinic: false },
        { online: true, clinic: false },
        { online: true, clinic: false },
        { online: true, clinic: false },
      ],
      wed: [
        { online: true, clinic: false },
        { online: true, clinic: false },
        { online: true, clinic: false },
        { online: true, clinic: false },
      ],
      thu: [
        { online: true, clinic: false },
        { online: true, clinic: false },
        { online: true, clinic: false },
        { online: true, clinic: false },
      ],
      fri: [
        { online: true, clinic: false },
        { online: true, clinic: false },
        { online: true, clinic: false },
        { online: true, clinic: false },
      ],
      sat: [
        { online: true, clinic: false },
        { online: true, clinic: false },
        { online: true, clinic: false },
        { online: true, clinic: false },
      ],
    },
    time: { value: '25', label: '25 Minutes' },
    onlineFee: 0,
    clinicFee: 0,
    alldays: false,
  }

  timeDropdownNew = [
    { value: '0000', label: '12:00 AM' },
    { value: '0030', label: '12:30 AM' },
    { value: '0100', label: '01:00 AM' },
    { value: '0130', label: '01:30 AM' },
    { value: '0200', label: '02:00 AM' },
    { value: '0230', label: '02:30 AM' },
    { value: '0300', label: '03:00 AM' },
    { value: '0330', label: '03:30 AM' },
    { value: '0400', label: '04:00 AM' },
    { value: '0430', label: '04:30 AM' },
    { value: '0500', label: '05:00 AM' },
    { value: '0530', label: '05:30 AM' },
    { value: '0600', label: '06:00 AM' },
    { value: '0630', label: '06:30 AM' },
    { value: '0700', label: '07:00 AM' },
    { value: '0730', label: '07:30 AM' },
    { value: '0800', label: '08:00 AM' },
    { value: '0830', label: '08:30 AM' },
    { value: '0900', label: '09:00 AM' },
    { value: '0930', label: '09:30 AM' },
    { value: '1000', label: '10:00 AM' },
    { value: '1030', label: '10:30 AM' },
    { value: '1100', label: '11:00 AM' },
    { value: '1130', label: '11:30 AM' },
    { value: '1200', label: '12:00 PM' },
    { value: '1230', label: '12:30 PM' },
    { value: '1300', label: '01:00 PM' },
    { value: '1330', label: '01:30 PM' },
    { value: '1400', label: '02:00 PM' },
    { value: '1430', label: '02:30 PM' },
    { value: '1500', label: '03:00 PM' },
    { value: '1530', label: '03:30 PM' },
    { value: '1600', label: '04:00 PM' },
    { value: '1630', label: '04:30 PM' },
    { value: '1700', label: '05:00 PM' },
    { value: '1730', label: '05:30 PM' },
    { value: '1800', label: '06:00 PM' },
    { value: '1830', label: '06:30 PM' },
    { value: '1900', label: '07:00 PM' },
    { value: '1930', label: '07:30 PM' },
    { value: '2000', label: '08:00 PM' },
    { value: '2030', label: '08:30 PM' },
    { value: '2100', label: '09:00 PM' },
    { value: '2130', label: '09:30 PM' },
    { value: '2200', label: '10:00 PM' },
    { value: '2230', label: '10:30 PM' },
    { value: '2300', label: '11:00 PM' },
    { value: '2330', label: '11:30 PM' },
    { value: '2400', label: '12:00 AM' },
  ]

  doSubmit = e => {
    e.preventDefault()

    //return;
    const { timeSlot, data, onlineFee, clinicFee, time, onlineClinic } =
      this.state
    const { sun, mon, tue, wed, thu, fri, sat } = data

    const payLoad = {
      schedules: {
        onlineFee,
        clinicFee,
        time: time.value,
      },
    }
    payLoad.schedules.sun = !sun
      ? []
      : timeSlot.sun.map((el, i) => ({
          start_time: el[0].id,
          end_time: el[1].id,
          is_online: onlineClinic.sun[i].online,
          is_clinic: onlineClinic.sun[i].clinic,
        }))

    payLoad.schedules.mon = !mon
      ? []
      : timeSlot.mon.map((el, i) => ({
          start_time: el[0].id,
          end_time: el[1].id,
          is_online: onlineClinic.mon[i].online,
          is_clinic: onlineClinic.mon[i].clinic,
        }))
    payLoad.schedules.tue = !tue
      ? []
      : timeSlot.tue.map((el, i) => ({
          start_time: el[0].id,
          end_time: el[1].id,
          is_online: onlineClinic.tue[i].online,
          is_clinic: onlineClinic.tue[i].clinic,
        }))
    payLoad.schedules.wed = !wed
      ? []
      : timeSlot.wed.map((el, i) => ({
          start_time: el[0].id,
          end_time: el[1].id,
          is_online: onlineClinic.wed[i].online,
          is_clinic: onlineClinic.wed[i].clinic,
        }))
    payLoad.schedules.thu = !thu
      ? []
      : timeSlot.thu.map((el, i) => ({
          start_time: el[0].id,
          end_time: el[1].id,
          is_online: onlineClinic.thu[i].online,
          is_clinic: onlineClinic.thu[i].clinic,
        }))
    payLoad.schedules.fri = !fri
      ? []
      : timeSlot.fri.map((el, i) => ({
          start_time: el[0].id,
          end_time: el[1].id,
          is_online: onlineClinic.fri[i].online,
          is_clinic: onlineClinic.fri[i].clinic,
        }))
    payLoad.schedules.sat = !sat
      ? []
      : timeSlot.sat.map((el, i) => ({
          start_time: el[0].id,
          end_time: el[1].id,
          is_online: onlineClinic.sat[i].online,
          is_clinic: onlineClinic.sat[i].clinic,
        }))

    console.log('payLoad', payLoad)
    if (!this.state.loading) {
      this.setState({
        btnClass: 'btn btn-primary btn-lg disabled',
        loading: true,
      })
      this.props.doctorUpdate(payLoad, this.callBack)
    }
  }

  callBack = res => {
    this.setState({
      btnClass: 'btn btn-primary btn-lg',
      loading: false,
    })
    if (res.status === 200) {
      this.props.history.push('/doctor/sign-up/bank')
      toast(<AlertSuccess message={profileUpdatedSuccess} />)
      this.props.loadDoctorProfile()
    } else {
      toast(<AlertError message={drPersonalDetailFaild} />)
    }
  }

  DayChange = e => {
    const { data, timeSlot } = this.state
    data[e.target.name] = !data[e.target.name]
    timeSlot[e.target.name] = [[{ id: '0000' }, { id: '0200' }]]
    this.setState({ data, timeSlot })
    console.log(e.target.name)
  }

  addMore = (e, day) => {
    e.preventDefault()
    const { timeSlot } = this.state
    timeSlot[day][timeSlot[day].length] = [{ id: '0000' }, { id: '0200' }]
    console.log(timeSlot)
    this.setState({ timeSlot })
  }

  removeMore = (e, day) => {
    e.preventDefault()
    const { timeSlot } = this.state
    timeSlot[day].pop()
    this.setState({ timeSlot })
  }

  componentDidMount() {
    if (getDoctorToken('auth')) {
      this.props.loadDoctorProfile()
    } else {
      this.props.history.push('/doctor/sign-up/personal-detail')
    }
    //window.$(".niceSelect").niceSelect();
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (
      !this.props.profileLoading &&
      this.props.profileLoading !== prevProps.profileLoading &&
      this.props.doctorProfile.schedules.time
    ) {
      const data = {}
      const timeSlot = {}
      const onlineClinic = {}
      const onlineClinicDays = [
        { online: true, clinic: false },
        { online: true, clinic: false },
        { online: true, clinic: false },
        { online: true, clinic: false },
      ]
      const { schedules } = this.props.doctorProfile
      data.sun = schedules.sun.length ? true : false
      data.mon = schedules.mon.length ? true : false
      data.tue = schedules.tue.length ? true : false
      data.wed = schedules.wed.length ? true : false
      data.thu = schedules.thu.length ? true : false
      data.fri = schedules.fri.length ? true : false
      data.sat = schedules.sat.length ? true : false

      timeSlot.sun = schedules.sun.length
        ? schedules.sun.map(el => [{ id: el.start_time }, { id: el.end_time }])
        : false

      onlineClinic.sun = schedules.sun.length
        ? onlineClinicDays.map((el, i) => ({
            online: schedules.sun[i] ? schedules.sun[i].is_online : true,
            clinic: schedules.sun[i] ? schedules.sun[i].is_clinic : true,
          }))
        : onlineClinicDays

      timeSlot.mon = schedules.mon.length
        ? schedules.mon.map(el => [{ id: el.start_time }, { id: el.end_time }])
        : false

      onlineClinic.mon = schedules.mon.length
        ? onlineClinicDays.map((el, i) => ({
            online: schedules.mon[i] ? schedules.mon[i].is_online : true,
            clinic: schedules.mon[i] ? schedules.mon[i].is_clinic : true,
          }))
        : onlineClinicDays

      timeSlot.tue = schedules.tue.length
        ? schedules.tue.map(el => [{ id: el.start_time }, { id: el.end_time }])
        : false
      onlineClinic.tue = schedules.tue.length
        ? onlineClinicDays.map((el, i) => ({
            online: schedules.tue[i] ? schedules.tue[i].is_online : true,
            clinic: schedules.tue[i] ? schedules.tue[i].is_clinic : true,
          }))
        : onlineClinicDays
      timeSlot.wed = schedules.wed.length
        ? schedules.wed.map(el => [{ id: el.start_time }, { id: el.end_time }])
        : false

      onlineClinic.wed = schedules.wed.length
        ? onlineClinicDays.map((el, i) => ({
            online: schedules.wed[i] ? schedules.wed[i].is_online : true,
            clinic: schedules.wed[i] ? schedules.wed[i].is_clinic : true,
          }))
        : onlineClinicDays
      timeSlot.thu = schedules.thu.length
        ? schedules.thu.map(el => [{ id: el.start_time }, { id: el.end_time }])
        : false

      onlineClinic.thu = schedules.thu.length
        ? onlineClinicDays.map((el, i) => ({
            online: schedules.thu[i] ? schedules.thu[i].is_online : true,
            clinic: schedules.thu[i] ? schedules.thu[i].is_clinic : true,
          }))
        : onlineClinicDays
      timeSlot.fri = schedules.fri.length
        ? schedules.fri.map(el => [{ id: el.start_time }, { id: el.end_time }])
        : false

      onlineClinic.fri = schedules.fri.length
        ? onlineClinicDays.map((el, i) => ({
            online: schedules.fri[i] ? schedules.fri[i].is_online : true,
            clinic: schedules.fri[i] ? schedules.fri[i].is_clinic : true,
          }))
        : onlineClinicDays
      timeSlot.sat = schedules.sat.length
        ? schedules.sat.map(el => [{ id: el.start_time }, { id: el.end_time }])
        : false

      onlineClinic.sat = schedules.sat.length
        ? onlineClinicDays.map((el, i) => ({
            online: schedules.sat[i] ? schedules.sat[i].is_online : true,
            clinic: schedules.sat[i] ? schedules.sat[i].is_clinic : true,
          }))
        : onlineClinicDays

      this.setState({
        data,
        timeSlot,
        onlineClinic,
        onlineFee: schedules.onlineFee,
        clinicFee: schedules.clinicFee,
        time: {
          value: schedules.time,
          label: schedules.time + ' Minutes',
        },
      })
    }
  }

  selectChangeOption = e => {
    const { attrind, attrnum, value, name } = e
    const { timeSlot } = this.state
    timeSlot[name][attrind][attrnum].id = value
    this.setState({ timeSlot, alldays: false })
  }

  checkOnline = (i, day) => {
    const onlineClinic = { ...this.state.onlineClinic }
    onlineClinic[day][i].online = !onlineClinic[day][i].online
    if (!onlineClinic[day][i].online) onlineClinic[day][i].clinic = true
    this.setState({ onlineClinic })
    console.log(onlineClinic, i, day)
  }

  checkClinic = (i, day) => {
    const onlineClinic = { ...this.state.onlineClinic }
    onlineClinic[day][i].clinic = !onlineClinic[day][i].clinic
    if (!onlineClinic[day][i].clinic) onlineClinic[day][i].online = true
    this.setState({ onlineClinic })
    console.log(onlineClinic, i, day)
  }

  changeBodyClass = () => {
    document.body.classList.add('active')
  }

  changeTime = e => {
    this.setState({ time: e })
  }

  render() {
    console.log('this.state.onlineClinic', this.state.onlineClinic)
    return (
      <React.Fragment>
        <ReactTooltip className='testPurple' id='schedule2-tooltip'/>
        <SignUpLeftBar />
        <div className='main-container'>
          <div className='main-head'>
            <button className='nav-toggler' onClick={this.changeBodyClass}>
              <i className='fas fa-bars'></i>
            </button>
            <a href='#' className='brand-logo'>
              <img src='include/images/yes-mindy-logo.svg' alt='' />
            </a>
          </div>
          <HelpText />
          <div className='container ml-0'>
            <div className='main-heading'>
              <h1>Clinic Schedule</h1>
            </div>
            <div className='main-content'>
              <div className='row mb-3'>
                <div className='col-xl-7'></div>
                <div className='col-xl-5 text-xl-right mt-3 mt-xl-0'>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      value={this.state.alldays}
                      id='flexCheckDefault'
                      onChange={() => {
                        if (!this.state.alldays) {
                          const { timeSlot, onlineClinic, data } = this.state
                          const { mon } = timeSlot
                          const { mon: MononlineClinic } = onlineClinic
                          const stringVal = JSON.stringify(mon)
                          const stringValonlineClinic =
                            JSON.stringify(MononlineClinic)

                          data.tue = data.mon
                          data.wed = data.mon
                          data.thu = data.mon
                          data.fri = data.mon
                          data.sat = data.mon
                          data.sun = data.mon

                          onlineClinic.tue = JSON.parse(stringValonlineClinic)
                          onlineClinic.wed = JSON.parse(stringValonlineClinic)
                          onlineClinic.thu = JSON.parse(stringValonlineClinic)
                          onlineClinic.fri = JSON.parse(stringValonlineClinic)
                          onlineClinic.sat = JSON.parse(stringValonlineClinic)
                          onlineClinic.sun = JSON.parse(stringValonlineClinic)

                          timeSlot.tue = JSON.parse(stringVal)
                          timeSlot.wed = JSON.parse(stringVal)
                          timeSlot.thu = JSON.parse(stringVal)
                          timeSlot.fri = JSON.parse(stringVal)
                          timeSlot.sat = JSON.parse(stringVal)
                          timeSlot.sun = JSON.parse(stringVal)
                          this.setState({
                            alldays: !this.state.alldays,
                            timeSlot,
                          })
                        } else {
                          this.setState({
                            alldays: !this.state.alldays,
                          })
                        }
                      }}
                      checked={this.state.alldays}
                    />
                    <label
                      className='form-check-label'
                      htmlFor='flexCheckDefault'>
                      Apply the same for all days
                    </label>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-xl-12 mb-2'>
                  <div class='schedule-box d-xl-flex flex-row'>
                    <div className='schedule-day'>
                      <div className='form-check'>
                        <input
                          name='mon'
                          onChange={this.DayChange}
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexCheckMon'
                          checked={this.state.data.mon}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='flexCheckMon'>
                          Mon
                        </label>
                      </div>
                    </div>
                    <div class='schedule-time-outer d-xl-flex flex-column'>
                      <div class='d-flex flex-column flex-wrap'>
                        {this.state.data.mon ? (
                          <>
                            {(this.state.timeSlot.mon || []).map((el, i) => (
                              <div class='schedule-time form-inline'>
                                <div class='form-group'>
                                  <SelectOptions
                                    name='mon'
                                    attrind={i}
                                    attrnum='0'
                                    onChange={this.selectChangeOption}
                                    value={this.timeDropdownNew.find(
                                      es =>
                                        es.value ===
                                        this.state.timeSlot.mon[i][0].id
                                    )}
                                    nameCl='small'
                                    options={this.timeDropdownNew}
                                  />

                                  <SelectOptions
                                    name='mon'
                                    attrind={i}
                                    attrnum='1'
                                    onChange={this.selectChangeOption}
                                    value={this.timeDropdownNew.find(
                                      es =>
                                        es.value ===
                                        this.state.timeSlot.mon[i][1].id
                                    )}
                                    nameCl='small'
                                    options={this.timeDropdownNew}
                                  />
                                </div>

                                <div class='form-check '>
                                  <input
                                    name='mon'
                                    class='form-check-input'
                                    type='checkbox'
                                    id={'monOnline' + i}
                                    checked={
                                      this.state.onlineClinic['mon'][i].online
                                    }
                                    onChange={() => this.checkOnline(i, 'mon')}
                                  />
                                  <label
                                    class='form-check-label'
                                    for={'monOnline' + i}>
                                    Online
                                  </label>
                                </div>
                                <div class='form-check '>
                                  <input
                                    name='mon'
                                    class='form-check-input'
                                    type='checkbox'
                                    id={'monClinic' + i}
                                    checked={
                                      this.state.onlineClinic['mon'][i].clinic
                                    }
                                    onChange={() => this.checkClinic(i, 'mon')}
                                  />
                                  <label
                                    class='form-check-label'
                                    for={'monClinic' + i}>
                                    Clinic
                                  </label>
                                </div>

                                {this.state.timeSlot.mon.length > 1 &&
                                  i === this.state.timeSlot.mon.length - 1 && (
                                    <a
                                      href='#'
                                      className='remove-field m-0'
                                      onClick={e => this.removeMore(e, 'mon')}>
                                      - remove
                                    </a>
                                  )}
                              </div>
                            ))}
                          </>
                        ) : (
                          <label className='grey'>
                            Not Available on Mondays
                          </label>
                        )}
                      </div>
                      {this.state.data.mon &&
                        this.state.timeSlot.mon.length < 4 && (
                          <a
                            href='#'
                            className='add-more-field'
                            onClick={e => this.addMore(e, 'mon')}>
                            + Add more
                          </a>
                        )}
                    </div>
                  </div>

                  <div class='schedule-box d-xl-flex flex-row'>
                    <div className='schedule-day'>
                      <div className='form-check'>
                        <input
                          name='tue'
                          onChange={this.DayChange}
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexChecktue'
                          checked={this.state.data.tue}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='flexChecktue'>
                          Tue
                        </label>
                      </div>
                    </div>
                    <div class='schedule-time-outer d-xl-flex flex-column'>
                      <div class='d-flex flex-column flex-wrap'>
                        {this.state.data.tue ? (
                          <>
                            {(this.state.timeSlot.tue || []).map((el, i) => (
                              <div class='schedule-time form-inline'>
                                <div class='form-group'>
                                  <SelectOptions
                                    name='tue'
                                    attrind={i}
                                    attrnum='0'
                                    onChange={this.selectChangeOption}
                                    value={this.timeDropdownNew.find(
                                      es =>
                                        es.value ===
                                        this.state.timeSlot.tue[i][0].id
                                    )}
                                    nameCl='small'
                                    options={this.timeDropdownNew}
                                  />

                                  <SelectOptions
                                    name='tue'
                                    attrind={i}
                                    attrnum='1'
                                    onChange={this.selectChangeOption}
                                    value={this.timeDropdownNew.find(
                                      es =>
                                        es.value ===
                                        this.state.timeSlot.tue[i][1].id
                                    )}
                                    nameCl='small'
                                    options={this.timeDropdownNew}
                                  />
                                </div>

                                <div class='form-check '>
                                  <input
                                    name='tue'
                                    class='form-check-input'
                                    type='checkbox'
                                    id={'tueOnline' + i}
                                    checked={
                                      this.state.onlineClinic['tue'][i].online
                                    }
                                    onChange={() => this.checkOnline(i, 'tue')}
                                  />
                                  <label
                                    class='form-check-label'
                                    for={'tueOnline' + i}>
                                    Online
                                  </label>
                                </div>
                                <div class='form-check '>
                                  <input
                                    name='tue'
                                    class='form-check-input'
                                    type='checkbox'
                                    id={'tueClinic' + i}
                                    checked={
                                      this.state.onlineClinic['tue'][i].clinic
                                    }
                                    onChange={() => this.checkClinic(i, 'tue')}
                                  />
                                  <label
                                    class='form-check-label'
                                    for={'tueClinic' + i}>
                                    Clinic
                                  </label>
                                </div>

                                {this.state.timeSlot.tue.length > 1 &&
                                  i === this.state.timeSlot.tue.length - 1 && (
                                    <a
                                      href='#'
                                      className='remove-field m-0'
                                      onClick={e => this.removeMore(e, 'tue')}>
                                      - remove
                                    </a>
                                  )}
                              </div>
                            ))}
                          </>
                        ) : (
                          <label className='grey'>
                            Not Available on Tuesdays
                          </label>
                        )}
                      </div>
                      {this.state.data.tue &&
                        this.state.timeSlot.tue.length < 4 && (
                          <a
                            href='#'
                            className='add-more-field'
                            onClick={e => this.addMore(e, 'tue')}>
                            + Add more
                          </a>
                        )}
                    </div>
                  </div>

                  {/**** */}

                  <div class='schedule-box d-xl-flex flex-row'>
                    <div className='schedule-day'>
                      <div className='form-check'>
                        <input
                          name='wed'
                          onChange={this.DayChange}
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexCheckwed'
                          checked={this.state.data.wed}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='flexCheckwed'>
                          Wed
                        </label>
                      </div>
                    </div>
                    <div class='schedule-time-outer d-xl-flex flex-column'>
                      <div class='d-flex flex-column flex-wrap'>
                        {this.state.data.wed ? (
                          <>
                            {(this.state.timeSlot.wed || []).map((el, i) => (
                              <div class='schedule-time form-inline'>
                                <div class='form-group'>
                                  <SelectOptions
                                    name='wed'
                                    attrind={i}
                                    attrnum='0'
                                    onChange={this.selectChangeOption}
                                    value={this.timeDropdownNew.find(
                                      es =>
                                        es.value ===
                                        this.state.timeSlot.wed[i][0].id
                                    )}
                                    nameCl='small'
                                    options={this.timeDropdownNew}
                                  />

                                  <SelectOptions
                                    name='wed'
                                    attrind={i}
                                    attrnum='1'
                                    onChange={this.selectChangeOption}
                                    value={this.timeDropdownNew.find(
                                      es =>
                                        es.value ===
                                        this.state.timeSlot.wed[i][1].id
                                    )}
                                    nameCl='small'
                                    options={this.timeDropdownNew}
                                  />
                                </div>

                                <div class='form-check '>
                                  <input
                                    name='wed'
                                    class='form-check-input'
                                    type='checkbox'
                                    id={'wedOnline' + i}
                                    checked={
                                      this.state.onlineClinic['wed'][i].online
                                    }
                                    onChange={() => this.checkOnline(i, 'wed')}
                                  />
                                  <label
                                    class='form-check-label'
                                    for={'wedOnline' + i}>
                                    Online
                                  </label>
                                </div>
                                <div class='form-check '>
                                  <input
                                    name='wed'
                                    class='form-check-input'
                                    type='checkbox'
                                    id={'wedClinic' + i}
                                    checked={
                                      this.state.onlineClinic['wed'][i].clinic
                                    }
                                    onChange={() => this.checkClinic(i, 'wed')}
                                  />
                                  <label
                                    class='form-check-label'
                                    for={'wedClinic' + i}>
                                    Clinic
                                  </label>
                                </div>

                                {this.state.timeSlot.wed.length > 1 &&
                                  i === this.state.timeSlot.wed.length - 1 && (
                                    <a
                                      href='#'
                                      className='remove-field m-0'
                                      onClick={e => this.removeMore(e, 'wed')}>
                                      - remove
                                    </a>
                                  )}
                              </div>
                            ))}
                          </>
                        ) : (
                          <label className='grey'>
                            Not Available on Wednesdays
                          </label>
                        )}
                      </div>
                      {this.state.data.wed &&
                        this.state.timeSlot.wed.length < 4 && (
                          <a
                            href='#'
                            className='add-more-field'
                            onClick={e => this.addMore(e, 'wed')}>
                            + Add more
                          </a>
                        )}
                    </div>
                  </div>

                  {/*thu*/}
                  <div class='schedule-box d-xl-flex flex-row'>
                    <div className='schedule-day'>
                      <div className='form-check'>
                        <input
                          name='thu'
                          onChange={this.DayChange}
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexCheckthu'
                          checked={this.state.data.thu}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='flexCheckthu'>
                          Thu
                        </label>
                      </div>
                    </div>
                    <div class='schedule-time-outer d-xl-flex flex-column'>
                      <div class='d-flex flex-column flex-wrap'>
                        {this.state.data.thu ? (
                          <>
                            {(this.state.timeSlot.thu || []).map((el, i) => (
                              <div class='schedule-time form-inline'>
                                <div class='form-group'>
                                  <SelectOptions
                                    name='thu'
                                    attrind={i}
                                    attrnum='0'
                                    onChange={this.selectChangeOption}
                                    value={this.timeDropdownNew.find(
                                      es =>
                                        es.value ===
                                        this.state.timeSlot.thu[i][0].id
                                    )}
                                    nameCl='small'
                                    options={this.timeDropdownNew}
                                  />

                                  <SelectOptions
                                    name='thu'
                                    attrind={i}
                                    attrnum='1'
                                    onChange={this.selectChangeOption}
                                    value={this.timeDropdownNew.find(
                                      es =>
                                        es.value ===
                                        this.state.timeSlot.thu[i][1].id
                                    )}
                                    nameCl='small'
                                    options={this.timeDropdownNew}
                                  />
                                </div>

                                <div class='form-check '>
                                  <input
                                    name='thu'
                                    class='form-check-input'
                                    type='checkbox'
                                    id={'thuOnline' + i}
                                    checked={
                                      this.state.onlineClinic['thu'][i].online
                                    }
                                    onChange={() => this.checkOnline(i, 'thu')}
                                  />
                                  <label
                                    class='form-check-label'
                                    for={'thuOnline' + i}>
                                    Online
                                  </label>
                                </div>
                                <div class='form-check '>
                                  <input
                                    name='thu'
                                    class='form-check-input'
                                    type='checkbox'
                                    id={'thuClinic' + i}
                                    checked={
                                      this.state.onlineClinic['thu'][i].clinic
                                    }
                                    onChange={() => this.checkClinic(i, 'thu')}
                                  />
                                  <label
                                    class='form-check-label'
                                    for={'thuClinic' + i}>
                                    Clinic
                                  </label>
                                </div>

                                {this.state.timeSlot.thu.length > 1 &&
                                  i === this.state.timeSlot.thu.length - 1 && (
                                    <a
                                      href='#'
                                      className='remove-field m-0'
                                      onClick={e => this.removeMore(e, 'thu')}>
                                      - remove
                                    </a>
                                  )}
                              </div>
                            ))}
                          </>
                        ) : (
                          <label className='grey'>
                            Not Available on Thursdays
                          </label>
                        )}
                      </div>
                      {this.state.data.thu &&
                        this.state.timeSlot.thu.length < 4 && (
                          <a
                            href='#'
                            className='add-more-field'
                            onClick={e => this.addMore(e, 'thu')}>
                            + Add more
                          </a>
                        )}
                    </div>
                  </div>

                  {/****Fri */}
                  <div class='schedule-box d-xl-flex flex-row'>
                    <div className='schedule-day'>
                      <div className='form-check'>
                        <input
                          name='fri'
                          onChange={this.DayChange}
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexCheckfri'
                          checked={this.state.data.fri}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='flexCheckfri'>
                          Fri
                        </label>
                      </div>
                    </div>
                    <div class='schedule-time-outer d-xl-flex flex-column'>
                      <div class='d-flex flex-column flex-wrap'>
                        {this.state.data.fri ? (
                          <>
                            {(this.state.timeSlot.fri || []).map((el, i) => (
                              <div class='schedule-time form-inline'>
                                <div class='form-group'>
                                  <SelectOptions
                                    name='fri'
                                    attrind={i}
                                    attrnum='0'
                                    onChange={this.selectChangeOption}
                                    value={this.timeDropdownNew.find(
                                      es =>
                                        es.value ===
                                        this.state.timeSlot.fri[i][0].id
                                    )}
                                    nameCl='small'
                                    options={this.timeDropdownNew}
                                  />

                                  <SelectOptions
                                    name='fri'
                                    attrind={i}
                                    attrnum='1'
                                    onChange={this.selectChangeOption}
                                    value={this.timeDropdownNew.find(
                                      es =>
                                        es.value ===
                                        this.state.timeSlot.fri[i][1].id
                                    )}
                                    nameCl='small'
                                    options={this.timeDropdownNew}
                                  />
                                </div>

                                <div class='form-check '>
                                  <input
                                    name='fri'
                                    class='form-check-input'
                                    type='checkbox'
                                    id={'friOnline' + i}
                                    checked={
                                      this.state.onlineClinic['fri'][i].online
                                    }
                                    onChange={() => this.checkOnline(i, 'fri')}
                                  />
                                  <label
                                    class='form-check-label'
                                    for={'friOnline' + i}>
                                    Online
                                  </label>
                                </div>
                                <div class='form-check '>
                                  <input
                                    name='fri'
                                    class='form-check-input'
                                    type='checkbox'
                                    id={'friClinic' + i}
                                    checked={
                                      this.state.onlineClinic['fri'][i].clinic
                                    }
                                    onChange={() => this.checkClinic(i, 'fri')}
                                  />
                                  <label
                                    class='form-check-label'
                                    for={'friClinic' + i}>
                                    Clinic
                                  </label>
                                </div>

                                {this.state.timeSlot.fri.length > 1 &&
                                  i === this.state.timeSlot.fri.length - 1 && (
                                    <a
                                      href='#'
                                      className='remove-field m-0'
                                      onClick={e => this.removeMore(e, 'fri')}>
                                      - remove
                                    </a>
                                  )}
                              </div>
                            ))}
                          </>
                        ) : (
                          <label className='grey'>
                            Not Available on Fridays
                          </label>
                        )}
                      </div>
                      {this.state.data.fri &&
                        this.state.timeSlot.fri.length < 4 && (
                          <a
                            href='#'
                            className='add-more-field'
                            onClick={e => this.addMore(e, 'fri')}>
                            + Add more
                          </a>
                        )}
                    </div>
                  </div>

                  {/***Sat */}
                  <div class='schedule-box d-xl-flex flex-row'>
                    <div className='schedule-day'>
                      <div className='form-check'>
                        <input
                          name='sat'
                          onChange={this.DayChange}
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexChecksat'
                          checked={this.state.data.sat}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='flexChecksat'>
                          Sat
                        </label>
                      </div>
                    </div>
                    <div class='schedule-time-outer d-xl-flex flex-column'>
                      <div class='d-flex flex-column flex-wrap'>
                        {this.state.data.sat ? (
                          <>
                            {(this.state.timeSlot.sat || []).map((el, i) => (
                              <div class='schedule-time form-inline'>
                                <div class='form-group'>
                                  <SelectOptions
                                    name='sat'
                                    attrind={i}
                                    attrnum='0'
                                    onChange={this.selectChangeOption}
                                    value={this.timeDropdownNew.find(
                                      es =>
                                        es.value ===
                                        this.state.timeSlot.sat[i][0].id
                                    )}
                                    nameCl='small'
                                    options={this.timeDropdownNew}
                                  />

                                  <SelectOptions
                                    name='sat'
                                    attrind={i}
                                    attrnum='1'
                                    onChange={this.selectChangeOption}
                                    value={this.timeDropdownNew.find(
                                      es =>
                                        es.value ===
                                        this.state.timeSlot.sat[i][1].id
                                    )}
                                    nameCl='small'
                                    options={this.timeDropdownNew}
                                  />
                                </div>

                                <div class='form-check '>
                                  <input
                                    name='sat'
                                    class='form-check-input'
                                    type='checkbox'
                                    id={'satOnline' + i}
                                    checked={
                                      this.state.onlineClinic['sat'][i].online
                                    }
                                    onChange={() => this.checkOnline(i, 'sat')}
                                  />
                                  <label
                                    class='form-check-label'
                                    for={'satOnline' + i}>
                                    Online
                                  </label>
                                </div>
                                <div class='form-check '>
                                  <input
                                    name='sat'
                                    class='form-check-input'
                                    type='checkbox'
                                    id={'satClinic' + i}
                                    checked={
                                      this.state.onlineClinic['sat'][i].clinic
                                    }
                                    onChange={() => this.checkClinic(i, 'sat')}
                                  />
                                  <label
                                    class='form-check-label'
                                    for={'satClinic' + i}>
                                    Clinic
                                  </label>
                                </div>

                                {this.state.timeSlot.sat.length > 1 &&
                                  i === this.state.timeSlot.sat.length - 1 && (
                                    <a
                                      href='#'
                                      className='remove-field m-0'
                                      onClick={e => this.removeMore(e, 'sat')}>
                                      - remove
                                    </a>
                                  )}
                              </div>
                            ))}
                          </>
                        ) : (
                          <label className='grey'>
                            Not Available on Saturdays
                          </label>
                        )}
                      </div>
                      {this.state.data.sat &&
                        this.state.timeSlot.sat.length < 4 && (
                          <a
                            href='#'
                            className='add-more-field'
                            onClick={e => this.addMore(e, 'sat')}>
                            + Add more
                          </a>
                        )}
                    </div>
                  </div>

                  {/**Sun */}
                  <div class='schedule-box d-xl-flex flex-row'>
                    <div className='schedule-day'>
                      <div className='form-check'>
                        <input
                          name='sun'
                          onChange={this.DayChange}
                          className='form-check-input'
                          type='checkbox'
                          value=''
                          id='flexChecksun'
                          checked={this.state.data.sun}
                        />
                        <label
                          className='form-check-label'
                          htmlFor='flexChecksun'>
                          Sun
                        </label>
                      </div>
                    </div>
                    <div class='schedule-time-outer d-xl-flex flex-column'>
                      <div class='d-flex flex-column flex-wrap'>
                        {this.state.data.sun ? (
                          <>
                            {(this.state.timeSlot.sun || []).map((el, i) => (
                              <div class='schedule-time form-inline'>
                                <div class='form-group'>
                                  <SelectOptions
                                    name='sun'
                                    attrind={i}
                                    attrnum='0'
                                    onChange={this.selectChangeOption}
                                    value={this.timeDropdownNew.find(
                                      es =>
                                        es.value ===
                                        this.state.timeSlot.sun[i][0].id
                                    )}
                                    nameCl='small'
                                    options={this.timeDropdownNew}
                                  />

                                  <SelectOptions
                                    name='sun'
                                    attrind={i}
                                    attrnum='1'
                                    onChange={this.selectChangeOption}
                                    value={this.timeDropdownNew.find(
                                      es =>
                                        es.value ===
                                        this.state.timeSlot.sun[i][1].id
                                    )}
                                    nameCl='small'
                                    options={this.timeDropdownNew}
                                  />
                                </div>

                                <div class='form-check '>
                                  <input
                                    name='sun'
                                    class='form-check-input'
                                    type='checkbox'
                                    id={'sunOnline' + i}
                                    checked={
                                      this.state.onlineClinic['sun'][i].online
                                    }
                                    onChange={() => this.checkOnline(i, 'sun')}
                                  />
                                  <label
                                    class='form-check-label'
                                    for={'sunOnline' + i}>
                                    Online
                                  </label>
                                </div>
                                <div class='form-check '>
                                  <input
                                    name='sun'
                                    class='form-check-input'
                                    type='checkbox'
                                    id={'sunClinic' + i}
                                    checked={
                                      this.state.onlineClinic['sun'][i].clinic
                                    }
                                    onChange={() => this.checkClinic(i, 'sun')}
                                  />
                                  <label
                                    class='form-check-label'
                                    for={'sunClinic' + i}>
                                    Clinic
                                  </label>
                                </div>

                                {this.state.timeSlot.sun.length > 1 &&
                                  i === this.state.timeSlot.sun.length - 1 && (
                                    <a
                                      href='#'
                                      className='remove-field m-0'
                                      onClick={e => this.removeMore(e, 'sun')}>
                                      - remove
                                    </a>
                                  )}
                              </div>
                            ))}
                          </>
                        ) : (
                          <label className='grey'>
                            Not Available on Sundays
                          </label>
                        )}
                      </div>
                      {this.state.data.sun &&
                        this.state.timeSlot.sun.length < 4 && (
                          <a
                            href='#'
                            className='add-more-field'
                            onClick={e => this.addMore(e, 'sun')}>
                            + Add more
                          </a>
                        )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='row mt-3'>
                <div className='col-xl-4'>
                  <div className='form-group'>
                    <label>
                      Select time slot{' '}
                      <i
                        class='fa fa-question-circle'
                        data-tooltip-id='schedule2-tooltip'
                        data-tooltip-content='Slot duration will be same for clinic visit and online visit for bookings via YesMindy to avoid any overlap and better calendar management'
                        aria-hidden='true'></i>
                    </label>
                    <SelectOptions
                      value={this.state.time}
                      onChange={this.changeTime}
                      options={[
                        { value: '20', label: '20 Minutes' },
                        { value: '30', label: '30 Minutes' },
                        { value: '40', label: '40 Minutes' },
                        { value: '50', label: '50 Minutes' },
                      ]}
                    />
                  </div>
                </div>
                <div className='col-xl-2'>
                  <div className='form-group'>
                    <label>Online Fee ₹ </label>
                    <input
                      type='text'
                      style={{ height: '38px' }}
                      className='form-control'
                      value={this.state.onlineFee}
                      onChange={e => {
                        this.setState({
                          onlineFee: e.target.value
                            ? parseInt(e.target.value.replace(/\D/, ''))
                            : 0,
                        })
                      }}
                    />
                  </div>
                </div>
                <div className='col-xl-2'>
                  <div className='form-group'>
                    <label>Clinic Fee ₹ </label>
                    <input
                      type='text'
                      style={{ height: '38px' }}
                      className='form-control'
                      value={this.state.clinicFee}
                      onChange={e => {
                        this.setState({
                          clinicFee: e.target.value
                            ? parseInt(e.target.value.replace(/\D/, ''))
                            : 0,
                        })
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className='row '>
                <div className='col-xl-12 form-group'>
                  <span className='note'>
                    <strong>Note -</strong> You may choose to select same or
                    different timings for online and clinic consultancy. Our
                    single calendar will handle your appointments accordingly
                    hassle free.
                  </span>
                </div>
              </div>

              <div className='row'>
                <div className='col-xl-5'>
                  <div className='form-group'>
                    <SubmitBtn
                      onClickHandle={this.doSubmit}
                      label='Save & Continue'
                      loading={this.state.loading}
                      btnClass={this.state.btnClass}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  profileLoading: getDoctor(state).profileLoading,
  doctorProfile: getDoctor(state).profile,
})

const mapDispatchToProps = dispatch => ({
  doctorUpdate: (data, callback) => dispatch(doctorUpdate(data, callback)),
  loadDoctorProfile: () => dispatch(loadDoctorProfile()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Schedule)
