import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { WatiScript } from '../../../include/js/wati'
import Header from './header'
import leftArrow from '../../../include/images/tp-left-arrow.svg'
import rightArrow from '../../../include/images/tp-right-arrow.svg'
import vDot from '../../../include/images/vertical-dots.svg'
import blueStar from '../../../include/images/blue-star-icon.svg'
import LeftBar from './leftBar'
import moment from 'moment'
import { isMobile } from 'react-device-detect'

import {
  calcelByDoctor,
  getAppointment,
  loadAllAppointmentForDoctor,
  loadAppointmentDetail,
  noShow,
} from '../../../store/appointment'
import { loadSeo } from '../../../store/seo'
import { getDoctor, loadDoctorProfile } from '../../../store/doctor'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import SideTabs from './sideTab'
import {
  getDoctorPatient,
  loadAllDoctorpatient,
} from '../../../store/doctorPatient'
import { loadChatList, loadList } from '../../../store/prescription'
import { toast } from 'react-toastify'
import AlertSuccess from '../../common/alertSuccess'
import AlertError from '../../common/alertError'
import AvtarImage from '../../common/avtarImage'
import YesMindy from '../../common/yesMindy'
import NoRecord from '../../common/noRecord'
import { Modal } from 'react-bootstrap'
import { Tooltip as ReactTooltip } from 'react-tooltip'

class DoctorDashboard extends Component {
  state = {
    appointmentId: '',
    date: moment(),
    time: moment(),
    intervalId: 1,
    type: 'online',
    appointModel: false,
    dropDown: false,
    popUp: false,
    sidebarToogle: false,
    cancelConfirm: false,
  }
  toggle = e => {
    if (e) e.preventDefault()
    this.setState({ popUp: !this.state.popUp })
  }

  dropDownToggle = e => {
    e.preventDefault()
    this.setState({ dropDown: !this.state.dropDown })
  }

  toggleAppointModel = e => {
    if (e) e.preventDefault()
    this.setState({ appointModel: !this.state.appointModel })
  }
  cancellAppointment = e => {
    e.preventDefault()
    this.props.cancellAppointment(this.state.appointmentId, this.callBack)
    this.setState({ loader: true })
  }
  callBack = res => {
    this.toggle()
    this.setState({ loader: false, cancelConfirm: false })
    this.props.loadAllAppointmentForDoctor({
      appointment_date: this.state.date.format('YYYY-MM-DD'),
    })
    this.props.loadList({
      appointment_date: this.state.date.format('YYYY-MM-DD'),
    })
    this.props.loadChatList({
      appointment_date: moment().format('YYYY-MM-DD'),
    })
    if (res.status === 200) {
      toast(<AlertSuccess message={'Appointment cancelled successfully'} />)
    } else {
      toast(<AlertError message={res.data.error} />)
    }
  }
  reSchule = e => {
    e.preventDefault()
    this.props.history.push({
      pathname: '/doctor/reschedule-appointment',
      state: {
        appointment_id: this.state.appointmentId,
        returnUrl: '/doctor/dashboard/calender',
      },
    })
  }
  componentWillUnmount = () => {
    clearInterval(this.state.intervalId)
  }

  changeData = type => {
    if (type === 'next')
      this.setState({ date: this.state.date.add(1, 'days') }, () => {
        this.props.loadAllAppointmentForDoctor({
          appointment_date: this.state.date.format('YYYY-MM-DD'),
        })
        this.props.loadList({
          appointment_date: this.state.date.format('YYYY-MM-DD'),
        })
      })
    if (type === 'prev')
      this.setState({ date: this.state.date.subtract(1, 'days') }, () => {
        this.props.loadAllAppointmentForDoctor({
          appointment_date: this.state.date.format('YYYY-MM-DD'),
        })
        this.props.loadList({
          appointment_date: this.state.date.format('YYYY-MM-DD'),
        })
      })
  }

  componentDidMount() {
    this.props.loadList({
      appointment_date: this.state.date.format('YYYY-MM-DD'),
    })
    this.props.loadChatList({
      appointment_date: moment().format('YYYY-MM-DD'),
    })
    this.props.loadAllDoctorpatient({
      page: 1,
      appointment_date: this.props.appointment_date,
    })
    const intervalId = setInterval(() => {
      this.setState({ time: moment() })
    }, 100)
    this.setState({ intervalId })
    document.body.className = 'dashboard'
    this.props.loadAllAppointmentForDoctor({
      appointment_date: moment().format('YYYY-MM-DD'),
    })
  }

  noShow = (e, id) => {
    this.setState({ appointmentId: '' })
    e.preventDefault()
    this.props.noShow(id, this.callbackNoShow)
  }
  callbackNoShow = res => {
    this.props.loadAllAppointmentForDoctor({
      appointment_date: moment().format('YYYY-MM-DD'),
    })
    if (res.status === 200) {
      toast(<AlertSuccess message={'Record updated.'} />)
    } else {
      toast(<AlertError message={'Something went wrong.'} />)
    }
  }

  onCall = (e, id) => {
    e.preventDefault()
    window.open('/doctor/video-call/' + id, '_blank').focus()
    //this.props.loadAppointmentDetail(id, this.callback)
  }
  callback = res => {
    this.props.history.push({
      pathname: '/doctor/video-call',
      state: { detail: res.data },
    })
  }

  toggleSidebar = () => {
    this.setState({ sidebarToogle: !this.state.sidebarToogle })
  }
  render() {
    //const serverMode = process.env.REACT_APP_MODE
    const serverMode = 'ddd'
    return (
      <>
        {/* <Helmet>
          <script id='wati'>{WatiScript}</script>
        </Helmet> */}
        <ReactTooltip className='testPurple' id="dashboard-tooltip" />
        <div class={this.state.sidebarToogle ? 'dashboard on' : 'dashboard'}>
          <LeftBar />
          <Header
            sidebarToogle={this.state.sidebarToogle}
            toggleSidebar={this.toggleSidebar}
          />
          {!this.props.profileLoading && (
            <div
              class='dashboard-main-container'
              onClick={e => {
                //alert(1);
                e.stopPropagation()
                this.setState({ appointmentId: '' })
              }}>
              <div class='dashboard-top-pannel d-lg-flex align-items-lg-center'>
                <div class='tp-text-box d-flex align-items-center justify-content-between'>
                  <div class='name-on-home'>{`Welcome, ${
                    this.props.profile && this.props.profile.name
                  }`}</div>
                  <div class='tp-heading'>Dashboard</div>
                  <div class='tp-date-desc'>
                    <div
                      class='tp-left-arrow'
                      onClick={() => this.changeData('prev')}>
                      <img src={leftArrow} alt='' />
                    </div>
                    <div class='text'>
                      {this.state.date.format('dddd, D MMM')}
                    </div>
                    <div
                      class='tp-right-arrow'
                      onClick={() => this.changeData('next')}>
                      <img src={rightArrow} alt='' />
                    </div>
                  </div>
                  <div class='tp-time-intro'>
                    <strong>{this.state.time.format('h:mm a')}</strong>
                  </div>
                </div>
                <div class='tp-button-group d-flex align-items-center justify-content-end'>
                  <Link
                    data-tooltip-id="dashboard-tooltip"
                    data-tooltip-content='For walk in/ online patients when you want to record their visit (physical, online, telephonic). Only for existing patients in your record. Else add new patient first to record their visit.'
                    to='/doctor/recordVisit'
                    class='btn2 btn-outline no-shadow'>
                    Record Visit
                  </Link>
                  <Link
                    data-tooltip-id="dashboard-tooltip"                  
                    data-tooltip-content='When you want to create an appointment for your existing patient on their behalf. If new patient then add the patient first.'
                    to='/doctor/newAppointment'
                    class='btn2 btn-outline no-shadow'>
                    + New Appointment
                  </Link>
                  <Link
                    data-tooltip-id="dashboard-tooltip"                  
                    data-tooltip-content='When you want to add a new patient to your dashboard so that you can create an appointment for them or record their visits.'
                    to='/doctor/addPatient'
                    class='btn2 btn-default'>
                    + New Patient
                  </Link>
                </div>
              </div>

              <div class='dashboard-main-pannel d-lg-flex flex-lg-wrap'>
                <div class='pannel-left'>
                  <div class='pannel-header'>
                    <ul class='pannel-page-links d-flex flex-wrap'>
                      <li
                        class={
                          this.state.type === 'online'
                            ? 'ppl-item active'
                            : 'ppl-item'
                        }>
                        <a
                          href='#!'
                          onClick={e => {
                            e.preventDefault()
                            this.setState({ type: 'online' })
                          }}
                          class='ppl-box'>
                          <strong>
                            {
                              this.props.getAppointment.data.filter(
                                e => e.appointment_type !== 'walkin'
                              ).length
                            }
                          </strong>
                          <p>
                            {this.state.date.format('dddd, D MMM') ===
                              moment().format('dddd, D MMM') && 'Today’s'}{' '}
                            Appointment
                          </p>
                        </a>
                      </li>
                      <li
                        class={
                          this.state.type === 'walkin'
                            ? 'ppl-item active'
                            : 'ppl-item'
                        }>
                        <a
                          href='#!'
                          onClick={e => {
                            e.preventDefault()
                            this.setState({ type: 'walkin' })
                          }}
                          class='ppl-box'>
                          <strong>
                            {
                              this.props.getAppointment.data.filter(
                                e => e.appointment_type === 'walkin'
                              ).length
                            }
                          </strong>
                          <p>
                            {this.state.date.format('dddd, D MMM') ===
                              moment().format('dddd, D MMM') && 'Today’s'}{' '}
                            Walk-Ins
                          </p>
                        </a>
                      </li>
                      <li class='ppl-item'>
                        <Link to='/doctor/patient' class='ppl-box'>
                          <strong>
                            {this.props.getDoctorPatient.total_record}
                          </strong>
                          <p>My Patients</p>
                        </Link>
                      </li>
                    </ul>
                    <h4>
                      {this.state.date.format('dddd, D MMM') ===
                        moment().format('dddd, D MMM') && 'Today’s'}{' '}
                      {this.state.type === 'online'
                        ? 'Appointment'
                        : 'Walk-Ins'}
                    </h4>
                  </div>

                  <div
                    class='appointments-pannel-outer  bg-white border-radius2  add-scrollbar  scrollbar-dynamic'
                    style={{ overflow: 'visible' }}>
                    <div class='appointments-pannel'>
                      <>
                        {this.props.getAppointment.data.filter(e =>
                          this.state.type === 'online'
                            ? e.appointment_type !== 'walkin' &&
                              !e.noShow &&
                              moment()
                                .subtract({
                                  minutes: e.doctor_id.schedules.time,
                                })
                                .diff(
                                  moment(e.appointment_time).subtract({
                                    hours: 5,
                                    minutes: 30,
                                  }),
                                  'minutes'
                                ) < 0
                            : e.appointment_type === this.state.type &&
                              !e.noShow &&
                              !e.noShow &&
                              moment()
                                .subtract({
                                  minutes: e.doctor_id.schedules.time,
                                })
                                .diff(
                                  moment(e.appointment_time).subtract({
                                    hours: 5,
                                    minutes: 30,
                                  }),
                                  'minutes'
                                ) < 0
                        ).length > 0 ? (
                          this.props.getAppointment.data
                            .filter(e =>
                              this.state.type === 'online'
                                ? e.appointment_type !== 'walkin' &&
                                  !e.noShow &&
                                  moment()
                                    .subtract({
                                      minutes: e.doctor_id.schedules.time,
                                    })
                                    .diff(
                                      moment(e.appointment_time).subtract({
                                        hours: 5,
                                        minutes: 30,
                                      }),
                                      'minutes'
                                    ) < 0
                                : e.appointment_type === this.state.type &&
                                  !e.noShow &&
                                  !e.noShow &&
                                  moment()
                                    .subtract({
                                      minutes: e.doctor_id.schedules.time,
                                    })
                                    .diff(
                                      moment(e.appointment_time).subtract({
                                        hours: 5,
                                        minutes: 30,
                                      }),
                                      'minutes'
                                    ) < 0
                            )
                            .map(ea => (
                              <div class='person-details d-flex align-items-center justify-content-between'>
                                <div class='person-details-box d-flex align-items-center flex-grow-1'>
                                  <div class='person-img has-active'>
                                    {ea.patient_id.thumb ? (
                                      <img
                                        src={
                                          process.env.REACT_APP_S3URL +
                                          ea.patient_id.thumb
                                        }
                                        alt=''
                                      />
                                    ) : (
                                      <AvtarImage />
                                    )}
                                  </div>
                                  <div class='person-text-box'>
                                    <h3>
                                      {ea.patient_id.first_name +
                                        ' ' +
                                        ea.patient_id.last_name}

                                      {ea.patient_id.star.length > 0 &&
                                        ea.patient_id.star.find(
                                          e =>
                                            e === this.props.doctorProfile._id
                                        ) && (
                                          <i>
                                            <img
                                              src={blueStar}
                                              alt='star-icon'
                                            />
                                          </i>
                                        )}
                                    </h3>
                                    <div class='patient-btn d-flex align-items-center justify-content-left'>
                                      <span class='patient-tags ml-0 light-blue'>
                                        {ea.appointment_type}
                                      </span>
                                      <span
                                        class={
                                          ea.followup
                                            ? 'patient-tags '
                                            : 'patient-tags yellow-tag'
                                        }>
                                        {ea.followup
                                          ? 'Follow Up'
                                          : 'New Patient'}
                                      </span>

                                      <a class='profile-box'>
                                        {ea.patient_id.type === 2 ? (
                                          <i class='fas fa-child'></i>
                                        ) : (
                                          <i class='fas fa-user'></i>
                                        )}
                                      </a>
                                    </div>
                                  </div>
                                </div>

                                <div class='pd-right d-flex align-items-center justify-content-end flex-grow-1'>
                                  {ea.appointment_type === 'online' && (
                                    <>
                                      {this.state.type === 'online' && (
                                        <div class=''>
                                          <a
                                            data-tooltip-id="dashboard-tooltip"
                                            data-tooltip-content={
                                              moment().diff(
                                                moment(
                                                  ea.appointment_time
                                                ).subtract({
                                                  hours: 5,
                                                  minutes: 30,
                                                }),
                                                'minutes'
                                              ) > -10 ||
                                              serverMode === 'development'
                                                ? ''
                                                : 'Join will be active 10 minutes before appointment'
                                            }
                                            class='btn btn-blue'
                                            onClick={e => {
                                              if (
                                                moment().diff(
                                                  moment(
                                                    ea.appointment_time
                                                  ).subtract({
                                                    hours: 5,
                                                    minutes: 30,
                                                  }),
                                                  'minutes'
                                                ) > -10 ||
                                                serverMode === 'development'
                                              ) {
                                                this.onCall(e, ea._id)
                                              }
                                            }}
                                            style={{
                                              cursor:
                                                moment().diff(
                                                  moment(
                                                    ea.appointment_time
                                                  ).subtract({
                                                    hours: 5,
                                                    minutes: 30,
                                                  }),
                                                  'minutes'
                                                ) > -10 ||
                                                serverMode === 'development'
                                                  ? 'pointer'
                                                  : 'initial',
                                            }}>
                                            Join Call
                                          </a>
                                        </div>
                                      )}
                                    </>
                                  )}
                                  <strong className='pe-3'>
                                    {moment(ea.appointment_time)
                                      .utc()
                                      .format('h:mm a')}
                                  </strong>
                                  <div class='dropright'>
                                    <a
                                      class='dropdown-toggle'
                                      href='#'
                                      data-toggle='dropdown'
                                      aria-haspopup='true'
                                      aria-expanded='false'
                                      onClick={e => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        this.setState({
                                          appointmentId: ea._id,
                                        })
                                      }}>
                                      <img src={vDot} alt='' />
                                    </a>
                                    <div
                                      class={
                                        this.state.appointmentId === ea._id
                                          ? 'dropdown-menu dropdown-custom show'
                                          : 'dropdown-menu dropdown-custom'
                                      }
                                      style={
                                        this.state.appointmentId === ea._id
                                          ? isMobile
                                            ? {
                                                zIndex: 99999999999,
                                                position: 'absolute',
                                                transform:
                                                  'translate3d(-173px, 0px, 0px)',
                                                top: '0px',
                                                left: '0px',
                                                willChange: 'transform',
                                              }
                                            : {}
                                          : {}
                                      }
                                      x-placement={
                                        isMobile ? 'left-start' : 'right-start'
                                      }>
                                      <a
                                        class='dropdown-item'
                                        href='#!'
                                        onClick={en => this.noShow(en, ea._id)}>
                                        No Show
                                      </a>
                                      <a
                                        class='dropdown-item'
                                        href='#!'
                                        onClick={e => {
                                          e.preventDefault()
                                          this.props.history.push({
                                            pathname: '/doctor/patient-detail',
                                            state: ea.patient_id._id,
                                          })
                                        }}>
                                        View Profile
                                      </a>
                                      <a
                                        class='dropdown-item'
                                        href='#!'
                                        onClick={e => {
                                          e.preventDefault()
                                          this.props.history.push({
                                            pathname: '/doctor/patient-detail',
                                            state: ea.patient_id._id,
                                          })
                                        }}>
                                        Notes and Thoughts
                                      </a>
                                      <a
                                        class='dropdown-item'
                                        href='#'
                                        onClick={e => {
                                          e.stopPropagation()
                                          this.dropDownToggle(e)
                                          this.toggle(e)
                                          this.toggleAppointModel(e)
                                          this.setState({
                                            appointmentId: ea._id,
                                          })
                                        }}>
                                        Modify Appointment
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                        ) : (
                          <div class='person-details d-flex align-items-center justify-content-between completed'>
                            <p>
                              {this.state.type === 'online' && (
                                <NoRecord
                                  header='You Have No Active Appointments For Today So
                                  Far'
                                  text='aims to put sincere efforts each
                                  day to make the experience of virtual
                                  consultation better for its Mental Health
                                  Experts.'
                                />
                              )}
                            </p>
                          </div>
                        )}
                        {this.props.getAppointment.data.filter(e =>
                          this.state.type === 'online'
                            ? e.appointment_type !== 'walkin' &&
                              !e.noShow &&
                              moment()
                                .subtract({
                                  minutes: e.doctor_id.schedules.time,
                                })
                                .diff(
                                  moment(e.appointment_time).subtract({
                                    hours: 5,
                                    minutes: 30,
                                  }),
                                  'minutes'
                                ) > 0
                            : e.appointment_type === this.state.type &&
                              !e.noShow &&
                              moment()
                                .subtract({
                                  minutes: e.doctor_id.schedules.time,
                                })
                                .diff(
                                  moment(e.appointment_time).subtract({
                                    hours: 5,
                                    minutes: 30,
                                  }),
                                  'minutes'
                                ) > 0
                        ).length > 0 && (
                          <div class='chat-seprator1'>
                            <span>Completed</span>
                          </div>
                        )}

                        {this.props.getAppointment.data.filter(e =>
                          this.state.type === 'online'
                            ? e.appointment_type !== 'walkin' &&
                              !e.noShow &&
                              moment()
                                .subtract({
                                  minutes: e.doctor_id.schedules.time,
                                })
                                .diff(
                                  moment(e.appointment_time).subtract({
                                    hours: 5,
                                    minutes: 30,
                                  }),
                                  'minutes'
                                ) > 0
                            : e.appointment_type === this.state.type &&
                              !e.noShow &&
                              !e.noShow &&
                              moment()
                                .subtract({
                                  minutes: e.doctor_id.schedules.time,
                                })
                                .diff(
                                  moment(e.appointment_time).subtract({
                                    hours: 5,
                                    minutes: 30,
                                  }),
                                  'minutes'
                                ) > 0
                        ).length > 0 ? (
                          this.props.getAppointment.data
                            .filter(e =>
                              this.state.type === 'online'
                                ? e.appointment_type !== 'walkin' &&
                                  !e.noShow &&
                                  moment()
                                    .subtract({
                                      minutes: e.doctor_id.schedules.time,
                                    })
                                    .diff(
                                      moment(e.appointment_time).subtract({
                                        hours: 5,
                                        minutes: 30,
                                      }),
                                      'minutes'
                                    ) > 0
                                : e.appointment_type === this.state.type &&
                                  !e.noShow &&
                                  !e.noShow &&
                                  moment()
                                    .subtract({
                                      minutes: e.doctor_id.schedules.time,
                                    })
                                    .diff(
                                      moment(e.appointment_time).subtract({
                                        hours: 5,
                                        minutes: 30,
                                      }),
                                      'minutes'
                                    ) > 0
                            )
                            .map(ea => (
                              <div class='person-details d-flex align-items-center justify-content-between completed'>
                                <div class='person-details-box d-flex align-items-center flex-grow-1'>
                                  <div class='person-img has-active'>
                                    {ea.patient_id.thumb ? (
                                      <img
                                        src={
                                          process.env.REACT_APP_S3URL +
                                          ea.patient_id.thumb
                                        }
                                        alt=''
                                      />
                                    ) : (
                                      <AvtarImage />
                                    )}
                                  </div>
                                  <div class='person-text-box'>
                                    <h3>
                                      {ea.patient_id.first_name +
                                        ' ' +
                                        ea.patient_id.last_name}

                                      {ea.patient_id.star.length > 0 &&
                                        ea.patient_id.star.find(
                                          e =>
                                            e === this.props.doctorProfile._id
                                        ) && (
                                          <i>
                                            <img
                                              src={blueStar}
                                              alt='star-icon'
                                            />
                                          </i>
                                        )}
                                    </h3>
                                    <div class='patient-btn d-flex align-items-center justify-content-left'>
                                      <span class='patient-tags ml-0 light-blue'>
                                        {ea.appointment_type}
                                      </span>
                                      <span
                                        class={
                                          ea.followup
                                            ? 'patient-tags '
                                            : 'patient-tags yellow-tag'
                                        }>
                                        {ea.followup
                                          ? 'Follow Up'
                                          : 'New Patient'}
                                      </span>

                                      <a class='profile-box'>
                                        {ea.patient_id.type === 2 ? (
                                          <i class='fas fa-child'></i>
                                        ) : (
                                          <i class='fas fa-user'></i>
                                        )}
                                      </a>
                                    </div>
                                  </div>
                                </div>

                                <div class='pd-right d-flex align-items-center justify-content-end flex-grow-1'>
                                  {/* <div class="pd-text">
                                    <a
                                      target="_blank"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <h5>
                                        {moment(ea.appointment_time)
                                          .utc()
                                          .format('h:mm a')}{' '}
                                      </h5>
                                    </a>
                                  </div> */}

                                  {ea.appointment_type === 'online' &&
                                    moment().diff(
                                      moment(ea.appointment_time).subtract({
                                        hours: 5,
                                        minutes: 30,
                                      }),
                                      'minutes'
                                    ) <= 80 && (
                                      <>
                                        {this.state.type === 'online' &&
                                          moment().diff(
                                            moment(
                                              ea.appointment_time
                                            ).subtract({
                                              hours: 5,
                                              minutes: 30,
                                            }),
                                            'minutes'
                                          ) > -10 && (
                                            <div class=''>
                                              <a
                                                data-tooltip-id="dashboard-tooltip"
                                                data-tooltip-content={
                                                  moment().diff(
                                                    moment(
                                                      ea.appointment_time
                                                    ).subtract({
                                                      hours: 5,
                                                      minutes: 30,
                                                    }),
                                                    'minutes'
                                                  ) > -10 ||
                                                  serverMode === 'development'
                                                    ? ''
                                                    : 'Join will be active 10 minutes before appointment'
                                                }
                                                class='btn btn-blue'
                                                onClick={e => {
                                                  if (
                                                    moment().diff(
                                                      moment(
                                                        ea.appointment_time
                                                      ).subtract({
                                                        hours: 5,
                                                        minutes: 30,
                                                      }),
                                                      'minutes'
                                                    ) > -10 ||
                                                    serverMode === 'development'
                                                  ) {
                                                    this.onCall(e, ea._id)
                                                  }
                                                }}
                                                style={{
                                                  cursor:
                                                    moment().diff(
                                                      moment(
                                                        ea.appointment_time
                                                      ).subtract({
                                                        hours: 5,
                                                        minutes: 30,
                                                      }),
                                                      'minutes'
                                                    ) > -10 ||
                                                    serverMode === 'development'
                                                      ? 'pointer'
                                                      : 'initial',
                                                }}>
                                                Join Call
                                              </a>
                                            </div>
                                          )}
                                      </>
                                    )}

                                  <strong>
                                    {moment(ea.appointment_time)
                                      .utc()
                                      .format('h:mm a')}
                                  </strong>
                                  <div class='dropright'>
                                    <a
                                      class='dropdown-toggle'
                                      href='#'
                                      data-toggle='dropdown'
                                      aria-haspopup='true'
                                      aria-expanded='false'
                                      onClick={e => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        this.setState({
                                          appointmentId: ea._id,
                                        })
                                      }}>
                                      <img src={vDot} alt='' />
                                    </a>
                                    <div
                                      class={
                                        this.state.appointmentId === ea._id
                                          ? 'dropdown-menu dropdown-custom show'
                                          : 'dropdown-menu dropdown-custom'
                                      }
                                      style={
                                        this.state.appointmentId === ea._id
                                          ? isMobile
                                            ? {
                                                zIndex: 99999999999,
                                                position: 'absolute',
                                                transform:
                                                  'translate3d(-173px, 0px, 0px)',
                                                top: '0px',
                                                left: '0px',
                                                willChange: 'transform',
                                              }
                                            : {}
                                          : {}
                                      }
                                      x-placement={
                                        isMobile ? 'left-start' : 'right-start'
                                      }>
                                      <a
                                        class='dropdown-item'
                                        href='#!'
                                        onClick={e => {
                                          e.preventDefault()
                                          this.props.history.push({
                                            pathname: '/doctor/patient-detail',
                                            state: ea.patient_id._id,
                                          })
                                        }}>
                                        View Profile
                                      </a>
                                      <a
                                        class='dropdown-item'
                                        href='#!'
                                        onClick={e => {
                                          e.preventDefault()
                                          this.props.history.push({
                                            pathname: '/doctor/patient-detail',
                                            state: ea.patient_id._id,
                                          })
                                        }}>
                                        Notes and Thoughts
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                        ) : (
                          <div style={{ margin: '-18px 10px 10px 22px' }}>
                            {this.state.type === 'walkin' &&
                              this.props.getAppointment.data.filter(
                                e => e.appointment_type === 'walkin'
                              ).length === 0 && (
                                <NoRecord
                                  header='You Have No Walk-ins Today So Far'
                                  text='strives to be the best Practice Management Software for Mental Health Experts exclusively'
                                />
                              )}
                          </div>
                        )}

                        {this.props.getAppointment.data.filter(e =>
                          this.state.type === 'online'
                            ? e.appointment_type !== 'walkin' && e.noShow
                            : e.appointment_type === this.state.type && e.noShow
                        ).length > 0 && (
                          <div class='chat-seprator1'>
                            <span>
                              No Show{' '}
                              {/* {
                              this.props.getAppointment.data.filter((e) =>
                                this.state.type === "online"
                                  ? e.appointment_type !== "walkin" && e.noShow
                                  : e.appointment_type === this.state.type &&
                                    e.noShow
                              ).length
                            } */}
                            </span>
                          </div>
                        )}
                        {this.props.getAppointment.data
                          .filter(e =>
                            this.state.type === 'online'
                              ? e.appointment_type !== 'walkin' && e.noShow
                              : e.appointment_type === this.state.type &&
                                e.noShow
                          )
                          .map(ea => (
                            <div class='person-details d-flex align-items-center justify-content-between'>
                              <div class='person-details-box d-flex align-items-center flex-grow-1'>
                                <div class='person-img has-active'>
                                  {ea.patient_id.thumb ? (
                                    <img
                                      src={
                                        process.env.REACT_APP_S3URL +
                                        ea.patient_id.thumb
                                      }
                                      alt=''
                                    />
                                  ) : (
                                    <AvtarImage />
                                  )}
                                </div>
                                <div class='person-text-box'>
                                  <h3>
                                    {ea.patient_id.first_name +
                                      ' ' +
                                      ea.patient_id.last_name}

                                    {ea.patient_id.star.length > 0 &&
                                      ea.patient_id.star.find(
                                        e => e === this.props.doctorProfile._id
                                      ) && (
                                        <i>
                                          <img src={blueStar} alt='star-icon' />
                                        </i>
                                      )}
                                  </h3>
                                  <div class='patient-btn d-flex align-items-center justify-content-left'>
                                    <span class='patient-tags ml-0 light-blue'>
                                      {ea.appointment_type}
                                    </span>

                                    <a class='profile-box'>
                                      {ea.patient_id.type === 2 ? (
                                        <i class='fas fa-child'></i>
                                      ) : (
                                        <i class='fas fa-user'></i>
                                      )}
                                    </a>
                                  </div>
                                </div>
                              </div>

                              <div class='pd-right d-flex align-items-center justify-content-end flex-grow-1'>
                                {ea.appointment_type === 'online' && (
                                  <>
                                    {this.state.type === 'online' && (
                                      <div class=''>
                                        <a
                                          data-tooltip-id="dashboard-tooltip"
                                          data-tooltip-content={
                                            moment().diff(
                                              moment(
                                                ea.appointment_time
                                              ).subtract({
                                                hours: 5,
                                                minutes: 30,
                                              }),
                                              'minutes'
                                            ) > -10 ||
                                            serverMode === 'development'
                                              ? ''
                                              : 'Join will be active 10 minutes before appointment'
                                          }
                                          class='btn btn-blue'
                                          onClick={e => {
                                            if (
                                              moment().diff(
                                                moment(
                                                  ea.appointment_time
                                                ).subtract({
                                                  hours: 5,
                                                  minutes: 30,
                                                }),
                                                'minutes'
                                              ) > -10 ||
                                              serverMode === 'development'
                                            ) {
                                              this.onCall(e, ea._id)
                                            }
                                          }}
                                          style={{
                                            cursor:
                                              moment().diff(
                                                moment(
                                                  ea.appointment_time
                                                ).subtract({
                                                  hours: 5,
                                                  minutes: 30,
                                                }),
                                                'minutes'
                                              ) > -10 ||
                                              serverMode === 'development'
                                                ? 'pointer'
                                                : 'initial',
                                          }}>
                                          Join Call
                                        </a>
                                      </div>
                                    )}
                                  </>
                                )}
                                <strong>
                                  {moment(ea.appointment_time)
                                    .utc()
                                    .format('h:mm a')}
                                </strong>
                                <div class='dropright'>
                                  <a
                                    class='dropdown-toggle'
                                    href='#'
                                    data-toggle='dropdown'
                                    aria-haspopup='true'
                                    aria-expanded='false'
                                    onClick={e => {
                                      e.preventDefault()
                                      e.stopPropagation()
                                      this.setState({ appointmentId: ea._id })
                                    }}>
                                    <img src={vDot} alt='' />
                                  </a>
                                  <div
                                    class={
                                      this.state.appointmentId === ea._id
                                        ? 'dropdown-menu dropdown-custom show'
                                        : 'dropdown-menu dropdown-custom'
                                    }
                                    style={
                                      this.state.appointmentId === ea._id
                                        ? isMobile
                                          ? {
                                              zIndex: 99999999999,
                                              position: 'absolute',
                                              transform:
                                                'translate3d(-173px, 0px, 0px)',
                                              top: '0px',
                                              left: '0px',
                                              willChange: 'transform',
                                            }
                                          : {}
                                        : {}
                                    }
                                    x-placement={
                                      isMobile ? 'left-start' : 'right-start'
                                    }>
                                    <a
                                      class='dropdown-item'
                                      href='#!'
                                      onClick={e => {
                                        e.preventDefault()
                                        this.props.history.push({
                                          pathname: '/doctor/patient-detail',
                                          state: ea.patient_id._id,
                                        })
                                      }}>
                                      View Profile
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                      </>
                    </div>
                  </div>
                </div>
                <SideTabs
                  appointment_date={this.state.date.format('YYYY-MM-DD')}
                />
              </div>
            </div>
          )}
          <Modal
            className='modal fade custom-modal'
            show={this.state.cancelConfirm}
            onHide={e => this.setState({ cancelConfirm: false })}>
            <div class='modal-body'>
              <h6>Cancel Appointment</h6>
              <p>Are you sure you want to cancel the appointment?</p>{' '}
              <div class='custom-modal-btn'>
                <a
                  disabled={this.state.loader}
                  href='#!'
                  class={
                    this.state.loader
                      ? 'btn btn-primary btn-block disabled'
                      : 'btn btn-primary btn-block'
                  }
                  onClick={e =>
                    this.cancellAppointment(e, this.state.appointId)
                  }>
                  Yes
                </a>
                <a
                  href='#!'
                  class='btn-text'
                  onClick={e => this.setState({ cancelConfirm: false })}>
                  Don’t Cancel
                </a>
              </div>
            </div>
          </Modal>
          <Modal
            className='modal fade custom-modal'
            show={this.state.popUp}
            onHide={this.toggle}>
            <div class='modal-body'>
              <h6>Modify Appointment?</h6>
              <p>Do you wish to cancel or reschedule your appointment</p>
              <div class='custom-modal-btn'>
                <a
                  disabled={this.state.loader}
                  href='#!'
                  class={
                    this.state.loader
                      ? 'btn btn-primary btn-block disabled'
                      : 'btn btn-primary btn-block'
                  }
                  onClick={e => this.setState({ cancelConfirm: true })}>
                  Cancel Appointment
                </a>
                <a
                  onClick={e => this.reSchule(e)}
                  href='#!'
                  class='btn btn-outline no-shadow btn-block'>
                  Reschedule Appointment
                </a>
                <a href='#!' class='btn-text' onClick={this.toggle}>
                  Don’t Modify
                </a>
              </div>
            </div>
          </Modal>
        </div>
      </>
    )
  }
}
const mapDispatchToProps = dispatch => ({
  loadSeo: page_key => dispatch(loadSeo(page_key)),
  loadDoctorProfile: () => dispatch(loadDoctorProfile()),
  loadAllDoctorpatient: params => dispatch(loadAllDoctorpatient(params)),
  loadAllAppointmentForDoctor: params =>
    dispatch(loadAllAppointmentForDoctor(params)),
  loadAppointmentDetail: (id, callback) =>
    dispatch(loadAppointmentDetail(id, callback)),
  loadList: params => dispatch(loadList(params)),
  loadChatList: params => dispatch(loadChatList(params)),
  noShow: (id, callback) => dispatch(noShow(id, callback)),
  cancellAppointment: (id, callBack) => dispatch(calcelByDoctor(id, callBack)),
})
const mapStateToProps = state => ({
  profile: getDoctor(state).profile,
  profileLoading: getDoctor(state).profileLoading,
  doctorProfile: getDoctor(state).profile,
  getDoctorPatient: getDoctorPatient(state).doctorPatient.pagination,
  getAppointment: getAppointment(state).doctorAppointment,
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DoctorDashboard)
)
