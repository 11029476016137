import React, { Component } from 'react'
import close from '../../../../include/images/icon-close.svg'
import bluestar from '../../../../include/images/blue-star-icon.svg'
import star from '../../../../include/images/start-0.svg'
import file from '../../../../include/images/prescription-file-icon.svg'
import IncompletePrescription from './incompletePrescription'
import {
  loadForPatient,
  getPrescription,
  loadList,
} from '../../../../store/prescription'
import { getDoctor } from '../../../../store/doctor'
import { loadDetailAssessment } from '../../../../store/assessment'
import { connect } from 'react-redux'
import Moment from 'moment'
import moment from 'moment'
import _ from 'lodash'
import down from '../../../../include/images/down-icon-1.svg'
import AvtarImage from '../../../common/avtarImage'
import TreatmentPlan from './treatmentPlan'
import { loadAnswertreatment, getPlan } from '../../../../store/treatmentPlan'
import FollowupForm from '../followUp/followupForm'
import { loadAnswerFollowup, getFollowup } from '../../../../store/followUp'
import loaderIcon from '../../../../include/images/loading-icon2.svg'
import { Tooltip as ReactTooltip } from 'react-tooltip'

class ListFollowup extends Component {
  state = {
    isShow2: false,
    id: '',
    number: '',
    age: '',
    list_id: '',
    caseRecord: '',
    detail: false,
    appointment_time: '',
    planForm: false,
    patient_id: '',
    uid: '',
  }

  togglePlan = () => {
    this.setState({ planForm: !this.state.planForm })
  }

  toggleDetail = list_id => {
    this.setState({
      list_id: list_id,
      detail: !this.state.detail,
    })
  }

  toggle2 = (
    id,
    num,
    age,
    type,
    caseRecord,
    appointment_time,
    patient_id,
    uid
  ) => {
    this.setState({
      isShow2: !this.state.isShow2,
      id: id,
      number: num,
      age: age,
      type: type,
      caseRecord: caseRecord,
      appointment_time,
      patient_id,
      uid,
    })
    this.props.loadList({
      appointment_date: this.props.appointment_date,
    })
    if (this.props.tab && this.props.tab === 'follow') {
      if (this.props?.doctorProfile?.field_type?.name !== 'Psychologist') {
        this.props.loadDetailAssessment('60e008b712b30d4b9468b9e4')
      } else {
        this.props.loadDetailAssessment('60eff53584694c08054f57c4')
      }
    }
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.props.id && this.props.id !== prevProps.id) {
      this.props.loadForPatient(this.props.id)
      this.props.loadAnswertreatment(this.props.id)
    }
  }

  componentDidMount = () => {
    if (this.props.tab && this.props.tab === 'follow') {
      this.props.loadForPatient(this.props.id)
    }
  }

  loadRefresh = () => {
    this.props.loadForPatient(this.props.id)
    this.props.loadAnswertreatment(this.props.id)
  }
  render() {
    const { forPatient } = this.props
    const followUpData = forPatient.filter(item => item.followup)
    const monthName = item =>
      item.followupNotes && item.followupNotes.is_submitted ? true : false
    const result = _.groupBy(followUpData, monthName)
    const groupArrays = Object.keys(result).map(status => {
      return {
        status,
        doc: result[status],
      }
    })
    // console.log(
    //   "palnAnswergroupArrays",
    //   _.chunk(Object.keys(this.props.followUpAnswer.data.formData), 2)
    // );
    const chunks =
      this.props.followUpAnswer.data && this.props.followUpAnswer.data.formData
        ? _.chunk(
            Object.keys(this.props.followUpAnswer.data.formData).filter(
              eds => eds !== 'followup'
            ),
            2
          )
        : []

    const { isVideoCall = false } = this.props
    const copyPrescriptionData =
      (groupArrays &&
        groupArrays.filter(ele => ele.status == 'true').map(ele => ele.doc)) ||
      []
    const prescriptionMapData =
      copyPrescriptionData && copyPrescriptionData.length > 0
        ? copyPrescriptionData[0].map(ele => ({
            ...ele,
            label:
              'Followup ' + moment(ele.appointment_time).utc().format('LT'),
            value: ele._id,
          }))
        : []
    // console.log('prescriptionMapData', prescriptionMapData)
    return (
      <>
        <ReactTooltip className='testPurple' id='listfollowup-tooltip'/>
        <div
          class={isVideoCall ? '' : 'sidebar-box list-box'}
          id='prescriptions_side_panel '
          style={{ right: this.props.isShow1 === true ? '0px' : '-100%' }}>
          {!isVideoCall && (
            <div class='sidebar-head d-flex align-items-center'>
              <div
                className='d-flex align-items-center justify-content-between'
                style={{ width: '93%' }}>
                <h5>Follow Up Notes</h5>
              </div>

              <div class='sidebar-right ml-auto d-flex align-items-centers'>
                <div
                  class='close-img'
                  onClick={() => this.props.toggle1(this.props.id)}>
                  <img src={close} alt='' />
                </div>
              </div>
            </div>
          )}
          <div class='sidebar-body height-auto p-0'>
            <div class='sidebar-inner-content'>
              {!isVideoCall && (
                <div class='sic-head d-flex flex-wrap justify-content-between'>
                  <div class='sic-head-left flex-grow-1 d-flex '>
                    <div class='sic-head-img'>
                      {this.props.image ? (
                        <img
                          src={process.env.REACT_APP_S3URL + this.props.image}
                          alt=''
                        />
                      ) : (
                        <AvtarImage />
                      )}
                    </div>
                    <div class='sic-head-text'>
                      <h4>
                        {this.props.first_name + ' ' + this.props.last_name}{' '}
                        <i>
                          {' '}
                          {this.props.star === true ? (
                            <img src={bluestar} alt='star-icon' />
                          ) : (
                            <img src={star} alt='star-icon' />
                          )}
                        </i>
                      </h4>
                      <p>{this.props.yesmindy_identification_number}</p>
                    </div>
                  </div>
                  {this.props.lastAppointment_time &&
                    'Last Appointment: ' +
                      moment(this.props.lastAppointment_time)
                        .utc()
                        .format('DD-MM-YYYY')}
                </div>
              )}
              <div class='sic-body'>
                <>
                  {this.props.loadingPatient ? (
                    <div>
                      <b className='d-flex justify-content-center align-items-center pt-4 text-center'>
                        <img
                          src={loaderIcon}
                          alt='loader'
                          className='loader-img fa-spin'
                          style={{ height: '15px;' }}
                        />
                      </b>
                    </div>
                  ) : (
                    groupArrays.length === 0 && 'No previous appointment found.'
                  )}
                  {groupArrays.map((e2, i) => (
                    <>
                      {e2.status === 'false' && (
                        <>
                          {e2.doc.map((e1, i) => (
                            <>
                              <div class='tpc-month-box'>
                                <h6>
                                  {Moment(e1.date.replace('-', '/')).format(
                                    'DD-MMM-YYYY'
                                  )}
                                </h6>
                              </div>
                              <div class='simple-links-box'>
                                <a
                                  href='javascript:void(0)'
                                  onClick={() => {
                                    if (
                                      moment().diff(
                                        moment(e1.appointment_time).subtract({
                                          hours: 5,
                                          minutes: 30,
                                        }),
                                        'minutes'
                                      ) >= 0
                                    ) {
                                      const diagnosisPsychiatristData =
                                        e1.caseRecord.finish &&
                                        e1.caseRecord.finish[
                                          e1.doctor_id.field_type.name ===
                                          'Psychologist'
                                            ? 'diagnosis'
                                            : 'diagnosisPsychiatrist'
                                        ]
                                      this.toggle2(
                                        e1._id,
                                        e2.doc.length - i,
                                        e1.patient_id.date_of_birth,
                                        e1.doctor_id.field_type &&
                                          e1.doctor_id.field_type.name ===
                                            'Psychiatrist'
                                          ? 'Psychiatrist'
                                          : 'Psychologist',

                                        diagnosisPsychiatristData,
                                        e1.appointment_time,
                                        e1.patient_id._id,
                                        e1.patient_id
                                          .yesmindy_identification_number
                                      )
                                    }
                                  }}
                                  data-tooltip-id='listfollowup-tooltip'
                                  data-tooltip-content={
                                    moment().diff(
                                      moment(e1.appointment_time).subtract({
                                        hours: 5,
                                        minutes: 30,
                                      }),
                                      'minutes'
                                    ) < 0
                                      ? 'Will be active after appointment time begins'
                                      : 'aaaaa'
                                  }
                                  style={{
                                    cursor:
                                      moment().diff(
                                        moment(e1.appointment_time).subtract({
                                          hours: 5,
                                          minutes: 30,
                                        }),
                                        'minutes'
                                      ) < 0
                                        ? 'initial'
                                        : 'pointer',
                                    opacity:
                                      moment().diff(
                                        moment(e1.appointment_time).subtract({
                                          hours: 5,
                                          minutes: 30,
                                        }),
                                        'minutes'
                                      ) < 0
                                        ? '0.5'
                                        : 1,
                                  }}
                                  class='slb-link-box'
                                  id='prescriptions10'
                                  data-toggle='prescriptions_side_panel_1'>
                                  <div class='slb-box d-flex flex-wrap'>
                                    <div class='slb-img'>
                                      <img src={file} alt='' />
                                    </div>
                                    <div class='slb-text'>
                                      <div class='d-flex align-items-center'>
                                        <h3>
                                          Followup{' '}
                                          {moment(e1.appointment_time)
                                            .utc()
                                            .format('LT')}{' '}
                                        </h3>
                                        <div class='ml-auto'>
                                          <span class='new-tags ml-2'>
                                            {' '}
                                            Incomplete
                                          </span>
                                        </div>
                                      </div>
                                      <p>
                                        <strong> {e1.doctor_id.name}</strong>,{' '}
                                        {e1.doctor_id.field_type.name}
                                      </p>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </>
                          ))}
                        </>
                      )}
                    </>
                  ))}
                </>
                {groupArrays.map((e2, i) => (
                  <>
                    {e2.status === 'true' && (
                      <div class='accordion' id='accordion1'>
                        {e2.doc.map((e1, i) => (
                          <>
                            <div class='tpc-month-box'>
                              <h6>
                                {Moment(e1.date.replace('-', '/')).format(
                                  'DD-MMM-YYYY'
                                )}
                              </h6>
                            </div>
                            <div class='item'>
                              <div
                                className={
                                  this.state.detail === true &&
                                  this.state.list_id == e1._id
                                    ? 'ih-box'
                                    : 'ih-box collapsed'
                                }
                                data-toggle='collapse'
                                data-target='#accor6'
                                aria-expanded={
                                  (this.state.detail === true &&
                                    this.state.list_id == e1._id &&
                                    'true') ||
                                  (this.state.detail === false && 'false')
                                }>
                                <div class='slb-box d-flex flex-wrap'>
                                  <div class='slb-img'>
                                    <img src={file} alt='' />
                                  </div>
                                  <div class='slb-text'>
                                    <h3
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        this.props.loadAnswerFollowup({
                                          patient_id: e1.patient_id._id,
                                          appointment_id: e1._id,
                                        })
                                        this.toggleDetail(e1._id)
                                      }}>
                                      {' '}
                                      Followup{' '}
                                      {moment(e1.appointment_time)
                                        .utc()
                                        .format('LT')}{' '}
                                    </h3>
                                    <p>
                                      <strong>{e1.doctor_id.name}</strong>,{' '}
                                      {e1.doctor_id.field_type.name}
                                    </p>
                                  </div>
                                </div>
                                <div class='slb-icons-outer d-flex align-items-center justify-content-end'>
                                  <span
                                    style={{ cursor: 'pointer' }}
                                    class='icon'
                                    onClick={() => {
                                      this.props.loadAnswerFollowup({
                                        patient_id: e1.patient_id._id,
                                        appointment_id: e1._id,
                                      })
                                      this.toggleDetail(e1._id)
                                    }}>
                                    <i>
                                      <img src={down} alt='' />
                                    </i>
                                  </span>
                                </div>
                              </div>
                              <div
                                id='accor6'
                                className={
                                  (this.state.detail === true &&
                                    this.state.list_id == e1._id &&
                                    'collapse show') ||
                                  (this.state.detail === false && 'collapsing')
                                }
                                data-parent='#accordion1'>
                                {this.state.list_id == e1._id && (
                                  <>
                                    <div class='t-p'>
                                      <div class='sidebar-info-box d-flex flex-wrap'>
                                        <div class='sib-left'>
                                          <h5>
                                            {e1.patient_id.first_name +
                                              ' ' +
                                              e1.patient_id.last_name}
                                          </h5>
                                          <p>
                                            Age:{' '}
                                            {moment().diff(
                                              e1.patient_id.date_of_birth,
                                              'years'
                                            )}{' '}
                                            yrs
                                          </p>
                                          {<p> Gender: {e1.patient_id.sex}</p>}
                                        </div>
                                        <div class='sib-right'>
                                          <h5>
                                            <small>
                                              UHID:{' '}
                                              {
                                                e1.patient_id
                                                  .yesmindy_identification_number
                                              }
                                            </small>
                                          </h5>
                                          <p>
                                            Date:{' '}
                                            {moment(e1.appointment_time)
                                              .utc()
                                              .format('lll')}
                                          </p>
                                        </div>
                                      </div>

                                      <div class='accor-seprator'></div>
                                      {chunks.map(echu => {
                                        const echu1 =
                                          this.props.followUpAnswer.data
                                            .formData[echu[0]]
                                        const echu2 =
                                          this.props.followUpAnswer.data
                                            .formData[echu[1]]
                                        const data1 =
                                          Array.isArray(echu1) &&
                                          echu1.length > 0 &&
                                          typeof echu1[0] === 'object'
                                            ? echu1[0]?.label
                                            : Array.isArray(echu1) &&
                                              echu1.length > 0 &&
                                              typeof echu1[0] === 'string'
                                            ? echu1[0]
                                            : typeof echu1 === 'object'
                                            ? echu1?.label
                                            : typeof echu1 === 'string'
                                            ? echu1
                                            : ''
                                        const data2 =
                                          Array.isArray(echu2) &&
                                          echu2.length > 0 &&
                                          typeof echu2[0] === 'object'
                                            ? echu2[0]?.label
                                            : Array.isArray(echu2) &&
                                              echu2.length > 0 &&
                                              typeof echu2[0] === 'string'
                                            ? echu2[0]
                                            : typeof echu2 === 'object'
                                            ? echu2?.label
                                            : typeof echu2 === 'string'
                                            ? echu2
                                            : ''

                                        return (
                                          <div class='sidebar-content-box d-flex flex-wrap'>
                                            <div class='scb-left'>
                                              <p>{echu[0]}</p>
                                              <h6>{data1}</h6>
                                            </div>
                                            <div class='scb-right'>
                                              <p>{echu[1]}</p>
                                              <h6>
                                                <b>{data2}</b>
                                              </h6>
                                            </div>
                                          </div>
                                        )
                                      })}
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            this.props.isShow1 === true ? 'overlay4 show' : 'overlay4'
          }></div>
        <FollowupForm
          caseRecord={this.state.caseRecord}
          appointment_id={this.state.id}
          patient_id={this.state.patient_id}
          id={this.state.patient_id}
          yesmindy_identification_number={this.state.uid}
          sex={this.props.sex}
          age={this.state.age}
          toggle1={this.props.toggle1}
          first_name={this.props.first_name}
          last_name={this.props.last_name}
          image={this.props.image}
          star={this.props.star}
          time={this.state.appointment_time}
          isShow2={this.state.isShow2}
          toggle2={this.toggle2}
          loadList={() => null}
          appointment_date={this.props.appointment_date}
          appointment_date_video={this.props.appointment_date}
          video={this.props.video}
          loadRefresh={this.loadRefresh}
          prescriptionMapData={prescriptionMapData}
        />
        {/* <IncompletePrescription
          time={this.state.appointment_time}
          number={this.state.number}
          yesmindy_identification_number={
            this.props.yesmindy_identification_number
          }
          age={this.state.age}
          sex={this.props.sex}
          toggle1={this.props.toggle1}
          first_name={this.props.first_name}
          last_name={this.props.last_name}
          image={this.props.image}
          star={this.props.star}
          isShow2={this.state.isShow2}
          toggle2={this.toggle2}
          id={this.state.id}
          patient_id={this.props.id}
          type={this.state.type}
          caseRecord={
            this.state.caseRecord ? this.state.caseRecord.join(", ") : ""
          }
          appointment_date={this.props.appointment_date}
        /> */}
        <TreatmentPlan
          planForm={this.state.planForm}
          togglePlan={this.togglePlan}
          patientId={this.props.id}
        />
      </>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  loadDetailAssessment: id => dispatch(loadDetailAssessment(id)),
  loadList: params => dispatch(loadList(params)),
  loadForPatient: id => dispatch(loadForPatient(id)),
  loadAnswertreatment: id => dispatch(loadAnswertreatment(id)),
  loadAnswerFollowup: params => dispatch(loadAnswerFollowup(params)),
})
const mapStateToProps = state => ({
  doctorProfile: getDoctor(state).profile,
  forPatient: getPrescription(state).forPatient,
  loadingPatient: getPrescription(state).loading,
  planAnswer: getPlan(state).planAnswer,
  followUpAnswer: getFollowup(state).answer,
})
export default connect(mapStateToProps, mapDispatchToProps)(ListFollowup)
