import React, { Component } from 'react'
import Joi from 'joi-browser'
import Form from '../../common/form/form'
import { connect } from 'react-redux'
import DashboardTopBar from './common/dashboardTopBar'
import Moment from 'moment'
import AlertError from '../../common/alertError'
import { toast } from 'react-toastify'
import seUploadFun from '../../services/s3Services'
import { WithContext as ReactTags } from 'react-tag-input'
import {
  updatePatientHistory,
  loadPatientProfile,
  getPatient,
} from '../../../store/patient'
import { withRouter } from 'react-router-dom'
import rocket from './../../../include/images/rocket.svg'
import SubmitBtn from '../../common/form/submitBtn'
import _ from 'lodash'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import CreatableSelect from 'react-select/creatable'
import loaderIcon from '../../../include/images/loading-icon.svg'
const KeyCodes = {
  comma: 188,
  enter: 13,
}

const delimiters = [KeyCodes.comma, KeyCodes.enter]

const illness1 = [
  { Illness: 'None' },
  { Illness: 'Gynecological' },
  { Illness: 'Allergies' },
  { Illness: 'Hypertension' },
  { Illness: 'Asthma' },
  { Illness: 'Hypothyroidism' },
  { Illness: 'Cardiac' },
]

const illness2 = [
  { Illness: 'Neurological' },
  { Illness: 'Diabetes' },
  { Illness: 'Respiratory' },
  { Illness: 'Gastroenterology' },
  { Illness: 'Others' },
]

class PatientHistory extends Form {
  state = {
    key: '',
    inputValue: '',
    value: [],
    btnClass: 'btn btn-primary btn-lg',
    loading: false,
    problems: [],
    time: '_year',
    patient_id: '',
    surgery1: '',
    physical_illnesses: [],
    data: {
      complaints_course: '',
      physical_illnesses_diagnosis: '',
      treatment_regularity: '',
      past_mental_health_treatment: '',
      problems_since: '',
      medicine_name: '',
      therapy_name: '',
      improvement_observed: '',
      recent_prescription: '',
      most_important_concern: '',
      problem_after_stress_or_life_event: '',
      expectations_from_yesmindy: '',
      referred_by: '',
      surgery: '',
      physical_illnesses_other: '',
      detail: '',
    },
    errors: {},
  }

  schema = {
    detail: Joi.string().allow(''),
    expectations_from_yesmindy: Joi.string()
      .allow('')
      .error(() => {
        return { message: 'Expectations from YesMindy field is required' }
      }),
    problem_after_stress_or_life_event: Joi.string()
      .allow('')
      .error(() => {
        return {
          message: 'Problem after stress or life event field is required',
        }
      }),
    most_important_concern: Joi.string().allow(''),

    referred_by: Joi.string().allow(''),
    complaints_course: Joi.string()
      .allow('')
      .error(() => {
        return { message: 'Course field is required' }
      }),
    physical_illnesses_diagnosis: Joi.string()
      .allow('')
      .error(() => {
        return { message: 'Physical Illness Diagnosis field is required' }
      }),
    treatment_regularity: Joi.string().allow(''),

    improvement_observed: Joi.string().allow(''),

    past_mental_health_treatment: Joi.string()
      .allow('')
      .error(() => {
        return { message: 'Past Mental Health Treatment field is required' }
      }),
    problems_since: Joi.number().label('enter a number').allow(''),
    medicine_name: Joi.string()
      .allow('')
      .label('Most Recent Taken. Names & Dose.'),
    therapy_name: Joi.string().allow('').label('Recent Conducted'),
    medicine: Joi.string().allow('').label('Most Recent Taken. Names & Dose'),
    recent_prescription: Joi.string().allow(''),
    surgery: Joi.string().allow(''),
    physical_illnesses_other: Joi.string().allow(''),
  }
  componentDidMount = () => {
    document.body.classList.remove('p-0')
    document.body.classList.add('dashboard')
    if (localStorage.getItem('patient')) {
      this.props.loadPatientProfile()
    }
  }
  componentWillUnmount = () => {
    if (!this.props.drawer) {
      document.body.classList.remove('dashboard')
      document.body.classList.add('p-0')
    }
  }

  changeLang = Lang => {
    let physical_illnesses = [...this.state.physical_illnesses]

    const find = physical_illnesses.find(v => v === Lang)
    if (find) {
      const f = physical_illnesses.filter(vf => vf !== Lang)
      physical_illnesses = f
    } else {
      physical_illnesses.push(Lang)
    }
    this.setState({ physical_illnesses })
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.props.patientProfile &&
      this.props.patientProfile._id &&
      !this.props.loading &&
      this.props.loading !== prevProps.loading
    ) {
      const {
        patient_id,
        problems,
        problems_since,
        complaints_course,
        past_mental_health_treatment,
        treatment_regularity,
        therapy_name,
        improvement_observed,
        recent_prescription,
        physical_illnesses,
        physical_illnesses_diagnosis,
        most_important_concern,
        problem_after_stress_or_life_event,
        expectations_from_yesmindy,
        referred_by,
        medicine,
        surgery,
        detail,
        physical_illnesses_other,
      } = this.props.patientProfile
      const data = {
        problems_since: problems_since[0],
        therapy_name,
        recent_prescription,
        complaints_course,
        past_mental_health_treatment,
        treatment_regularity,
        improvement_observed,
        physical_illnesses_diagnosis,
        most_important_concern,
        problem_after_stress_or_life_event,
        expectations_from_yesmindy,
        referred_by,
        medicine,
        surgery,
        detail,
        physical_illnesses_other,
      }
      this.setState({
        data,
        patient_id: patient_id,
        value: problems.map(el => ({ label: el, value: el })),
        physical_illnesses: physical_illnesses,
        time: problems_since.slice(1, problems_since.length),
      })
      if (data.surgery) {
        this.setState({ surgery1: 'yes1' })
      } else {
        this.setState({ surgery1: 'no1' })
      }
    }
  }
  handleDeleteproblems = i => {
    const { problems } = this.state
    this.setState({
      problems: problems.filter((tag, index) => index !== i),
    })
  }

  handleAdditionproblems = tag => {
    this.setState(state => ({ problems: [...state.problems, tag] }))
  }
  toggleRadio = e => {
    this.setState({ time: e.target.value })
  }
  toggleCourse = e => {
    const data = { ...this.state.data }
    data.complaints_course = e.target.id
    this.setState({ data })
  }
  toggleRefer = e => {
    const data = { ...this.state.data }
    data.referred_by = e.target.id
    this.setState({ data })
  }

  toggleConcern = e => {
    const data = { ...this.state.data }
    data.most_important_concern = e.target.id
    this.setState({ data })
  }
  toggleMental = e => {
    const data = { ...this.state.data }
    data.past_mental_health_treatment = e.target.id
    this.setState({ data })
  }
  toggleRegularity = e => {
    const data = { ...this.state.data }
    data.treatment_regularity = e.target.id
    this.setState({ data })
  }
  toggleImprovement = e => {
    const data = { ...this.state.data }
    data.improvement_observed = e.target.id
    this.setState({ data })
  }
  toggleIllness = e => {
    const data = { ...this.state.data }
    data.physical_illnesses_diagnosis = e.target.id
    this.setState({ data })
  }
  toggleSurgery = e => {
    this.setState({
      surgery1: e.target.id,
    })
  }

  fileUpload = e => {
    this.setState({ photoStatus: 'Loading' })
    const fileName = 'patient/photo/' + Date.now()
    const res = seUploadFun(e.target.files[0], fileName)
    res.then(result => {
      const { data, errors } = this.state
      data.recent_prescription = result.key
      errors.recent_prescription = false
      this.setState({
        data,
      })
    })
  }

  doSubmit = () => {
    if (localStorage.getItem('patient')) {
      const { time, value, patient_id, physical_illnesses, surgery1 } =
        this.state
      const {
        problems_since,
        medicine,
        therapy_name,
        complaints_course,
        recent_prescription,
        past_mental_health_treatment,
        improvement_observed,
        treatment_regularity,
        physical_illnesses_diagnosis,
        most_important_concern,
        problem_after_stress_or_life_event,
        expectations_from_yesmindy,
        referred_by,
        surgery,
        physical_illnesses_other,
        detail,
      } = this.state.data

      const { _id } = JSON.parse(localStorage.getItem('patient'))
      const data = {
        patient_id: _id,
        problems: value.map(el => el.value),
        problems_since: problems_since + time,
        complaints_course,
        past_mental_health_treatment,
        therapy_name,
        treatment_regularity,
        improvement_observed,
        recent_prescription,
        physical_illnesses_diagnosis,
        physical_illnesses,
        most_important_concern,
        problem_after_stress_or_life_event,
        expectations_from_yesmindy,
        referred_by,
        medicine,
        detail,
        physical_illnesses_other: physical_illnesses.find(el => el === 'Others')
          ? physical_illnesses_other
          : '',
        surgery: surgery1 === 'yes1' ? surgery : '',
      }
      if (
        physical_illnesses.find(el => el === 'Others') &&
        this.state.data.physical_illnesses_other === undefined
      ) {
        this.setState({
          errors: {
            physical_illnesses_other: 'This field is required.',
          },
        })
      } else if (
        (past_mental_health_treatment === 'both' &&
          treatment_regularity === '' &&
          improvement_observed === '') ||
        (past_mental_health_treatment === 'medicinal' &&
          treatment_regularity === '' &&
          improvement_observed === '') ||
        (past_mental_health_treatment === 'therpy' &&
          treatment_regularity === '' &&
          improvement_observed === '')
      ) {
        this.setState({
          errors: {
            treatment_regularity: 'Treatment Regularity field is required.',
            improvement_observed: 'Improvement observed field is required.',
          },
        })
      } else if (
        (past_mental_health_treatment === 'both' &&
          treatment_regularity === '') ||
        (past_mental_health_treatment === 'medicinal' &&
          treatment_regularity === '') ||
        (past_mental_health_treatment === 'therpy' &&
          treatment_regularity === '')
      ) {
        this.setState({
          errors: {
            treatment_regularity: 'Treatment Regularity field is required.',
          },
        })
      } else if (
        (past_mental_health_treatment === 'both' &&
          improvement_observed === '') ||
        (past_mental_health_treatment === 'medicinal' &&
          improvement_observed === '') ||
        (past_mental_health_treatment === 'therpy' &&
          improvement_observed === '')
      ) {
        this.setState({
          errors: {
            improvement_observed: 'Improvement observed field is required.',
          },
        })
      } else {
        const newData = _.pickBy(data, _.identity)
        this.props.updatePatientHistory(newData, this.callBackUpdate)
      }
    } else {
      if (!this.state.loading) {
        this.setState({
          btnClass: 'btn btn-primary btn-lg disabled',
          loading: true,
        })
      }
    }
  }
  callBackUpdate = res => {
    this.setState({
      btnClass: 'btn btn-primary btn-lg',
      loading: false,
    })
    if (res && res.status === 200) {
      if (this.props.drawer) {
        this.props.loadPatientProfile()
        this.props.toggleDrawer()
      } else {
        this.props.loadPatientProfile()
        this.props.history.push('/patient/dashboard')
        //this.props.updateStep(null, 5)
      }
    } else {
      toast(<AlertError message='failed' />)
    }
  }
  handleInputChange = inputValue => {
    const { value } = this.state
    if (inputValue.length > 1) {
      console.log('inputValue', inputValue.charAt(inputValue.length - 1))
      switch (inputValue.charAt(inputValue.length - 1)) {
        case '':
        case ',':
        case 'Enter':
        case 'Tab':
          console.group('Value Added')

          console.groupEnd()

          this.setState({
            inputValue: '',
            value: [...value, this.createOption(inputValue.slice(0, -1))],
          })
          inputValue = ''
      }
    }
    this.setState({ inputValue })
  }
  createOption = label => ({
    label,
    value: label,
  })
  handleKeyDown = event => {
    const { inputValue, value } = this.state
    if (!inputValue) return
    switch (event.key) {
      case ',':
      case 'Enter':
      case 'Tab':
        console.group('Value Added')
        console.log(value)
        console.groupEnd()
        this.setState({
          inputValue: '',
          value: [...value, this.createOption(inputValue)],
        })
        event.preventDefault()
    }
  }
  handleChangeP = (value, actionMeta) => {
    console.group('Value Changed')
    console.log('value', value)
    console.log(`action: ${actionMeta.action}`)
    console.groupEnd()
    this.setState({ value: value ? value : [] })
  }
  render() {
    const components = {
      DropdownIndicator: null,
    }
    const customStyles = {
      indicatorSeparator: styles => ({ display: 'none' }),
      option: (provided, state) => ({
        ...provided,
        '&:hover': {
          backgroundColor: 'rgb(95, 50, 187, 10%)',
          color: '#621ac0',
        },

        'color': state.isSelected ? '#621ac0' : 'black',
        'backgroundColor': state.isSelected
          ? 'rgb(95, 50, 187, 10%)'
          : provided.backgroundColor,
        //backgroundColor: state.isSelected ? "rgb(95, 50, 187, 10%)" : "white",
      }),
      control: (base, state) => ({
        ...base,
        'minHeight': 55,
        'borderColor': state.isFocused ? '#6119c0' : '#e0e0e0',
        'boxShadow': '0 !important',
        '&:hover': {
          borderColor: '#6119c0',
        },
        '&:focus': {
          borderColor: '#6119c0',
        },
        //backgroundColor: state.isDisabled ? "#aaa" : "white",
      }),
      multiValueRemove: (base, state) => ({
        ...base,
        '&:hover': {
          backgroundColor: 'rgb(95, 50, 187, 10%)',
          color: '#6119c0',
        },
      }),
    }

    const { readable = false } = this.props
    return (
      <div class='dashboard-content-box'>
        <ReactTooltip className='testPurple' id='patientHistory-tooltip' />

        {window.location.pathname ===
        '/patient/dashboard/profile-information' ? (
          ''
        ) : !this.props.drawer ? (
          <DashboardTopBar
            step={this.props.step}
            updateStep={this.props.updateStep}
          />
        ) : (
          ''
        )}
        <form onSubmit={this.handleSubmit} id='my-form'>
          <div
            className={
              window.location.pathname ===
              '/patient/dashboard/profile-information'
                ? 'd-flex'
                : 'dc-body p-0 d-flex flex-wrap history'
            }>
            <div class={`${!this.props.drawer ? 'dc-left' : ''}`}>
              {/* <h4 class="d-block d-xl-none">Your History</h4> */}
              <div class={`${!this.props.drawer ? 'dc-block-box' : ''}`}>
                <div
                  class={`${
                    !this.props.drawer ? 'dc-block' : 'dc-block pl-0'
                  }`}>
                  <div class='dc-block-head'>
                    <h6>Current or past Complaints/Issues/Difficulties</h6>
                    <h6>{this.state.key}</h6>
                  </div>
                  <div className='row'>
                    <div className='col-xl-10'>
                      <CreatableSelect
                        components={components}
                        inputValue={this.state.inputValue}
                        isClearable
                        isMulti
                        //menuIsOpen={false}
                        onChange={this.handleChangeP}
                        onInputChange={this.handleInputChange}
                        onKeyDown={this.handleKeyDown}
                        placeholder='Type something and press enter...'
                        value={this.state.value}
                        styles={customStyles}
                      />
                      {/* <ReactTags
                        classNames={{
                          tagInput: "form-group custom-input",
                          tagInputField: "form-control",
                        }}
                        inline
                        placeholder="E.g. Disturbed sleep, Negative thoughts... "
                        inputFieldPosition="inline"
                        tags={this.state.problems}
                        suggestions={[{ id: "Hello", text: "Hello" }]}
                        handleDelete={this.handleDeleteproblems}
                        handleAddition={this.handleAdditionproblems}
                        delimiters={delimiters}
                      /> */}
                    </div>
                    <div className='col-xl-1'>
                      <i
                        class='fa fa-question-circle'
                        data-tooltip-id='patientHistory-tooltip'
                        data-tooltip-content='Press enter or comma for multiple entries'
                        aria-hidden='true'></i>
                    </div>
                  </div>
                </div>

                <div
                  class={`${
                    !this.props.drawer ? 'dc-block' : 'dc-block pl-0'
                  }`}>
                  <div class='dc-block-head'>
                    <h6>Facing the above problems since</h6>
                  </div>
                  <div class='dc-block-body d-md-flex align-items-center flex-wrap'>
                    {this.renderInput(
                      'problems_since',
                      'Enter a Number',
                      'number'
                    )}
                    <div class='d-md-flex align-items-center ml-lg-4 mob-mt-15'>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='radio'
                          name='radiofordays'
                          id='years'
                          checked={this.state.time === '_year'}
                          value='_year'
                          onChange={e => this.toggleRadio(e)}
                        />
                        <label class='form-check-label ml-4' htmlFor='years'>
                          Years
                        </label>
                      </div>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='radio'
                          name='radiofordays'
                          id='months'
                          value='_month'
                          onChange={e => this.toggleRadio(e)}
                          checked={this.state.time === '_month'}
                        />
                        <label class='form-check-label ml-4' htmlFor='months'>
                          Months
                        </label>
                      </div>
                      <div class='form-check'>
                        <input
                          class='form-check-input'
                          type='radio'
                          name='radiofordays'
                          id='days'
                          checked={this.state.time === '_day'}
                          value='_day'
                          onChange={e => this.toggleRadio(e)}
                        />
                        <label class='form-check-label ml-4' htmlFor='days'>
                          Days
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class={`${
                    !this.props.drawer ? 'dc-block' : 'dc-block pl-0'
                  }`}>
                  <div class='dc-block-head'>
                    <h6>Course of Problems</h6>
                  </div>
                  <div class='dc-block-body course d-md-flex flex-wrap align-items-center'>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='Course'
                        id='relapse'
                        onChange={e => this.toggleCourse(e)}
                        checked={
                          this.state.data.complaints_course === 'relapse'
                        }
                      />
                      <label class='form-check-label ml-4' htmlFor='relapse'>
                        Occur Again
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='Course'
                        id='worsening'
                        onChange={e => this.toggleCourse(e)}
                        checked={
                          this.state.data.complaints_course === 'worsening'
                        }
                      />
                      <label class='form-check-label ml-4' htmlFor='worsening'>
                        Worsening
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='Course'
                        id='fluctuates'
                        onChange={e => this.toggleCourse(e)}
                        checked={
                          this.state.data.complaints_course === 'fluctuates'
                        }
                      />
                      <label class='form-check-label ml-4' htmlFor='fluctuates'>
                        Fluctuates
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='Course'
                        id='stable'
                        onChange={e => this.toggleCourse(e)}
                        checked={this.state.data.complaints_course === 'stable'}
                      />
                      <label class='form-check-label ml-4' htmlFor='stable'>
                        Stable
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='Course'
                        id='improving'
                        onChange={e => this.toggleCourse(e)}
                        checked={
                          this.state.data.complaints_course === 'improving'
                        }
                      />
                      <label class='form-check-label ml-4' htmlFor='improving'>
                        Improving
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='Course'
                        id='cannotsay'
                        onChange={e => this.toggleCourse(e)}
                        checked={
                          this.state.data.complaints_course === 'cannotsay'
                        }
                      />
                      <label class='form-check-label ml-4' htmlFor='cannotsay'>
                        Cannot Say
                      </label>
                    </div>
                  </div>
                  {this.state.errors.complaints_course && (
                    <div className='form-group custom-input'>
                      <label className='error'>
                        {this.state.errors.complaints_course}
                      </label>
                    </div>
                  )}
                </div>
                <div class='block-seprator'></div>

                <div
                  class={`${
                    !this.props.drawer ? 'dc-block' : 'dc-block pl-0'
                  }`}>
                  <div class='dc-block-head'>
                    <h6>Past mental health treatment details</h6>
                  </div>
                  <div class='dc-block-body d-md-flex align-items-center'>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='treatmentDetails'
                        id='medicinal'
                        onChange={e => this.toggleMental(e)}
                        checked={
                          this.state.data.past_mental_health_treatment ===
                          'medicinal'
                        }
                      />
                      <label class='form-check-label ml-4' htmlFor='medicinal'>
                        Medicinal
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='treatmentDetails'
                        id='therpy'
                        onChange={e => this.toggleMental(e)}
                        checked={
                          this.state.data.past_mental_health_treatment ===
                          'therpy'
                        }
                      />
                      <label class='form-check-label ml-4' htmlFor='therpy'>
                        Therapy
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='treatmentDetails'
                        id='both'
                        onChange={e => this.toggleMental(e)}
                        checked={
                          this.state.data.past_mental_health_treatment ===
                          'both'
                        }
                      />
                      <label class='form-check-label ml-4' htmlFor='both'>
                        Both
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='treatmentDetails'
                        id='none'
                        onChange={e => this.toggleMental(e)}
                        checked={
                          this.state.data.past_mental_health_treatment ===
                          'none'
                        }
                      />
                      <label class='form-check-label ml-4' htmlFor='none'>
                        None
                      </label>
                    </div>
                  </div>
                  {this.state.errors.past_mental_health_treatment && (
                    <div className='form-group custom-input'>
                      <label className='error'>
                        {this.state.errors.past_mental_health_treatment}
                      </label>
                    </div>
                  )}
                </div>

                {this.state.data.past_mental_health_treatment !== 'none' && (
                  <>
                    <div
                      class={`${
                        !this.props.drawer ? 'dc-block' : 'dc-block pl-0'
                      }`}>
                      <div class='dc-block-head'>
                        <h6>Name of Medicines</h6>
                      </div>
                      <div class='dc-block-body d-md-flex'>
                        <div class='form-group custom-input min-width-80'>
                          {this.renderInput(
                            'medicine',
                            'Most Recent Taken. Names & Dose.'
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      class={`${
                        !this.props.drawer ? 'dc-block' : 'dc-block pl-0'
                      }`}>
                      <div class='dc-block-head'>
                        <h6>Therapy Name</h6>
                      </div>
                      <div class='dc-block-body d-md-flex'>
                        <div class='form-group custom-input min-width-80'>
                          {this.renderInput('therapy_name', 'Recent Conducted')}
                        </div>
                      </div>
                    </div>
                    <div
                      class={`${
                        !this.props.drawer ? 'dc-block' : 'dc-block pl-0'
                      }`}>
                      <div class='dc-block-head'>
                        <h6>Treatment Regularity</h6>
                      </div>
                      <div class='dc-block-body d-md-flex align-items-center'>
                        <div class='form-check'>
                          <input
                            class='form-check-input'
                            type='radio'
                            name='treatmentRegularity'
                            id='regular'
                            checked={
                              this.state.data.treatment_regularity === 'regular'
                            }
                            onChange={e => this.toggleRegularity(e)}
                          />
                          <label
                            class='form-check-label ml-4'
                            htmlFor='regular'>
                            Regular
                          </label>
                        </div>
                        <div class='form-check'>
                          <input
                            class='form-check-input'
                            type='radio'
                            name='treatmentRegularity'
                            checked={
                              this.state.data.treatment_regularity ===
                              'irregular'
                            }
                            id='irregular'
                            onChange={e => this.toggleRegularity(e)}
                          />
                          <label
                            class='form-check-label ml-4'
                            htmlFor='irregular'>
                            Irregular
                          </label>
                        </div>
                        <div class='form-check'>
                          <input
                            class='form-check-input'
                            type='radio'
                            name='treatmentRegularity'
                            checked={
                              this.state.data.treatment_regularity === 'stopped'
                            }
                            id='stopped'
                            onChange={e => this.toggleRegularity(e)}
                          />
                          <label
                            class='form-check-label ml-4'
                            htmlFor='stopped'>
                            Stopped/Discontinued
                          </label>
                        </div>
                      </div>
                      {this.state.errors.treatment_regularity && (
                        <div className='form-group custom-input'>
                          <label className='error'>
                            {this.state.errors.treatment_regularity}
                          </label>
                        </div>
                      )}
                    </div>
                    <div
                      class={`${
                        !this.props.drawer ? 'dc-block' : 'dc-block pl-0'
                      }`}>
                      <div class='dc-block-head'>
                        <h6>Improvement Observed</h6>
                      </div>
                      <div class='dc-block-body d-md-flex align-items-center'>
                        <div class='form-check'>
                          <input
                            class='form-check-input'
                            type='radio'
                            name='improvement'
                            id='mild'
                            checked={
                              this.state.data.improvement_observed === 'mild'
                            }
                            onChange={e => this.toggleImprovement(e)}
                          />
                          <label class='form-check-label ml-4' htmlFor='mild'>
                            Mild
                          </label>
                        </div>
                        <div class='form-check'>
                          <input
                            class='form-check-input'
                            type='radio'
                            name='improvement'
                            id='moderate'
                            checked={
                              this.state.data.improvement_observed ===
                              'moderate'
                            }
                            onChange={e => this.toggleImprovement(e)}
                          />
                          <label
                            class='form-check-label ml-4'
                            htmlFor='moderate'>
                            Moderate
                          </label>
                        </div>
                        <div class='form-check'>
                          <input
                            class='form-check-input'
                            type='radio'
                            name='improvement'
                            id='noImprovement'
                            checked={
                              this.state.data.improvement_observed ===
                              'noImprovement'
                            }
                            onChange={e => this.toggleImprovement(e)}
                          />
                          <label
                            class='form-check-label ml-4'
                            htmlFor='noImprovement'>
                            No Improvement
                          </label>
                        </div>
                        <div class='form-check'>
                          <input
                            class='form-check-input'
                            type='radio'
                            name='improvement'
                            id='contSay'
                            checked={
                              this.state.data.improvement_observed === 'contSay'
                            }
                            onChange={e => this.toggleImprovement(e)}
                          />
                          <label
                            class='form-check-label ml-4'
                            htmlFor='contSay'>
                            Can’t say
                          </label>
                        </div>
                      </div>
                      {this.state.errors.improvement_observed && (
                        <div className='form-group custom-input'>
                          <label className='error'>
                            {this.state.errors.improvement_observed}
                          </label>
                        </div>
                      )}
                    </div>
                    <div
                      class={`${
                        !this.props.drawer ? 'dc-block' : 'dc-block pl-0'
                      }`}>
                      <div class='dc-block-head'>
                        <h6>Upload Recent Prescription/Reports</h6>
                      </div>
                      <div class='row'>
                        <div class='dc-block-body mr-5 ml-3'>
                          <div class='file-upload add-file-upload'>
                            <div class='image-upload-wrap'>
                              <input
                                class='file-upload-input'
                                type='file'
                                onChange={this.fileUpload}
                                accept='image/*'
                              />
                              <div class='drag-text'>
                                <h3>Upload Your Docs</h3>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class='dc-block-body'>
                          <div
                            class='file-upload add-file-upload'
                            style={{ background: '#FFF' }}>
                            <div>
                              {this.state.data.recent_prescription && (
                                <img
                                  src={
                                    process.env.REACT_APP_S3URL +
                                    this.state.data.recent_prescription
                                  }
                                  alt=''
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div class='block-seprator'></div>

                <div
                  class={`${
                    !this.props.drawer ? 'dc-block' : 'dc-block pl-0'
                  }`}>
                  <div class='dc-block-head'>
                    <h6>Do you have any Physical Illnesses (Past/Current)?</h6>
                  </div>
                  <div class='dc-block-body'>
                    <div class='row'>
                      {illness1.map(e => (
                        <div class='col-md-6 mb-3'>
                          <div class='form-check form-check-inline'>
                            <input
                              class='form-check-input'
                              type='checkbox'
                              checked={this.state.physical_illnesses.find(
                                el => el === e.Illness
                              )}
                              id={e.Illness}
                              onChange={() => {
                                if (e.Illness === 'None') {
                                  let physical_illnesses = ['None']
                                  this.setState({ physical_illnesses })
                                } else {
                                  let physical_illnesses = [
                                    ...this.state.physical_illnesses,
                                  ]
                                  this.setState(
                                    {
                                      physical_illnesses:
                                        physical_illnesses.filter(
                                          efs => efs !== 'None'
                                        ),
                                    },
                                    () => this.changeLang(e.Illness)
                                  )
                                }
                              }}
                            />
                            <label
                              class='form-check-label ml-4'
                              htmlFor={e.Illness}>
                              {e.Illness}
                            </label>
                          </div>
                        </div>
                      ))}
                      {illness2.map(e => (
                        <div class='col-md-6 mb-3'>
                          <div class='form-check form-check-inline'>
                            <input
                              class='form-check-input'
                              type='checkbox'
                              checked={this.state.physical_illnesses.find(
                                el => el === e.Illness
                              )}
                              id={e.Illness}
                              onChange={() => {
                                let physical_illnesses = [
                                  ...this.state.physical_illnesses,
                                ]
                                this.setState(
                                  {
                                    physical_illnesses:
                                      physical_illnesses.filter(
                                        efs => efs !== 'None'
                                      ),
                                  },
                                  () => this.changeLang(e.Illness)
                                )
                              }}
                            />
                            <label
                              class='form-check-label ml-4'
                              htmlFor={e.Illness}>
                              {e.Illness}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div class='row'>
                      <div class='col-md-6 ml-auto'>
                        {this.state.physical_illnesses.find(
                          el => el === 'Others'
                        )
                          ? this.renderInput(
                              'physical_illnesses_other',
                              'Start Typing'
                            )
                          : ''}
                      </div>
                    </div>
                  </div>
                </div>

                {!this.state.physical_illnesses.find(
                  efss => efss === 'None'
                ) && (
                  <>
                    <div
                      class={`${
                        !this.props.drawer ? 'dc-block' : 'dc-block pl-0'
                      }`}>
                      <div class='dc-block-head'>
                        <h6>Are you getting treated for above diagnosis?</h6>
                      </div>
                      <div class='dc-block-body d-md-flex align-items-center'>
                        <div class='form-check'>
                          <input
                            class='form-check-input'
                            type='radio'
                            name='diagnosis'
                            id='yes'
                            checked={
                              this.state.data.physical_illnesses_diagnosis ===
                              'yes'
                            }
                            onChange={e => this.toggleIllness(e)}
                          />
                          <label class='form-check-label ml-4' htmlFor='yes'>
                            Yes
                          </label>
                        </div>
                        <div class='form-check'>
                          <input
                            class='form-check-input'
                            type='radio'
                            name='diagnosis'
                            id='no'
                            checked={
                              this.state.data.physical_illnesses_diagnosis ===
                              'no'
                            }
                            onChange={e => this.toggleIllness(e)}
                          />
                          <label class='form-check-label ml-4' htmlFor='no'>
                            No
                          </label>
                        </div>
                      </div>

                      {this.state.errors.physical_illnesses_diagnosis && (
                        <div className='form-group custom-input'>
                          <label className='error'>
                            {this.state.errors.physical_illnesses_diagnosis}
                          </label>
                        </div>
                      )}
                    </div>
                    <div
                      class={`${
                        !this.props.drawer ? 'dc-block' : 'dc-block pl-0'
                      }`}>
                      <div class='dc-block-body'>
                        {this.renderTextarea(
                          'detail',
                          'Details you would like to mention'
                        )}
                      </div>
                    </div>
                  </>
                )}

                <div
                  class={`${
                    !this.props.drawer ? 'dc-block' : 'dc-block pl-0'
                  }`}>
                  <div class='dc-block-head'>
                    <h6>If any surgeries in past</h6>
                  </div>
                  <div class='dc-block-body d-md-flex align-items-center'>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='radio'
                        onChange={e => this.toggleSurgery(e)}
                        name='surgeries'
                        id='yes1'
                        checked={this.state.surgery1 === 'yes1'}
                      />
                      <label class='form-check-label ml-4' htmlFor='yes1'>
                        Yes
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='radio'
                        onChange={e => this.toggleSurgery(e)}
                        name='surgeries'
                        id='no1'
                        checked={this.state.surgery1 === 'no1'}
                      />
                      <label class='form-check-label ml-4' htmlFor='no1'>
                        No
                      </label>
                    </div>
                  </div>
                </div>
                {this.state.surgery1 === 'yes1' && (
                  <div
                    class={`${
                      !this.props.drawer ? 'dc-block' : 'dc-block pl-0'
                    }`}>
                    <div class='dc-block-head'>
                      <h6>If yes then mention</h6>
                    </div>
                    <div class='dc-block-body'>
                      {this.renderTextarea('surgery')}
                    </div>
                  </div>
                )}
                <div class='block-seprator'></div>

                <div
                  class={`${
                    !this.props.drawer ? 'dc-block' : 'dc-block pl-0'
                  }`}>
                  <div class='dc-block-head'>
                    <h6>Choose your most important concern (pick one)</h6>
                  </div>
                  <div class='dc-block-body d-md-flex align-items-center'>
                    {this.state.value.map((e1, i) => (
                      <div class='form-check-box'>
                        <div class='form-check'>
                          <input
                            class='form-check-input'
                            type='radio'
                            name='importantconcern'
                            id={e1.value}
                            checked={
                              this.state.data.most_important_concern ===
                              e1.value
                            }
                            onChange={e => this.toggleConcern(e)}
                          />
                          <label
                            class='form-check-label ml-4'
                            htmlFor={e1.value}>
                            {e1.label}
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div class='block-seprator'></div>
                <div
                  class={`${
                    !this.props.drawer ? 'dc-block' : 'dc-block pl-0'
                  }`}>
                  <div class='dc-block-head'>
                    <h6>
                      Any stress or life event after which problems started or
                      became worse?
                    </h6>
                  </div>
                  <div class='dc-block-body'>
                    {this.renderTextarea(
                      'problem_after_stress_or_life_event',
                      'Describe in brief...'
                    )}
                  </div>
                </div>
                <div class='block-seprator'></div>

                <div
                  class={`${
                    !this.props.drawer ? 'dc-block' : 'dc-block pl-0'
                  }`}>
                  <div class='dc-block-head'>
                    <h6>
                      Your expectations/thoughts at start from YesMindy’s
                      Experts
                    </h6>
                  </div>
                  <div class='dc-block-body'>
                    {this.renderTextarea(
                      'expectations_from_yesmindy',
                      'Describe in brief...'
                    )}
                  </div>
                </div>
                <div class='block-seprator'></div>

                <div
                  class={`${
                    !this.props.drawer ? 'dc-block' : 'dc-block pl-0'
                  }`}>
                  <div class='dc-block-head'>
                    <h6>You were referred by</h6>
                  </div>
                  <div class='dc-block-body d-md-flex align-items-center'>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='referredby'
                        id='self'
                        onChange={e => this.toggleRefer(e)}
                        checked={this.state.data.referred_by === 'self'}
                      />
                      <label class='form-check-label ml-4' htmlFor='self'>
                        Self
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='referredby'
                        id='otherClient'
                        onChange={e => this.toggleRefer(e)}
                        checked={this.state.data.referred_by === 'otherClient'}
                      />
                      <label class='form-check-label ml-4' for='otherClient'>
                        Other Client
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='referredby'
                        id='psychologist'
                        onChange={e => this.toggleRefer(e)}
                        checked={this.state.data.referred_by === 'psychologist'}
                      />
                      <label
                        class='form-check-label ml-4'
                        htmlFor='psychologist'>
                        Psychologist
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='referredby'
                        id='psychiatrist'
                        onChange={e => this.toggleRefer(e)}
                        checked={this.state.data.referred_by === 'psychiatrist'}
                      />
                      <label
                        class='form-check-label ml-4'
                        htmlFor='psychiatrist'>
                        Psychiatrist
                      </label>
                    </div>
                    <div class='form-check'>
                      <input
                        class='form-check-input'
                        type='radio'
                        name='referredby'
                        id='otherDoctor'
                        onChange={e => this.toggleRefer(e)}
                        checked={this.state.data.referred_by === 'otherDoctor'}
                      />
                      <label
                        class='form-check-label ml-4'
                        htmlFor='otherDoctor'>
                        Other Doctor
                      </label>
                    </div>
                  </div>
                  {this.state.errors.referred_by && (
                    <div className='form-group custom-input'>
                      <label className='error'>
                        {this.state.errors.referred_by}
                      </label>
                    </div>
                  )}
                </div>
                <div class='block-seprator'></div>

                <div
                  class={`${
                    !this.props.drawer ? 'dc-block' : 'dc-block pl-0'
                  }`}>
                  <div class='dc-block-head'>
                    <h6>
                      <strong>
                        By clicking SUBMIT you agree that the information
                        provided by me is correct and I agree to share the same
                        with my mental health care provider.
                      </strong>
                    </h6>
                  </div>
                  <div class='dc-block-body'>
                    <p>
                      Disclaimer - The information provided by you is
                      confidential and will only be shared with mental health
                      care professionals. YesMindy or its partner services will
                      have no access to it.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {window.location.pathname ===
            '/patient/dashboard/profile-information' ? (
              <div class='dc-right d-none'>
                {this.props.patientProfile &&
                  this.props.patientProfile.created_on && (
                    <h3>
                      <span>Edited on </span>
                      {Moment(this.props.patientProfile.created_on).format(
                        'DD-MM-YYYY'
                      )}
                    </h3>
                  )}
              </div>
            ) : !this.props.drawer ? (
              <div class='dc-right'>
                <div class='dc-right-content'>
                  <div class='dc-img-box'>
                    <img src={rocket} alt='' />
                  </div>
                  <h1>
                    Welcome to <span>Yes</span>Mindy
                  </h1>
                  <p>
                    Sharing your history once with experts will improve your
                    overall experience and quality of care. Kindly fill
                    carefully as once filled certain fields cannot be edited.
                  </p>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </form>

        {window.location.pathname ===
        '/patient/dashboard/profile-information' ? (
          <div className='dc-bottom-box'>
            <SubmitBtn
              label='SUBMIT'
              form='my-form'
              loading={this.state.loading}
              btnClass='btn btn-default no-shadow'
            />
          </div>
        ) : (
          <div className='dc-footer d-flex align-items-center justify-content-between'>
            <button
              className='btn btn-grey'
              onClick={e => {
                if (this.props.drawer) {
                  this.props.toggleDrawer()
                } else {
                  this.props.updateStep(e, 3)
                }
              }}>
              Go Back
            </button>
            {!readable && (
              <button
                type='submit'
                className='btn btn-default no-shadow'
                form='my-form'>
                SUBMIT
                {this.state.loading && (
                  <b className='btn-loader'>
                    <img
                      src={loaderIcon}
                      alt='loader'
                      className='loader-img fa-spin'
                      style={{ height: '15px;' }}
                    />
                  </b>
                )}
              </button>
            )}
            {/* <SubmitBtn
              label="SUBMIT"
              form="my-form"
              loading={this.state.loading}
              btnClass="btn btn-default no-shadow"
            /> */}
          </div>
        )}
      </div>
    )
  }
}
const mapStateToProps = state => ({
  patientProfile: getPatient(state).history,
  loading: getPatient(state).loading,
})
const mapDispatchToProps = dispatch => ({
  updatePatientHistory: (param, callback) =>
    dispatch(updatePatientHistory(param, callback)),
  loadPatientProfile: () => dispatch(loadPatientProfile()),
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PatientHistory)
)
