import _ from 'lodash'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import {
  getAssessment,
  loadDetailAssessment,
  loadAnswerAssessment,
  loadSubmittedAssessment,
} from '../../../../store/assessment'
import { getPatient } from '../../../../store/patient'
import close from '../../../../include/images/icon-close.svg'
import material from '../../../../include/images/material-note.svg'
import moment from 'moment'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { Tooltip as ReactTooltip } from 'react-tooltip'

class Task extends Component {
  customStyles = {
    indicatorSeparator: (styles) => ({ display: 'none' }),
    option: (provided, state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#621ac0',
      },
      color: state.isSelected ? '#621ac0' : 'black',
      backgroundColor: state.isSelected
        ? 'rgb(95, 50, 187, 10%)'
        : provided.backgroundColor,
    }),
    control: (base, state) => ({
      ...base,
      height: 55,
      minHeight: 55,
      borderColor: state.isFocused ? '#6119c0' : '#e0e0e0',
      boxShadow: '0 !important',
      '&:hover': {
        borderColor: '#6119c0',
      },
      '&:focus': {
        borderColor: '#6119c0',
      },
    }),
    multiValueRemove: (base, state) => ({
      ...base,
      '&:hover': {
        backgroundColor: 'rgb(95, 50, 187, 10%)',
        color: '#6119c0',
      },
    }),
  }
  state = {
    category: '',
    isShow: false,
    id: '',
    doctor_id: '',
    title: '',
    data: {},
  }
  toggle = (id, title, doctor_id) => {
    this.setState({
      isShow: !this.state.isShow,
      id: id,
      doctor_id,
      title: title,
    })
  }
  toggleClose = () => {
    this.setState({
      isShow: false,
      title: '',
      id: '',
      doctor_id: '',
    })
  }
  componentDidMount = () => {
    this.props.loadSubmittedAssessment()
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.category != prevState.category) {
      this.props.loadSubmittedAssessment({
        category: this.state.category,
      })
    }
    if (this.state.id != '' && this.state.id !== prevState.id) {
      this.props.loadDetailAssessment(this.state.id)
      this.props.loadAnswerAssessment({
        doctor_id: this.state.doctor_id,
        assesment_id: this.state.id,
      })
    }
    if (
      this.props.answer.data !== null &&
      this.props.answer.data !== prevProps.answer.data
    ) {
      this.setState({
        data: this.props.answer.data && this.props.answer.data.formData,
      })
    }
  }
  render() {
    const { detail, assessment, answer } = this.props
    const category = (item) => item.assesment_id.category.name
    const result = _.groupBy(assessment, category)
    const groupArrays = Object.keys(result).map((category) => {
      return {
        category,
        assess: result[category],
      }
    })

    const ques =
      detail.questions &&
      detail.questions.map((e) =>
        _.chain(e.questions)
          .groupBy('section.name')
          .map((value, key) => ({
            section: key,
            question_group: _.chain(value)
              .groupBy('question_group.name')
              .map((value, key) => ({ question_group: key, question: value }))
              .value(),
          }))
          .value(),
      )
    return (
      <>
        {/* <div
          class="tab-pane card fade show active"
          id="myreport"
          role="tabpanel"
          aria-labelledby="myreport-tab"
        >
          <div class="card-header">My Task</div>
          <div class="content-body show"> */}
        <ReactTooltip className='testPurple' id='task-tooltip'/>
        <div className="cb-outer">
          <div className="filter-content-box d-md-flex flex-wrap align-items-center">
            <div className="filter-text-box">
              <h6>Filters:</h6>
            </div>
            <div className="filter-check-box">
              <ul className="filter-check-list">
                <li className="filter-check-item">
                  <div className="form-group preferred-field">
                    <div className="custom-radio-group d-md-flex flex-wrap align-items-center justify-content-center mx-0">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="Assessments"
                          name="Assessment"
                          onClick={() => {
                            this.setState({ category: 'Assessments' })
                          }}
                          checked={this.state.category === 'Assessments'}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="Assessments"
                        >
                          Assessments
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="Worksheets"
                          name="Worksheet"
                          onClick={() => {
                            this.setState({ category: 'Worksheets' })
                          }}
                          checked={this.state.category === 'Worksheets'}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="Worksheets"
                        >
                          Worksheets
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="Homeworks"
                          name="Homework"
                          onClick={() => {
                            this.setState({ category: 'Homeworks' })
                          }}
                          checked={this.state.category === 'Homeworks'}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="Homeworks"
                        >
                          Homeworks
                        </label>
                      </div>
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          className="custom-control-input"
                          id="All"
                          name="All"
                          onClick={() => {
                            this.setState({ category: '' })
                          }}
                          checked={this.state.category === ''}
                        />
                        <label className="custom-control-label" htmlFor="All">
                          All
                        </label>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="tab-pane-content-box tpc-report">
          <div class="tpc-lower">
            {groupArrays.length === 0 ? (
              <div className="document-content-box ">No record found.</div>
            ) : (
              <>
                {groupArrays.map((e2, i) => (
                  <>
                    {e2.category === 'Assessments' ||
                    e2.category === 'Homeworks' ||
                    e2.category === 'Worksheets' ? (
                      <div className="document-content-box ">
                        <div className="tpc-month-box mb-5 mt-5">
                          <h6>{e2.category}</h6>
                        </div>
                        <ul class="new-profile-list">
                          {e2.assess.map((e1, i) => (
                            <>
                              {e1.doctor_id && (
                                <li class="np-item" key={i}>
                                  <div class="np-box d-lg-flex align-items-center justify-content-between">
                                    <div class="np-left d-lg-flex flex-wrap align-items-center">
                                      <div class="np-text-box">
                                        <p className="task">Title</p>
                                        <h5>{e1.assesment_id.title}</h5>
                                      </div>
                                    </div>
                                    {!this.props.videoCall && (
                                      <>
                                        <div class="np-mid">
                                          <p className="task">Alloted By</p>
                                          <p>{e1.doctor_id.name}</p>
                                        </div>

                                        <div class="np-mid">
                                          <p className="task">
                                            Date of Allotment
                                          </p>
                                          <p>
                                            {moment(e1.created_at).format('ll')}
                                          </p>
                                        </div>

                                        <div class="np-mid">
                                          <p className="task">
                                            Date of Submission
                                          </p>
                                          <p>
                                            {moment(e1.updated_at).format('ll')}
                                          </p>
                                        </div>
                                      </>
                                    )}
                                    <div class="np-right d-flex flex-wrap np-task">
                                      <a
                                        href="#"
                                        class="np-links"
                                        data-toggle="notes"
                                        data-tooltip-id='task-tooltip'
                                        data-tooltip-content="Notes & Thoughts"
                                        aria-hidden="true"
                                        onClick={() =>
                                          this.toggle(
                                            e1.assesment_id._id,
                                            e1.assesment_id.title,
                                            e1.doctor_id._id,
                                          )
                                        }
                                      >
                                        <img
                                          style={{
                                            maxWidth: 'fit-content',
                                          }}
                                          src={material}
                                          alt=""
                                        />
                                      </a>
                                    </div>
                                  </div>
                                </li>
                              )}
                            </>
                          ))}
                        </ul>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                ))}
              </>
            )}
          </div>
        </div>
        {/* </div>
        </div> */}
        <div
          className={
            this.state.isShow === true
              ? 'sidebar-box preSession opennote'
              : 'sidebar-box preSession closenote'
          }
          id="pre"
        >
          <div className="sidebar-head d-flex align-items-center">
            {this.state.isShow === true && <h5>{this.state.title}</h5>}
            {this.state.isShow === true ? (
              <div className="close-img ml-auto" onClick={this.toggleClose}>
                <img src={close} alt="" />
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="sidebar-body">
            {this.state.id &&
              ques &&
              ques.map((e, i) => (
                <>
                  {e.map((e1, i1) => (
                    <div
                      className="check-box-group"
                      style={{ marginTop: '10px' }}
                      key={i1}
                    >
                      {e1.section !== 'undefined' && (
                        <label class="section">{e1.section}</label>
                      )}
                      {e1.question_group.map((e2) => (
                        <>
                          <div class="d-flex flex-column">
                            <label class="question_group">
                              {e2.question_group !== 'undefined' &&
                                e2.question_group}
                            </label>
                          </div>
                          {e2.question &&
                            e2.question.map((e3) => (
                              <>
                                <>
                                  <label>{e3.question}</label>
                                  {e3.response_type ===
                                    'free_text_pill_box' && (
                                    <CreatableSelect
                                      noOptionsMessage={() =>
                                        'Type and press enter to add options'
                                      }
                                      isMulti
                                      classNamePrefix="select"
                                      isDisabled={false}
                                      isLoading={false}
                                      name="color"
                                      styles={this.customStyles}
                                      value={
                                        this.state.data &&
                                        this.state.data[e3.question]
                                      }
                                      // onChange={(e) => {
                                      //   console.log("eeeee", e);
                                      //   const data = { ...this.state.data };
                                      //   data[e3.question] = e;
                                      //   this.setState({ data });
                                      //   console.log(e);
                                      // }}
                                    />
                                  )}
                                  {e3.response_type ===
                                    'multiselect_dropdown' && (
                                    <Select
                                      isMulti
                                      className="basic-single"
                                      classNamePrefix="select"
                                      isDisabled={false}
                                      isLoading={false}
                                      isClearable={false}
                                      isRtl={false}
                                      isSearchable={false}
                                      name="color"
                                      styles={this.customStyles}
                                      options={e3.options.map((ex) => ({
                                        label: ex.title,
                                        value: ex.title,
                                      }))}
                                      value={
                                        this.state.data &&
                                        this.state.data[e3.question]
                                      }
                                    />
                                  )}
                                  {e3.response_type === 'dropdown' && (
                                    <Select
                                      className="basic-single"
                                      classNamePrefix="select"
                                      isDisabled={false}
                                      isLoading={false}
                                      isClearable={false}
                                      isRtl={false}
                                      isSearchable={false}
                                      name="color"
                                      styles={this.customStyles}
                                      options={e3.options.map((ex) => ({
                                        label: ex.title,
                                        value: ex.title,
                                      }))}
                                      value={
                                        this.state.data &&
                                        this.state.data[e3.question]
                                      }
                                    />
                                  )}
                                  {e3.response_type === 'text' && (
                                    <input
                                      type="text"
                                      name=""
                                      className="form-control"
                                      placeholder="Write here…"
                                      value={
                                        this.state.data &&
                                        this.state.data[e3.question]
                                      }
                                    />
                                  )}
                                  {e3.response_type === 'text_area' && (
                                    <textarea
                                      style={{ padding: '10px' }}
                                      type="text"
                                      className="form-control"
                                      placeholder=""
                                      value={
                                        this.state.data &&
                                        this.state.data[e3.question]
                                      }
                                    ></textarea>
                                  )}

                                  <div className="radiobox-group d-flex flex-wrap align-items-center">
                                    {e3.response_type === 'checkbox' &&
                                      e3.options.map((e4, i2) => (
                                        <div className="form-check" key={i2}>
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value={e4.title}
                                            id={e3.question + e4.title}
                                            name={e4.title + e4._id}
                                            checked={
                                              this.state.data &&
                                              this.state.data[e3.question] &&
                                              this.state.data[e3.question].find(
                                                (echeck) => echeck === e4.title,
                                              )
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={e3.question + e4.title}
                                          >
                                            <span
                                              style={{
                                                marginLeft: '0.5rem',
                                              }}
                                            ></span>
                                            {e4.title}
                                          </label>
                                        </div>
                                      ))}
                                  </div>
                                  <div className="radiobox-group d-flex flex-wrap align-items-center">
                                    {e3.response_type === 'radio' &&
                                      e3.options.map((e4, i2) => (
                                        <div
                                          className="form-check"
                                          key={e4.title}
                                        >
                                          <input
                                            className="form-check-input"
                                            type="radio"
                                            value={e4.title}
                                            id={e3.question + e4.title}
                                            name={e3.question + e4.title}
                                            checked={
                                              this.state.data[e3.question] ==
                                              e4.title
                                            }
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={e3.question + e4.title}
                                          >
                                            <span
                                              style={{
                                                marginLeft: '2rem',
                                              }}
                                            ></span>
                                            {e4.title}
                                          </label>
                                        </div>
                                      ))}
                                  </div>

                                  <div className="radiobox-group d-flex flex-wrap align-items-center">
                                    {e3.response_type === 'doc' &&
                                      e3.options.length > 0 && (
                                        <>
                                          {e3.options[0].title
                                            .split('.')
                                            .pop() === 'pdf' ||
                                          e3.options[0].title
                                            .split('.')
                                            .pop() === 'PDF' ? (
                                            <>
                                              <embed
                                                src={
                                                  process.env.REACT_APP_S3URL +
                                                  e3.options[0].title
                                                }
                                              />
                                            </>
                                          ) : (
                                            <img
                                              src={
                                                process.env.REACT_APP_S3URL +
                                                e3.options[0].title
                                              }
                                              alt=""
                                            />
                                          )}
                                        </>
                                      )}
                                  </div>
                                  <br />
                                </>
                              </>
                            ))}
                        </>
                      ))}
                    </div>
                  ))}
                </>
              ))}
            {detail.data && detail.data.footer_text && (
              <small>{detail.data.footer_text}</small>
            )}
            <div class="score-parent">
              <div className="score-child">
                <div class="mt-3" style={{ fontSize: '14px' }}>
                  {' '}
                  {answer && answer.data && answer.data.scoreNumber > 0 && (
                    <>
                      <label style={{ color: '#621ac0', fontWeight: '500' }}>
                        Total Score -{' '}
                      </label>{' '}
                      <label>{answer.data.scoreNumber}</label>
                    </>
                  )}
                </div>
                <div style={{ fontSize: '14px' }}>
                  {' '}
                  {answer && answer.data && answer.data.scoreValue && (
                    <>
                      <label style={{ color: '#621ac0', fontWeight: '500' }}>
                        Severity -{' '}
                      </label>{' '}
                      <label>{answer.data.scoreValue.severity}</label>
                    </>
                  )}
                </div>
                <div style={{ fontSize: '14px' }}>
                  {' '}
                  {answer && answer.data && answer.data.scoreValue && (
                    <>
                      <label style={{ color: '#621ac0', fontWeight: '500' }}>
                        Recommendation -{' '}
                      </label>{' '}
                      <label>{answer.data.scoreValue.recommendation}</label>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={this.state.isShow === true ? 'overlay4 show' : 'overlay4'}
        ></div>
      </>
    )
  }
}
const mapStateToProps = (state) => ({
  detail: getAssessment(state).detail,
  assessment: getAssessment(state).submitted,
  answer: getAssessment(state).answer,
  psychiatrist: getPatient(state).psychiatrist,
  psychiatristAppointment: getPatient(state).psychiatristAppointment,
  psychologist: getPatient(state).psychologist,
  psychologistAppointment: getPatient(state).psychologistAppointment,
  loading: getAssessment(state).answerLoading,
})
const mapDispatchToProps = (dispatch) => ({
  loadDetailAssessment: (id) => dispatch(loadDetailAssessment(id)),
  loadSubmittedAssessment: (params) =>
    dispatch(loadSubmittedAssessment(params)),
  loadAnswerAssessment: (params) => dispatch(loadAnswerAssessment(params)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Task)
