import React from "react";
import closeBtn from "../../include/images/close.svg";
const AlertClose = ({closeToast}) => {
  return (
    <span className="alert-right">
      <a href="#" className="alert-close" data-dismiss="alert" onClick={closeToast}>
        <img src={closeBtn} alt="" />
      </a>
    </span>
  );
};
export default AlertClose;