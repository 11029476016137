import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Header from "../home/header";
import Footer from "../home/footer";
import {
  getMisc,
  loadConstant,
} from "../../store/misc";

import banner from "../../include/images/inner-banner1.png";
import conntect from "../../include/images/lets-conntect-img.png";
import pannel from "../../include/images/pannel-img1.png";
import icons1 from "../../include/images/kn-icon.svg";

class AboutUs extends Component {
  state = {
    patient: localStorage.getItem("patient")
      ? JSON.parse(localStorage.getItem("patient"))
      : false,
    tabPane: false,
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.props.loadConstant();
  };
  tabPaneToggle = (e) => {
    e.preventDefault();
    this.setState({ tabPane: !this.state.tabPane });
  };
  render() {
    const { constants } =
      this.props.getMisc;
    const {
      helpline_no,
      header_email_address,
      footer_email_address,
      facebook,
      instagram,
      twitter,
    } = constants;
    return (
      <>
        <Header
          helpline_no={helpline_no}
          header_email_address={header_email_address}
          patient={this.state.patient}
        />

        <div class="outer-container">
          <div class="inner-hero-container overflow-hidden">
            <div class="container container1">
              <div class="row">
                <div class="col-md-12">
                  <div class="inner-hero-content-box d-flex flex-wrap">
                    <div class="inner-hero-bg add-new-shape">
                      <img src={banner} alt="" />
                    </div>
                    <div class="inner-hero-content-left d-flex align-items-center justify-content-lg-end">
                      <h1>EMPO</h1>
                      <span>WERING</span>
                    </div>
                    <div class="inner-hero-content-right">
                      <div class="inner-hero-content">
                        <p>
                          Let’s give people and professionals the ‘license’ to a
                          holistically improved Mental Health ecosystem
                        </p>
                        <Link to={"/services"} class="btn banner-btn">
                          Find Your Solutions
                        </Link>
                      </div>
                    </div>

                    <div class="inner-hero-img d-block d-lg-none text-center">
                      <img src={banner} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class={
              this.state.tabPane === true
                ? "content-container  info-block-container add-box-shadow"
                : "content-container  info-block-container"
            }
          >
            <div class="container container1">
              <div class="row flex-lg-row-reverse">
                <div class="col-lg-6 ">
                  <div class="intro-heading-block d-flex align-items-center">
                    <div class="intro-block-img">
                      <img src={conntect} alt="" />
                    </div>
                    <div class="intro-block-heading">
                      <h4>
                        Healing isn’t solo <span>Lets Connect</span>
                      </h4>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 ">
                  <div class="intro-block">
                    <h4> Product</h4>
                    <div class="heading4">
                      <h5>
                        Story <span> Meet Mindy</span>{" "}
                      </h5>
                    </div>
                    <p>
                      Meet Mindy, the perfect Mental Health partner to find ‘the
                      light of day’ in a world filled with darkness. We might
                      all know that 1 out of 7 people suffer from such issues,
                      yet don’t know how to deal with them. Let’s look at a
                      positive change by connecting with professionals.
                    </p>{" "}
                    <a
                      class="know-more-link"
                      aria-selected="true"
                      href="#know"
                      onClick={(e) => this.tabPaneToggle(e)}
                    >
                      {" "}
                      Know more <img src={icons1} alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class={
              this.state.tabPane === true
                ? "info-block-pannel open-pannel"
                : "info-block-pannel"
            }
            style={{ display: "none" }}
          >
            <div class="ib-head">
              <div class="container container1">
                <div class="row">
                  <div class="col-lg-12  text-center">
                    <div class="d-flex align-items-center justify-content-center">
                      {" "}
                      <h5>Product Story </h5>
                      <span> Introduction</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ib-body ">
              <div class="container container1">
                <div class="row">
                  <div class="col-lg-4">
                    <p>
                      Today’s competitive lifestyle needs more creativity,
                      productivity, and learning. But then life becomes more of
                      expectancies and less of you. This pressure creates a
                      negative loop of mental health struggles.
                    </p>
                    <p>
                      <strong>No, you are not alone!</strong> Mental health
                      issues affect around 1 billion people worldwide. Studies
                      show that 1 out of 7 people around you today suffer from
                      mental health problems. Remember, this isn’t just a phase
                      but an illness that needs to be addressed and resolved.
                    </p>
                    <p>
                      How? Come, meet your new virtual friend,{" "}
                      <strong>Mindy!</strong>
                      Your holistic supporter, anywhere, and any time you need!
                      It’ll teach you the art of deep breathing to deal with all
                      the trying moments you face. Mindy loves saying,
                      <strong> “illness is not far behind wellness”</strong>.
                    </p>
                    <p>
                      <strong>
                        Don’t let the pressure break you. Let’s turn a positive
                        chapter
                      </strong>
                    </p>
                  </div>
                  <div class="col-lg-8">
                    <div class="pannel-body-content">
                      <div class="pb-img">
                        <img src={pannel} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="content-container ">
            <div class="container container1">
              <div class="row align-items-center">
                <div class="col-md-6">
                  <div class="intro-content">
                    <h4>
                      Humane <span>Quality-Driven</span> Holistic{" "}
                    </h4>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="blockquote-box">
                    <blockquote>
                      <h3>MISSION</h3>
                      <p>
                        Build a mental health ecosystem to make holistic
                        services accessible to all; that utilizes an innovative
                        virtual assistant platform to support patients & their
                        aligned Mental Health Professionals identify, assess,
                        deliver & track treatment throughout the journey of
                        wellness.
                      </p>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="content-container pb-0">
            <div class="container container1">
              <div class="row">
                <div class="col-lg-12">
                  <div class="heading d-flex align-items-center mb-0">
                    <h4>
                      Our <span>Founders</span>
                    </h4>
                    <span class="h-line"></span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-10 offset-lg-1">
                  <ul class="founder-list d-flex flex-wrap">
                    <li class="founder-item  ">
                      <div class="founder-box text-center d-flex flex-column align-items-center justify-content-center">
                        <div class="founder-img">
                          <img
                            src="https://d2z6xjd2pnztki.cloudfront.net/aboutImages/Nimish+.png"
                            alt=""
                          />
                        </div>
                        <h4>Dr. Nimish Gupta</h4>
                        <p>MD, Psychiatry</p>
                      </div>
                    </li>
                    <li class="founder-item">
                      <div class="founder-box text-center d-flex flex-column align-items-center justify-content-center">
                        <div class="founder-img">
                          <img
                            src="https://d2z6xjd2pnztki.cloudfront.net/aboutImages/Saras+Prasad.png"
                            alt=""
                          />
                        </div>
                        <h4>Dr. Saras Prasad</h4>
                        <p>MD, Psychiatry</p>
                      </div>
                    </li>
                    <li class="founder-item">
                      <div class="founder-box text-center d-flex flex-column align-items-center justify-content-center">
                        <div class="founder-img">
                          <img
                            src="https://d2z6xjd2pnztki.cloudfront.net/aboutImages/Shivam+(2).png"
                            alt=""
                          />
                        </div>
                        <h4>Dr. Shivam Dubey</h4>
                        <p>MD, Psychiatry</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container block-box-container ">
            <div class="container container1">
              <div class="row">
                <div class="col-lg-12">
                  <div class="block-box-bg">
                    <div class="block-box-content text-center">
                      <h3>Transforming The Journey</h3>
                      <p>
                        We on the behalf of the Mental Health fraternity are
                        helping the industry from every aspect. Our support goes
                        beyond holistic healing for patients – we believe in
                        helping our fellow professionals’ practices, breaking
                        the stigma by creating a safe community, and blending
                        technology with humanity to revolutionize the healing
                        journey.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container ">
            <div class="container container1">
              <div class="row">
                <div class="col-lg-12">
                  <div class="heading d-flex align-items-center mb-0">
                    <h4>
                      Founding <span>Partners</span>
                    </h4>
                    <span class="h-line"></span>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-10 offset-lg-1">
                  <ul class="founder-list d-flex flex-wrap">
                    <li class="founder-item">
                      <div class="founder-box text-center d-flex flex-column align-items-center justify-content-center">
                        <div class="founder-img">
                          <img
                            src="https://d2z6xjd2pnztki.cloudfront.net/aboutImages/Founding+Partner.png"
                            alt=""
                          />
                        </div>
                        <h4>Mr. Ankur Gupta</h4>
                        <p>Tech Founder</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer
          footer_email_address={footer_email_address}
          facebook={facebook}
          instagram={instagram}
          twitter={twitter}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  getMisc: getMisc(state),
});

const mapDispatchToProps = (dispatch) => ({
  loadConstant: () => dispatch(loadConstant()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
