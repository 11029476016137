import React, { Component } from 'react'
import info from './../../../include/images/info-icon.svg'
import { Tooltip as ReactTooltip } from 'react-tooltip'

class Nominee extends Component {
  state = {
    formclass: 'form-group custom-input d-flex align-items-center',
  }

  handleFocus = () => {
    this.setState({
      formclass: 'form-group custom-input show on d-flex align-items-center',
    })
  }

  handleBlur = e => {
    const classname = e.target.value
      ? 'form-group custom-input show on d-flex align-items-center'
      : 'form-group custom-input d-flex align-items-center'
    this.setState({ formclass: classname })
  }

  componentDidMount() {
    const classname = this.props.value
      ? 'form-group custom-input show on d-flex align-items-center'
      : 'form-group custom-input d-flex align-items-center'
    this.setState({ formclass: classname })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      const classname = this.props.value
        ? 'form-group custom-input show on d-flex align-items-center'
        : 'form-group custom-input d-flex align-items-center'
      this.setState({ formclass: classname })
    }
  }
  render() {
    const { name, label, tooltip, error, readOnly, ...rest } = this.props
    console.log(tooltip)
    return (
    <>
      <ReactTooltip className='testPurple' id="nominee"/>
      <div className={this.state.formclass}>
        <label htmlFor={name}>{label}</label>
        <input
          name={name}
          id={name}
          className={error ? 'form-control error' : 'form-control'}
          onFocus={this.handleFocus}
          onBlur={this.handleBlur}
          readOnly={readOnly}
          {...rest}
        />
        {this.props.showIcon && (
          <span class='ml-3'>
            <img src={info} alt='' data-tooltip-id='nominee' data-tooltip-content={tooltip} aria-hidden='true' />
          </span>
        )}
        {error && <label className='error'>{error}</label>}
      </div>
    </>
    )
  }
}

export default Nominee
