import React, { useEffect, useState } from 'react'
import videoslash from '../../../include/images/video-slash.svg'
import messageIcon from '../../../include/images/tab-message.svg'
import videosOn from '../../../include/images/Videoon.png'
import documentIcon from '../../../include/images/tab-document.svg'
import notesIcon from '../../../include/images/notes-icon.svg'
import taskIcon from '../../../include/images/tab-dashboard.svg'
import micOn from '../../../include/images/micon.png'
import Document from './../../patient/dashboard/postAppointment/activity/documents'
import phoneslash from '../../../include/images/phone-slash.svg'
import shareIcon from '../../../include/images/share.svg'
import shareClose from '../../../include/images/share_close.svg'
import iosExit from '../../../include/images/ios-exit.svg'
import fullScreenImg from '../../../include/images/fullscreen-icon.svg'
import Timer from '../../common/timer'
import moment from 'moment'
import {
  getAppointment,
  loadAppointmentDetailPatient,
} from '../../../store/appointment'
import { loadSeo, loadTermTemplate } from '../../../store/seo'
import { getDoctor, loadDoctorProfile } from '../../../store/doctor'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import DashboardHeader from '../profile/common/dashboardHeader'
import Messeges from './postAppointment/activity/messeges'
import Notes from './postAppointment/activity/notes'

import TermTemplatePopup from '../../common/termTemplatePopup'
//import Task from "./timeline/task";
import Task from './postAppointment/activity/task'

import AvtarImage from '../../common/avtarImage'
import { getPatient } from '../../../store/patient'
import LinesEllipsis from 'react-lines-ellipsis'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import LoadVideoCall from '../../video-call/loadVideoCall';
import LoadScreenShare from '../../video-call/loadScreenShare';

import '../../doctor/dashboard/style.css'

function VideoCallPatient(props) {
  const [popShow, setPopShow] = useState(false)
  const [data, setData] = useState({
    patient_id: {},
    doctor_id: { field_type: {} },
  })
  const [video, setVideo] = useState(true)
  const [audio, setAudio] = useState(true)
  const [fullScreen, setFullScreen] = useState(false)
  const [tab, setTab] = useState('notes')
  const [localMedia, setLocalMedia] = useState([])
  const [screenShare, setScreenShare] = useState(false);
  const [userToken, setUserToken] = useState({});
  const [client, setClient] = useState();
  const [loadContent, setLoadContent] = useState();
  
  const changeTab = (tab, e) => {
    e.preventDefault()
    setTab(tab)
  }
  const toggleFull = () => {
    setFullScreen(!fullScreen)
  }
  const togglePopup = () => {
    setPopShow(!popShow)
  }
  const openPopup = (e, key) => {
    if (e) e.preventDefault()
    props.loadTermTemplate(key)
    togglePopup()
  }

  const muteVideo = () => {
    if (localMedia.length > 1) {
      if (video) {
        localMedia[1].setEnabled(false)
      } else {
        localMedia[1].setEnabled(true)
      }
    }
    setVideo(!video)
  }

  const muteAudio = () => {
    if (localMedia.length > 1) {
      if (audio) {
        localMedia[0].setVolume(0)
        //localStream.muteAudio();
      } else {
        localMedia[0].setVolume(100)
        //localStream.unmuteAudio();
      }
    }
    setAudio(!audio)
  }
  
  const leave = async () => {
    if (localMedia.length > 1) {
      localMedia[0].close()
      localMedia[1].close()
    }
    window.close()
  }
  const handleScreenShareStart = async ()=>{
    if(!screenShare){
      await client.leave();
      props.loadAppointmentDetailPatient(
        props.match.params.appointment_id,
        res => {
          if (res && res.data) {
            setScreenShare(true);
            setUserToken({
              screenShare:true,
              token:res.data.tokenA,
              uid:res.data.uid
            });
          }
        }
      )
    }
  }
  const handleScreenShareEnd = async ()=>{
    if(screenShare){
      localMedia.map(l=>l.close())
      //localMedia[1].close()
      await client.leave();
      window.location.reload();
      props.loadAppointmentDetailPatient(
        props.match.params.appointment_id,
        res => {
          if (res && res.data) {
            setScreenShare(false);
            setUserToken({
              screenShare:false,
              token:res.data.tokenA,
              uid:res.data.uid
            });
          }
        }
      )
    }
  }
  useEffect(() => {
    openPopup(null, 'userConsentForm')
    props.loadAppointmentDetailPatient(
      props.match.params.appointment_id,
      res => {
        if (res && res.data) {
          setData(res.data.data)
          setUserToken({
            screenShare:false,
            token:res.data.tokenA,
            uid:res.data.uid
          })
        }
      }
    )
  }, []);
  const getVideoContent = ()=>{
    return (   
      <LoadVideoCall 
        setLocalMedia={setLocalMedia} 
        channel={data._id}
        userToken={userToken}
        setClient={setClient}
        screenShare={screenShare}
        />
      )
    }
  const getScreenShareContent = ()=>{
    return (      
        <LoadScreenShare 
          localMedia={localMedia} 
          setLocalMedia = {setLocalMedia}
          channel={data._id}
          userToken={userToken}
          shareScreen={handleScreenShareEnd}
          setClient={setClient}
          screenShare={screenShare}
        />
    )
  }
  useEffect(()=>{
    if(Object.keys(userToken).length > 0){
      if(userToken.screenShare) setLoadContent(getScreenShareContent())
      else setLoadContent(getVideoContent())
    }
  },[userToken])

  useEffect(() => {
    const classData = document.getElementsByClassName('mcb-body-fullscreen')
    const topDar = document.getElementsByClassName('dashboard-top-bar')
    if (classData.length > 0 && topDar && topDar.length > 0) {
      topDar[0].style.zIndex = -999
    } else if (topDar && topDar.length > 0) {
      topDar[0].style.zIndex = 999
    }
    let localVideoTrack = localMedia && localMedia.length > 0 && localMedia[1]
    if (localVideoTrack) {
      if (fullScreen) {
        let localPlayerContainer = document.getElementById('agora_local_full')
        localPlayerContainer.innerHTML = ''
        localVideoTrack.play(localPlayerContainer)
      } else {
        let localPlayerContainer = document.getElementById('agora_local')
        localPlayerContainer.innerHTML = ''
        localVideoTrack.play(localPlayerContainer)
      }
    }
  }, [fullScreen])

  return (
    <>
      {/* <Helmet>
        <script id='wati'>{WatiScript}</script>
      </Helmet> */}

      <DashboardHeader />

      <ReactTooltip id='my-tooltip' className='testPurple' />

      {data && data.doctor_id && data.doctor_id._id && (
        <div class='dashboard-main-container'>
          {/* {JSON.stringify(
              props.getPatient.lastFollowup &&
                props.getPatient.lastFollowup.formData &&
                props.getPatient.lastFollowup.formData[
                  "Current Complaints:"
                ]
            )} */}
          <div class='video-box d-xl-flex flex-wrap'>
            <div
              className={
                fullScreen
                  ? 'main-content-box main-content-box3'
                  : 'main-content-box '
              }>
              <div class='mcb-head'>
                <ReactTooltip
                  id='toolTip'
                  multiline={true}
                  className='testPurple'
                />
                <ul class='doc-intro-list d-md-flex flex-wrap'>
                  <li class='di-item'>
                    <div class='di-intro d-flex flex-wrap align-items-center'>
                      <div class='di-img has-active'>
                        {data.doctor_id.image_url ? (
                          <img
                            src={
                              process.env.REACT_APP_S3URL +
                              data.doctor_id.image_url
                            }
                            alt=''
                          />
                        ) : (
                          <AvtarImage />
                        )}
                      </div>
                      <div class='di-text-box'>
                        <h6>{data && data.doctor_id && data.doctor_id.name}</h6>
                        <p>
                          {' '}
                          {data &&
                            data.doctor_id &&
                            data.doctor_id.field_type &&
                            data.doctor_id.field_type.name}
                          {data &&
                            data.doctor_id &&
                            data.doctor_id.graduation &&
                            ', ' + data.doctor_id.graduation.education}
                          {data &&
                            data.doctor_id &&
                            data.doctor_id.post_graduation &&
                            ', ' + data.doctor_id.post_graduation.education}
                          {data &&
                            data.doctor_id &&
                            data.doctor_id.super_speciality &&
                            ', ' + data.doctor_id.super_speciality.education}
                        </p>
                      </div>
                    </div>
                  </li>
                  <li
                    class='di-item'
                    data-for='toolTip'
                    data-tip={
                      props.getPatient &&
                      props.getPatient.lastFollowupNotes[
                        data.doctor_id.field_type.name
                      ] &&
                      Object.keys(
                        props.getPatient.lastFollowupNotes[
                          data.doctor_id.field_type.name
                        ]
                      ).length > 0
                        ? props.getPatient.lastFollowupNotes[
                            data.doctor_id.field_type.name
                          ].formData &&
                          props.getPatient.lastFollowupNotes[
                            data.doctor_id.field_type.name
                          ].formData['Current Complaints:'] &&
                          typeof props.getPatient.lastFollowupNotes[
                            data.doctor_id.field_type.name
                          ].formData['Current Complaints:'] === 'string'
                          ? props.getPatient.lastFollowupNotes[
                              data.doctor_id.field_type.name
                            ].formData['Current Complaints:']
                          : props.getPatient.lastFollowupNotes[
                              data.doctor_id.field_type.name
                            ].formData['Current Complaints:'] &&
                            props.getPatient.lastFollowupNotes[
                              data.doctor_id.field_type.name
                            ].formData['Current Complaints:'].length > 0
                          ? props.getPatient.lastFollowupNotes[
                              data.doctor_id.field_type.name
                            ].formData['Current Complaints:'] &&
                            props.getPatient.lastFollowupNotes[
                              data.doctor_id.field_type.name
                            ].formData['Current Complaints:']
                              .map(ec => ec.value)
                              .join(', ')
                          : 'NA'
                        : 'NA'
                    }>
                    <h3>Current Complaints:</h3>
                    <p>
                      <LinesEllipsis
                        text={
                          props.getPatient &&
                          props.getPatient.lastFollowupNotes[
                            data.doctor_id.field_type.name
                          ] &&
                          Object.keys(
                            props.getPatient.lastFollowupNotes[
                              data.doctor_id.field_type.name
                            ]
                          ).length > 0
                            ? props.getPatient.lastFollowupNotes[
                                data.doctor_id.field_type.name
                              ].formData &&
                              props.getPatient.lastFollowupNotes[
                                data.doctor_id.field_type.name
                              ].formData['Current Complaints:'] &&
                              typeof props.getPatient.lastFollowupNotes[
                                data.doctor_id.field_type.name
                              ].formData['Current Complaints:'] === 'string'
                              ? props.getPatient.lastFollowupNotes[
                                  data.doctor_id.field_type.name
                                ].formData['Current Complaints:']
                              : props.getPatient.lastFollowupNotes[
                                  data.doctor_id.field_type.name
                                ].formData['Current Complaints:'] &&
                                props.getPatient.lastFollowupNotes[
                                  data.doctor_id.field_type.name
                                ].formData['Current Complaints:'].length > 0
                              ? props.getPatient.lastFollowupNotes[
                                  data.doctor_id.field_type.name
                                ].formData['Current Complaints:'] &&
                                props.getPatient.lastFollowupNotes[
                                  data.doctor_id.field_type.name
                                ].formData['Current Complaints:']
                                  .map(ec => ec.value)
                                  .join(', ')
                              : 'NA'
                            : 'NA'
                        }
                        maxLine='2'
                        ellipsis='...'
                        trimRight
                        basedOn='letters'
                      />
                    </p>
                  </li>
                  <li
                    class='di-item'
                    data-for='toolTip'
                    data-tip={
                      props.getPatient.prevPsychiatristPrescription &&
                      props.getPatient.prevPsychiatristPrescription
                        .medical_prescription &&
                      props.getPatient.prevPsychiatristPrescription
                        .medical_prescription.length > 0 &&
                      props.getPatient.prevPsychiatristPrescription
                        .medical_prescription[0].composition
                        ? props.getPatient.prevPsychiatristPrescription.medical_prescription
                            .map(
                              elp =>
                                elp.formulation +
                                ' ' +
                                elp.composition +
                                ' ' +
                                elp.dose
                            )
                            .join(', ')
                        : 'NA'
                    }>
                    <h3>Last advise:</h3>
                    <p>
                      {data &&
                      data.doctor_id &&
                      data.doctor_id.field_type &&
                      data.doctor_id.field_type.name &&
                      data.doctor_id.field_type.name === 'Psychologist' ? (
                        <LinesEllipsis
                          text={
                            props.getPatient.prevPsychologistPrescription &&
                            props.getPatient.prevPsychologistPrescription
                              .theray_method
                              ? props.getPatient.prevPsychologistPrescription
                                  .theray_method
                              : 'NA'
                          }
                          maxLine='2'
                          ellipsis='...'
                          trimRight
                          basedOn='letters'
                        />
                      ) : (
                        <LinesEllipsis
                          text={
                            props.getPatient.prevPsychiatristPrescription &&
                            props.getPatient.prevPsychiatristPrescription
                              .medical_prescription &&
                            props.getPatient.prevPsychiatristPrescription
                              .medical_prescription.length > 0 &&
                            props.getPatient.prevPsychiatristPrescription
                              .medical_prescription[0].composition
                              ? props.getPatient.prevPsychiatristPrescription.medical_prescription
                                  .map(
                                    elp =>
                                      elp.formulation +
                                      ' ' +
                                      elp.composition +
                                      ' ' +
                                      elp.dose
                                  )
                                  .join(', ')
                              : 'NA'
                          }
                          maxLine='2'
                          ellipsis='...'
                          trimRight
                          basedOn='letters'
                        />
                      )}
                    </p>
                  </li>
                </ul>
              </div>
              
              <div
                class={`call-end ${
                  fullScreen ? 'mcb-body-fullscreen' : 'mcb-body'
                }`}>
                
                {loadContent}

                {!fullScreen && (
                  <>
                    <div
                      class='fullscreen-button'
                      style={{ cursor: 'pointer' }}
                      onClick={() => toggleFull()}>
                      <img src={fullScreenImg} alt='' />
                    </div>
                    <div id='agora_local' class='front-thumbnail-box'></div>
                  </>
                )}
              </div>
              
              {fullScreen && (
                <>
                  <div
                    class='fullscreen-button'
                    style={{ cursor: 'pointer' }}
                    onClick={() => toggleFull()}>
                    <img src={fullScreenImg} alt='' />
                  </div>
                  <div id='agora_local_full' class='front-thumbnail-box'></div>
                </>
              )}

              <div class={`${fullScreen ? 'mcb-footer-fullscreen' : ''} `}>
                <div className={`${fullScreen ? 'mb-foor-app' : ''} `}>
                  <div class='mcb-footer d-flex align-items-center justify-content-between'>
                    <div class='mcb-left'>
                      {moment().format('MMMM Do, YYYY')}
                    </div>
                    <div class='mcb-mid d-flex align-items-center flex-wrap'>
                      <div class='mcb-link' onClick={muteVideo}>
                        <div class='img'>
                          <img src={!video ? videoslash : videosOn} alt='' />
                        </div>
                        <p>Camera</p>
                      </div>
                      <div class='mcb-link' onClick={muteAudio}>
                        <div class='img'>
                          <img src={!audio ? phoneslash : micOn} alt='' />
                        </div>
                        <p>Mic</p>
                      </div>
                      
                      <div
                        class='mcb-link'
                        onClick={()=>{
                          if(!screenShare) handleScreenShareStart();
                          else handleScreenShareEnd();
                        }}
                      >
                        <div class='img'>
                          <img
                            src={!screenShare ? shareIcon : shareClose}
                            alt=''
                          />
                        </div>
                        <p>share</p>
                      </div>
                      <div class='mcb-link leave' onClick={leave}>
                        <div class='img'>
                          <img src={iosExit} alt='' />
                        </div>
                        <p>Leave</p>
                      </div>
                    </div>
                    <div class='mcb-right'>
                      <Timer />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {!fullScreen && (
              <div class='video-sidebar video-sidebar2'>
                <div class='vs-box'>
                  <div class='tab-container d-none d-md-block'>
                    <ul
                      class='nav nav-tabs videochat-tabs'
                      id='myTab'
                      role='tablist'>
                      <li
                        class='nav-item'
                        role='presentation'
                        data-for='toolTip'
                        data-tip='You can message your experts for up to 3 days after every appointment.'>
                        <a
                          class={
                            tab === 'message' ? 'nav-link active' : 'nav-link'
                          }
                          id='messages-tab'
                          data-toggle='tab'
                          href='#messages'
                          role='tab'
                          aria-controls='messages'
                          aria-selected='true'
                          onClick={e => changeTab('message', e)}>
                          <img src={messageIcon} alt='' />
                          <b>Messages</b>
                        </a>
                      </li>

                      <li
                        class='nav-item'
                        role='presentation'
                        data-for='toolTip'
                        data-tip='Reports/ Past prescriptions can be shared from here with your                                                                                          
                experts.'>
                        <a
                          class={
                            tab === 'documents' ? 'nav-link active' : 'nav-link'
                          }
                          id='profile-tab'
                          data-toggle='tab'
                          href='#mySummary'
                          role='tab'
                          aria-controls='mySummary'
                          aria-selected='false'
                          onClick={e => changeTab('documents', e)}>
                          {' '}
                          <img src={documentIcon} alt='' />
                          <b>My Reports</b>{' '}
                        </a>
                      </li>
                      <li
                        class='nav-item'
                        role='presentation'
                        data-for='toolTip'
                        data-tip='Notes & Thoughts'>
                        <a
                          class={
                            tab === 'notes' ? 'nav-link active' : 'nav-link'
                          }
                          id='contact-tab'
                          data-toggle='tab'
                          href='#myNotes'
                          role='tab'
                          aria-controls='myNotes'
                          aria-selected='false'
                          onClick={e => changeTab('notes', e)}>
                          {' '}
                          <img src={notesIcon} alt='' />
                          <b>Notes & Thoughts</b>{' '}
                        </a>
                      </li>
                      <li
                        class='nav-item'
                        role='presentation'
                        data-for='toolTip'
                        data-tip='When task is allotted by your expert it will be visible here till  
                Completed and submitted. Find completed tasks under timeline.'>
                        <a
                          class={
                            tab === 'tasks' ? 'nav-link active' : 'nav-link'
                          }
                          id='contact-tab'
                          data-toggle='tab'
                          href='#myTasks'
                          role='tab'
                          aria-controls='myTasks'
                          aria-selected='false'
                          onClick={e => changeTab('tasks', e)}>
                          {' '}
                          <img src={taskIcon} alt='' />
                          <b>Tasks</b>{' '}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class='chatbot-body p-md-0'>
                    <div class='tab-content' id='myTabContent'>
                      <div
                        class={
                          tab === 'message'
                            ? 'tab-pane card fade show active'
                            : 'tab-pane card'
                        }
                        id='messages'
                        role='tabpanel'
                        aria-labelledby='messages-tab'>
                        <Messeges
                          doctor_id={data.doctor_id._id}
                          patient_id={
                            JSON.parse(localStorage.getItem('patient'))._id
                          }
                          tab={tab}
                          setTab={changeTab}
                        />
                      </div>
                      <div
                        class={
                          tab === 'documents'
                            ? 'tab-pane card fade show active'
                            : 'tab-pane card'
                        }
                        id='messages'
                        role='tabpanel'
                        aria-labelledby='messages-tab'>
                        <Document
                          videoCall={true}
                          tab={tab}
                          setTab={changeTab}
                        />
                      </div>

                      <div
                        class={
                          tab === 'notes'
                            ? 'tab-pane card fade show active'
                            : 'tab-pane card'
                        }
                        id='messages'
                        role='tabpanel'
                        aria-labelledby='messages-tab'>
                        <Notes
                          aapointment_id={props.match.params.appointment_id}
                          doctor_id={data.doctor_id._id}
                          tab={tab}
                          setTab={changeTab}
                        />
                      </div>

                      <div
                        class={
                          tab === 'tasks'
                            ? 'tab-pane card fade show active'
                            : 'tab-pane card'
                        }
                        id='messages'
                        role='tabpanel'
                        aria-labelledby='messages-tab'>
                        {data && data.doctor_id && data.doctor_id._id && (
                          <Task
                            videoCall={true}
                            aapointment_id={props.match.params.appointment_id}
                            doctor_id={data.doctor_id._id}
                            tab={tab}
                            setTab={changeTab}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <TermTemplatePopup consent={true} show={popShow} toggle={togglePopup} />
    </>
  )
}
const mapDispatchToProps = dispatch => ({
  loadSeo: page_key => dispatch(loadSeo(page_key)),
  loadDoctorProfile: () => dispatch(loadDoctorProfile()),
  loadTermTemplate: params => dispatch(loadTermTemplate(params)),
  loadAppointmentDetailPatient: (id, callback) =>
    dispatch(loadAppointmentDetailPatient(id, callback)),
})
const mapStateToProps = state => ({
  profileLoading: getDoctor(state).profileLoading,
  getPatient: getPatient(state),
  doctorProfile: getDoctor(state).profile,
  getAppointment: getAppointment(state).doctorAppointment,
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VideoCallPatient)
)
