import React, { Component } from 'react'
import avtarImg from '../../../include/images/avatar1.png'
import bluestar from '../../../include/images/blue-star-icon.svg'
import { Link, withRouter } from 'react-router-dom'
import star from '../../../include/images/start-0.svg'
import moment from 'moment'
import Moment from 'moment'
import check from '../../../include/images/check-circle-09.svg'
import match from '../../../include/images/icon-matchat.svg'
import dot from '../../../include/images/vertical-dots.svg'
import { getDoctor } from '../../../store/doctor'
import { connect } from 'react-redux'
import Messages from './messages'
import SettingPopup from './settingPopup'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import { getCaserecord } from '../../../store/caseRecord'
import AvtarImage from '../../common/avtarImage'

class DetailHeader extends Component {
  state = {
    isShow3: false,
    isShow2: false,
    doc_id: '',
    doc_name: '',
    image: '',
    field: '',
    patient_id: '',
  }
  toggle3 = () => {
    this.setState({
      isShow3: !this.state.isShow3,
    })
    if (this.state.isShow3 === false) {
      document.body.className = 'dashboard fixed modal-open'
      document.body.style = 'padding-right: 17px;'
    } else {
      document.body.className = 'dashboard fixed'
    }
  }
  toggle2 = (patient_id, id, name, image, field) => {
    this.setState({
      isShow2: !this.state.isShow2,
      patient_id: patient_id,
      doc_id: id,
      doc_name: name,
      image: image,
      field: field,
    })
  }
  render() {
    const { detail, caserecord } = this.props

    return (
      <>
        {' '}
        <div class='patients-details-head d-md-flex align-items-md-center justify-content-between'>
          <a
            href='#!'
            onClick={e => {
              e.preventDefault()
              this.props.history.goBack()
            }}
            class='patients-back-link'></a>
          <div class='patients-details-intro d-md-flex'>
            <div class='patients-img has-active'>
              {detail && detail.thumb ? (
                <img src={process.env.REACT_APP_S3URL + detail.thumb} alt='' />
              ) : (
                <AvtarImage />
              )}
            </div>
            <div class='patients-details-box'>
              <div class='patients-details-upper d-md-flex align-items-md-center'>
                <div class='patients-details-info d-flex align-items-center'>
                  <h4>
                    {detail && detail.first_name + ' ' + detail.last_name}
                  </h4>
                  <i>
                    {detail && detail.isStar === true ? (
                      <img src={bluestar} alt='star-icon' />
                    ) : (
                      <img src={star} alt='star-icon' />
                    )}
                  </i>
                </div>
                {caserecord &&
                  caserecord.finish &&
                  caserecord.finish.diagnosis &&
                  this.props.doctorProfile.field_type && (
                    <>
                      <div class='patients-varify d-flex align-items-center'>
                        <ReactTooltip className='testPurple' id='detailHeader-tooltip' />
                        <i data-tooltip-id='detailHeader-tooltip' data-tooltip-content='CRF completed'>
                          <img src={check} alt='' />
                        </i>
                        <span>Verified Patient</span>
                      </div>

                      {caserecord.finish &&
                        caserecord.finish[
                          this.props.doctorProfile.field_type.name ===
                          'Psychologist'
                            ? 'diagnosis'
                            : 'diagnosisPsychiatrist'
                        ] &&
                        caserecord.finish[
                          this.props.doctorProfile.field_type.name ===
                          'Psychologist'
                            ? 'diagnosis'
                            : 'diagnosisPsychiatrist'
                        ].diagnosis.length > 0 &&
                        caserecord.finish[
                          this.props.doctorProfile.field_type.name ===
                          'Psychologist'
                            ? 'diagnosis'
                            : 'diagnosisPsychiatrist'
                        ].diagnosis.map(fd => (
                          <div class='pd-right-content mr-2'>
                            <div class='d-flex align-items-center justify-content-md-end'>
                              <strong>{fd.value}</strong>
                              <div class='custom-dropright'>
                                <div>
                                  <a
                                    class='custom-dropdown-toggle'
                                    href='#!'
                                    onClick={e => e.preventDefault()}
                                    data-tooltip-id='detailHeader-tooltip'
                                    data-tooltip-content={fd.diagnosis}>
                                    <i
                                      class='fas fa-info-circle'
                                      aria-hidden='true'></i>
                                  </a>
                                  {'  '}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </>
                  )}
              </div>
              <div class='patients-details-mid d-flex  flex-wrap align-items-center'>
                <div class='pdm-inner-box d-flex align-items-center'>
                  <span>UHID:</span>
                  <p>{detail && detail.yesmindy_identification_number}</p>
                </div>
                <div class='pdm-inner-box d-flex align-items-center'>
                  <span>Sex:</span>
                  <p>{detail && detail.sex === 'M' ? 'Male' : 'Female'}</p>
                </div>
                <div class='pdm-inner-box d-flex align-items-center'>
                  <span>Date of Birth:</span>
                  <p>
                    {detail && detail.date_of_birth
                      ? Moment(detail.date_of_birth).format('DD-MM-YYYY')
                      : ''}
                  </p>
                </div>
                <div class='pdm-inner-box d-flex align-items-center'>
                  <span>Age:</span>
                  <p>
                    {' '}
                    {moment().diff(detail && detail.date_of_birth, 'years')}yrs
                  </p>
                </div>
              </div>
              <div class='patients-details-lower d-flex  flex-wrap align-items-center'>
                <div class='ddm-inner-box d-flex align-items-center flex-wrap'>
                  <span>Treated By:</span>
                  <div class='d-flex align-items-center '>
                    {detail &&
                      detail.Psychiatrist != null &&
                      detail.Psychologist === null && (
                        <>
                          <strong>{this.props.detail.Psychiatrist.name}</strong>{' '}
                          <small>
                            {(this.props.detail.Psychiatrist.name ? ', ' : '') +
                              'Psychiatrist'}
                          </small>
                        </>
                      )}
                    {detail &&
                      detail.Psychiatrist != null &&
                      detail.Psychologist != null && (
                        <>
                          <strong>{this.props.detail.Psychiatrist.name}</strong>{' '}
                          <small>
                            {(this.props.detail.Psychiatrist.name ? ', ' : '') +
                              'Psychiatrist'}
                            ,
                          </small>
                        </>
                      )}
                    {detail && detail.Psychologist != null && (
                      <>
                        <strong>{this.props.detail.Psychologist.name}</strong>
                        <small>
                          {(this.props.detail.Psychologist.name ? ', ' : '') +
                            'Psychologist'}
                        </small>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class='pd-right-box d-flex align-items-center'>
            <ul class='list-with-icon d-flex align-items-center'>
              <li data-tooltip-id='detailHeader-tooltip' data-tooltip-content='Emergency Contact Details'>
                <ReactTooltip className='testPurple' />
                <a
                  href='javascript:void(0)'
                  onClick={e => {
                    e.preventDefault()
                    this.toggle3()
                  }}
                  class='icon-box orange-icon'
                  data-toggle='modal'
                  data-target='#exampleModalCenter'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='30'
                    height='29.489'
                    viewBox='0 0 30 29.489'>
                    <path
                      class='a'
                      style={{ fill: '#f1964f' }}
                      d='M27.305,38.134H18.8a.817.817,0,0,1-.818-.818V32.163l-4.462,2.574a.818.818,0,0,1-1.113-.3L8.16,27.083a.818.818,0,0,1-.082-.617.861.861,0,0,1,.378-.5l4.462-2.574L8.456,20.82a.8.8,0,0,1-.378-.5.809.809,0,0,1,.082-.617l4.247-7.362a.813.813,0,0,1,1.113-.3l4.462,2.574V9.463a.817.817,0,0,1,.818-.818h8.5a.812.812,0,0,1,.812.818v5.153l4.462-2.574a.812.812,0,0,1,1.113.3L37.941,19.7a.818.818,0,0,1,.082.617.861.861,0,0,1-.378.5L33.182,23.4l4.462,2.574a.8.8,0,0,1,.378.5.809.809,0,0,1-.082.617l-4.247,7.362a.813.813,0,0,1-1.113.3l-4.462-2.574v5.153a.814.814,0,0,1-.811.812Z'
                      transform='translate(-8.05 -8.645)'
                    />
                  </svg>{' '}
                </a>
              </li>

              <li>
                <a
                  href='javascript:void(0)'
                  class='np-links'
                  onClick={() =>
                    this.toggle2(
                      detail._id,
                      this.props.doctorProfile._id,
                      this.props.doctorProfile.name,
                      this.props.doctorProfile.image_url,
                      this.props.doctorProfile.field_type.name
                    )
                  }
                  data-tooltip-id='detailHeader-tooltip'
                  data-tooltip-content='Message'
                  aria-hidden='true'
                  data-toggle='matchat'>
                  <img src={match} alt='' />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <SettingPopup
          isShow={this.state.isShow3}
          toggle={this.toggle3}
          detail={detail}
        />
        <Messages
          isShow={this.state.isShow2}
          toggle={this.toggle2}
          doctor_id={this.state.doc_id}
          patient_id={this.state.patient_id}
          doc_name={this.state.doc_name}
          image={this.state.image}
          field={this.state.field}
        />
      </>
    )
  }
}
const mapStateToProps = state => ({
  doctorProfile: getDoctor(state).profile,
})
export default withRouter(connect(mapStateToProps, null)(DetailHeader))
