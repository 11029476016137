import React, { Component } from 'react'
import bluestar from '../../../../include/images/blue-star-icon.svg'
import star from '../../../../include/images/start-0.svg'
import closeIcon from '../../../../include/images/close.svg'
import ListPrescription from './listPrescription'
import { loadList, getPrescription } from '../../../../store/prescription'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Moment from 'moment'
import { Modal } from 'react-bootstrap'
import AvtarImage from '../../../common/avtarImage'
import NoRecord from '../../../common/noRecord'
import { getDoctor } from '../../../../store/doctor'
import { Tooltip as ReactTooltip } from 'react-tooltip'

class Prescription extends Component {
  state = {
    popup: false,
    isShow1: false,
    id: '',
    first_name: '',
    sex: '',
    last_name: '',
    image: '',
    time: '',
    star: '',
    yesmindy_identification_number: '',
    type: '',
    lastAppointment_time: '',
  }
  toggle1 = (
    id,
    first_name,
    last_name,
    thumb,
    appointment_time,
    star,
    yesmindy_identification_number,
    type,
    sex,
    lastAppointment_time
  ) => {
    this.setState({
      isShow1: !this.state.isShow1,
      id: id,
      first_name: first_name,
      last_name: last_name,
      image: thumb,
      time: appointment_time,
      star: star,
      yesmindy_identification_number: yesmindy_identification_number,
      type: type,
      sex,
      lastAppointment_time,
    })
  }
  //   componentDidMount() {
  //     this.props.loadList();
  //   }
  render() {
    const { list } = this.props
    console.log("Appointment list", list);
    return (
      <>
        <ReactTooltip className='testPurple' id='doc-db-prescription-tt' />
        <div
          class='tab-pane fade show active'
          id='prescriptions'
          role='tabpanel'
          aria-labelledby='prescriptions-tab'>
          <div class='tb-body'>
            {list.length === 0 && (
              <NoRecord
                noLogo={true}
                header='No Prescriptions Created'
                text='Prescriptions are created every time a new appointment is booked. Prescription can only be filled after making a diagnosis on case record form for a new patient.'
              />
            )}
            {list.filter(e => !e.prescription.is_submited).length > 0 && (
              <div class='chat-seprator1'>
                <span>In-Complete</span>
              </div>
            )}

            <ul class='chat-member-list bordered'>
              {list
                .filter(e => !e.prescription.is_submited)
                .map((e, i) => (
                  <li
                    class='cm-item'
                    onClick={() => {
                      if (!e.caseRecord) return this.setState({ popup: true })
                      if (!e.caseRecord.finish)
                        return this.setState({ popup: true })
                      if (
                        !e.caseRecord.finish[
                          this.props.doctorProfile.field_type.name ===
                          'Psychiatrist'
                            ? 'diagnosisPsychiatrist'
                            : 'diagnosis'
                        ]
                      )
                        return this.setState({ popup: true })
                      if (
                        !e.caseRecord.finish[
                          this.props.doctorProfile.field_type.name ===
                          'Psychiatrist'
                            ? 'diagnosisPsychiatrist'
                            : 'diagnosis'
                        ].diagnosis ||
                        e.caseRecord.finish[
                          this.props.doctorProfile.field_type.name ===
                          'Psychiatrist'
                            ? 'diagnosisPsychiatrist'
                            : 'diagnosis'
                        ].diagnosis.length === 0
                      )
                        return this.setState({ popup: true })

                      console.log('e toggle', e)
                      this.toggle1(
                        e._id,
                        e.first_name,
                        e.last_name,
                        e.thumb,
                        e.appointment_time,
                        e.isStar,
                        e.yesmindy_identification_number,
                        e.Psychologist ? 'Psychologist' : 'Psychiatrist',
                        e.sex,
                        e.lastAppointment
                          ? e.lastAppointment.appointment_time
                          : ''
                      )
                    }}>
                    <div class='cm-box d-flex flex-wrap'>
                      <div class='person-details-box d-flex align-items-center flex-grow-1'>
                        <div class='person-img'>
                          {e.thumb ? (
                            <img
                              src={process.env.REACT_APP_S3URL + e.thumb}
                              alt=''
                            />
                          ) : (
                            <AvtarImage />
                          )}
                        </div>
                        <div class='person-text-box'>
                          <h3>
                            {e.first_name + ' ' + e.last_name}{' '}
                            <i>
                              {e.isStar === true ? (
                                <img src={bluestar} alt='star-icon' />
                              ) : (
                                <img src={star} alt='star-icon' />
                              )}
                            </i>
                          </h3>
                          <p>{e.yesmindy_identification_number}</p>
                        </div>
                      </div>
                      {e.lastAppointment && (
                        <div class='pd-right-content'>
                          <div>
                            Last appointment:{' '}
                            {Moment(e.lastAppointment.appointment_time)
                              .utc()
                              .format('DD-MM-YYYY')}
                          </div>
                          <div>
                            {e.caseRecord.finish &&
                              e.caseRecord.finish[
                                this.props.doctorProfile.field_type.name ===
                                'Psychiatrist'
                                  ? 'diagnosisPsychiatrist'
                                  : 'diagnosis'
                              ].diagnosis &&
                              e.caseRecord.finish[
                                this.props.doctorProfile.field_type.name ===
                                'Psychiatrist'
                                  ? 'diagnosisPsychiatrist'
                                  : 'diagnosis'
                              ].diagnosis.length > 0 && (
                                <>
                                  {e.caseRecord.finish[
                                    this.props.doctorProfile.field_type.name ===
                                    'Psychologist'
                                      ? 'diagnosis'
                                      : 'diagnosisPsychiatrist'
                                  ].diagnosis.map(ed => (
                                    <div class='d-flex align-items-center justify-content-end'>
                                      <strong>{ed.value}</strong>
                                      <div className='custom-dropright'>
                                        <div>
                                          <a
                                            className='custom-dropdown-toggle'
                                            href='javascript:void(0)'
                                            data-tooltip-id="doc-db-prescription-tt" 
                                            data-tooltip-content={ed.diagnosis}
                                            //</div>data-tip={ed.diagnosis}
                                            >
                                            <i
                                              data-tooltip-id="doc-db-prescription-tt" 
                                              data-tooltip-content={ed.diagnosis}
                                              //data-tip={ed.diagnosis}
                                              className='fas fa-info-circle'
                                              aria-hidden='true'></i>
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                  {/* <div style={{ textAlign: 'right' }}>
                                  <div style={{ fontWeight: 'bold' }}>
                                    {e.caseRecord.finish[
                                      this.props.doctorProfile.field_type
                                        .name === 'Psychiatrist'
                                        ? 'diagnosisPsychiatrist'
                                        : 'diagnosis'
                                    ].diagnosis.map((item) => (
                                      <div>{item?.value}</div>
                                    ))}
                                    <a
                                      data-tip={
                                        e.caseRecord.finish[
                                          this.props.doctorProfile.field_type
                                            .name === 'Psychiatrist'
                                            ? 'diagnosisPsychiatrist'
                                            : 'diagnosis'
                                        ].diagnosis[0].diagnosis
                                      }
                                      //className="nav-link"
                                      id="tasks-tab"
                                      data-toggle="tab"
                                      role="tab"
                                      href="#"
                                      aria-controls="tasks"
                                      aria-selected="true"
                                    >
                                      <i
                                        data-tip={
                                          e.caseRecord.finish[
                                            this.props.doctorProfile.field_type
                                              .name === 'Psychiatrist'
                                              ? 'diagnosisPsychiatrist'
                                              : 'diagnosis'
                                          ].diagnosis[0].diagnosis
                                        }
                                        class="fas fa-info-circle ml-2"
                                        style={{
                                          fontSize: '12px',
                                        }}
                                      ></i>
                                    </a>
                                  </div>
                                </div> */}
                                </>
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                ))}
            </ul>

            {list.filter(e => e.prescription.is_submited).length > 0 && (
              <div class='chat-seprator1'>
                <span>Complete</span>
              </div>
            )}

            <ul class='chat-member-list bordered'>
              {list
                .filter(e => e.prescription.is_submited)
                .map((e, i) => (
                  <li
                    class='cm-item'
                    onClick={() => {
                      if (
                        !e.caseRecord.finish[
                          this.props.doctorProfile.field_type.name ===
                          'Psychiatrist'
                            ? 'diagnosisPsychiatrist'
                            : 'diagnosis'
                        ].diagnosis ||
                        e.caseRecord.finish[
                          this.props.doctorProfile.field_type.name ===
                          'Psychiatrist'
                            ? 'diagnosisPsychiatrist'
                            : 'diagnosis'
                        ].diagnosis.length === 0
                      )
                        return this.setState({ popup: true })
                      this.toggle1(
                        e._id,
                        e.first_name,
                        e.last_name,
                        e.thumb,
                        e.appointment_time,
                        e.isStar,
                        e.yesmindy_identification_number,
                        e.Psychologist ? 'Psychologist' : 'Psychiatrist'
                      )
                    }}>
                    <div class='cm-box d-flex flex-wrap'>
                      <div class='person-details-box d-flex align-items-center flex-grow-1'>
                        <div class='person-img'>
                          {e.thumb ? (
                            <img
                              src={process.env.REACT_APP_S3URL + e.thumb}
                              alt=''
                            />
                          ) : (
                            <AvtarImage />
                          )}
                        </div>
                        <div class='person-text-box'>
                          <h3>
                            {e.first_name + ' ' + e.last_name}{' '}
                            <i>
                              {e.isStar === true ? (
                                <img src={bluestar} alt='star-icon' />
                              ) : (
                                <img src={star} alt='star-icon' />
                              )}
                            </i>
                          </h3>
                          <p>{e.yesmindy_identification_number}</p>
                        </div>
                      </div>
                      <div class='pd-right-content'>
                        <div>
                          Date:{' '}
                          {Moment(e.appointment_time).format('DD-MM-YYYY')}
                        </div>
                        <div>
                          {e.caseRecord.finish &&
                            e.caseRecord.finish[
                              this.props.doctorProfile.field_type.name ===
                              'Psychiatrist'
                                ? 'diagnosisPsychiatrist'
                                : 'diagnosis'
                            ].diagnosis &&
                            e.caseRecord.finish[
                              this.props.doctorProfile.field_type.name ===
                              'Psychiatrist'
                                ? 'diagnosisPsychiatrist'
                                : 'diagnosis'
                            ].diagnosis.length > 0 && (
                              <>
                                {e.caseRecord.finish[
                                  this.props.doctorProfile.field_type.name ===
                                  'Psychologist'
                                    ? 'diagnosis'
                                    : 'diagnosisPsychiatrist'
                                ].diagnosis.map(ed => (
                                  <div class='d-flex align-items-center justify-content-end'>
                                    <strong>{ed.value}</strong>
                                    <div className='custom-dropright'>
                                      <div>
                                        <a
                                          className='custom-dropdown-toggle'
                                          href='javascript:void(0)'
                                          data-tooltip-id="doc-db-prescription-tt" 
                                          data-tooltip-content={ed.diagnosis}
                                          //</div>data-tip={ed.diagnosis}
                                          >
                                          <i
                                            data-tooltip-id="doc-db-prescription-tt" 
                                            data-tooltip-content={ed.diagnosis}
                                           // data-tip={ed.diagnosis}
                                            className='fas fa-info-circle'
                                            aria-hidden='true'></i>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </div>
        <ListPrescription
          isShow1={this.state.isShow1}
          yesmindy_identification_number={
            this.state.yesmindy_identification_number
          }
          type={this.state.type}
          toggle1={this.toggle1}
          id={this.state.id}
          first_name={this.state.first_name}
          last_name={this.state.last_name}
          sex={this.state.sex}
          image={this.state.image}
          star={this.state.star}
          time={this.state.time}
          lastAppointment_time={this.state.lastAppointment_time}
          appointment_date={this.props.appointment_date}
        />

        <Modal
          aria-labelledby='contained-modal-title-vcenter'
          centered
          dialogClassName='modal-dialog modal-dialog-centered modal-sm'
          className='modal fade '
          show={this.state.popup}>
          <>
            <div class='modal-header'>
              <button
                onClick={() => {
                  this.setState({ popup: false })
                }}
                type='button'
                class='close'
                data-dismiss='modal'>
                <img src={closeIcon} alt='close popup' />
              </button>
            </div>
            <div class='modal-body'>
              <p>Please fill Case Record Form first.</p>
              <div class='modal-action-btn'>
                <a
                  href='#!'
                  class='btn btn-outline no-shadow'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={e => {
                    e.preventDefault()
                    this.setState({ popup: false })
                  }}>
                  Ok
                </a>
              </div>
            </div>
          </>
        </Modal>
      </>
    )
  }
}
const mapStateToProps = state => ({
  list: getPrescription(state).list,
  doctorProfile: getDoctor(state).profile,
})
export default withRouter(connect(mapStateToProps, null)(Prescription))
