import React, { Component } from 'react'
import avtarImg from '../../../../include/images/avatar1.png'
import bluestar from '../../../../include/images/blue-star-icon.svg'
import star from '../../../../include/images/start-0.svg'
import closeIcon from '../../../../include/images/close.svg'
import { getPrescription, loadList } from '../../../../store/prescription'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Moment from 'moment'
import FollowupForm from './followupForm'
import { Modal } from 'react-bootstrap'
import AvtarImage from '../../../common/avtarImage'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import NoRecord from '../../../common/noRecord'
import moment from 'moment'
import { getDoctor } from '../../../../store/doctor'

class Followup extends Component {
  state = {
    popup: false,
    isShow1: false,
    id: '',
    first_name: '',
    last_name: '',
    image: '',
    time: '',
    star: '',
    yesmindy_identification_number: '',
    appointment_id: '',
    patient_id: '',
    caseRecord: {},
    age: '',
    sex: '',
  }
  toggle1 = (
    id,
    first_name,
    last_name,
    thumb,
    appointment_time,
    star,
    yesmindy_identification_number,
    appointment_id,
    patient_id,
    caseRecord,
    age,
    sex
  ) => {
    this.setState({
      patient_id,
      appointment_id,
      isShow1: !this.state.isShow1,
      id: id,
      first_name: first_name,
      last_name: last_name,
      image: thumb,
      time: appointment_time,
      star: star,
      yesmindy_identification_number: yesmindy_identification_number,
      caseRecord,
      age,
      sex,
    })
  }

  render() {
    const { list } = this.props

    return (
      <>
        <div
          class='tab-pane fade show active'
          id='prescriptions'
          role='tabpanel'
          aria-labelledby='prescriptions-tab'>
          <ReactTooltip className='testPurple' id="followup-tooltip1" />
          {this.props.video ? (
            <div class='tb-body'>
              {list
                .filter(
                  ef =>
                    ef.appointmentData.followup &&
                    !ef.followupNotes.is_submitted
                )
                .filter(cb => cb._id == this.props.id).length > 0 ? (
                <div class='chat-seprator1'>
                  <span>In-Complete</span>
                </div>
              ) : this.props.video ? (
                ''
              ) : (
                <NoRecord
                  noLogo={true}
                  header='No Follow-Up Notes Are Pending To Be Filled'
                  text='A Follow-Up template will be visible here to be filled by you when your old patients have booked again.'
                />
              )}

              <ul class='chat-member-list bordered'>
                {list
                  .filter(
                    ef =>
                      ef.appointmentData.followup &&
                      !ef.followupNotes.is_submitted
                  )
                  .filter(cb => cb._id == this.props.id)
                  .map((e, i) => (
                    <li
                      class='cm-item'
                      onClick={() => {
                        if (!e.caseRecord) return this.setState({ popup: true })
                        if (!e.caseRecord.finish)
                          return this.setState({ popup: true })
                        if (
                          !e.caseRecord.finish[
                            this.props.doctorProfile.field_type.name ===
                            'Psychiatrist'
                              ? 'diagnosisPsychiatrist'
                              : 'diagnosis'
                          ]
                        )
                          return this.setState({ popup: true })
                        if (
                          e.caseRecord.finish[
                            this.props.doctorProfile.field_type.name ===
                            'Psychiatrist'
                              ? 'diagnosisPsychiatrist'
                              : 'diagnosis'
                          ].diagnosis &&
                          e.caseRecord.finish[
                            this.props.doctorProfile.field_type.name ===
                            'Psychiatrist'
                              ? 'diagnosisPsychiatrist'
                              : 'diagnosis'
                          ].diagnosis.length === 0
                        )
                          return this.setState({ popup: true })
                        this.toggle1(
                          e._id,
                          e.first_name,
                          e.last_name,
                          e.thumb,
                          e.appointment_time,
                          e.isStar,
                          e.yesmindy_identification_number,
                          e.appointmentData._id,
                          e._id,
                          e.caseRecord.finish[
                            this.props.doctorProfile.field_type.name ===
                            'Psychiatrist'
                              ? 'diagnosisPsychiatrist'
                              : 'diagnosis'
                          ],
                          e.date_of_birth,
                          e.sex
                        )
                      }}>
                      <div class='cm-box d-flex flex-wrap'>
                        <div class='person-details-box d-flex align-items-center flex-grow-1'>
                          <div class='person-img'>
                            {e.thumb ? (
                              <img
                                src={process.env.REACT_APP_S3URL + e.thumb}
                                alt=''
                              />
                            ) : (
                              <AvtarImage />
                            )}
                          </div>
                          <div class='person-text-box'>
                            <h3>
                              {e.first_name + ' ' + e.last_name}{' '}
                              <i>
                                {e.isStar === true ? (
                                  <img src={bluestar} alt='star-icon' />
                                ) : (
                                  <img src={star} alt='star-icon' />
                                )}
                              </i>
                            </h3>
                            <p>{e.yesmindy_identification_number}</p>
                          </div>
                        </div>
                        <div class='pd-right-content'>
                          {e.lastAppointment && (
                            <>
                              Last appointment:{' '}
                              {Moment(e.lastAppointment.appointment_time)
                                .utc()
                                .format('DD-MM-YYYY')}
                            </>
                          )}
                          <div class='d-flex align-items-center justify-content-end'>
                            <strong>
                              {e.caseRecord.finish &&
                                e.caseRecord.finish.diagnosis &&
                                e.caseRecord.finish.diagnosis.diagnosis.value}
                            </strong>
                          </div>
                          <h6>
                            {' '}
                            <div class='d-flex align-items-center '>
                              {e.caseRecord &&
                                e.caseRecord.finish &&
                                e.caseRecord.finish.diagnosis &&
                                e.caseRecord.finish.diagnosis.diagnosis &&
                                e.caseRecord.finish.diagnosis.diagnosis.map(
                                  eds => (
                                    <>
                                      <strong>{eds.value}</strong>
                                      <i
                                      data-tooltip-id='followup-tooltip1'
                                        data-tooltip-content={eds.diagnosis}
                                        class='fas fa-info-circle ml-2'
                                        aria-hidden='true'></i>
                                    </>
                                  )
                                )}
                            </div>
                          </h6>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>

              {list
                .filter(
                  ef =>
                    ef.appointmentData.followup && ef.followupNotes.is_submitted
                )
                .filter(cb => cb._id == this.props.id).length > 0 && (
                <div class='chat-seprator1'>
                  <span>Complete</span>
                </div>
              )}

              <ul class='chat-member-list bordered'>
                {list
                  .filter(
                    ef =>
                      ef.appointmentData.followup &&
                      ef.followupNotes.is_submitted
                  )
                  .filter(cb => cb._id == this.props.id)
                  .map((e, i) => (
                    <li
                      class='cm-item'
                      onClick={() => {
                        if (!e.caseRecord.finish)
                          return this.setState({ popup: true })
                        this.toggle1(
                          e._id,
                          e.first_name,
                          e.last_name,
                          e.thumb,
                          e.appointment_time,
                          e.isStar,
                          e.yesmindy_identification_number,
                          e.appointmentData._id,
                          e._id,
                          e.caseRecord.finish[
                            this.props.doctorProfile.field_type.name ===
                            'Psychiatrist'
                              ? 'diagnosisPsychiatrist'
                              : 'diagnosis'
                          ],
                          e.date_of_birth,
                          e.sex
                        )
                      }}>
                      <div class='cm-box d-flex flex-wrap'>
                        <div class='person-details-box d-flex align-items-center flex-grow-1'>
                          <div class='person-img'>
                            {e.thumb ? (
                              <img
                                src={process.env.REACT_APP_S3URL + e.thumb}
                                alt=''
                              />
                            ) : (
                              <AvtarImage />
                            )}
                          </div>
                          <div class='person-text-box'>
                            <h3>
                              {e.first_name + ' ' + e.last_name}{' '}
                              <i>
                                {e.isStar === true ? (
                                  <img src={bluestar} alt='star-icon' />
                                ) : (
                                  <img src={star} alt='star-icon' />
                                )}
                              </i>
                            </h3>
                            <p>{e.yesmindy_identification_number}</p>
                          </div>
                        </div>
                        <div class='pd-right-content'>
                          {e.lastAppointment && (
                            <>
                              Last appointment:{' '}
                              {Moment(e.lastAppointment.appointment_time)
                                .utc()
                                .format('DD-MM-YYYY')}
                            </>
                          )}
                          <div class='d-flex align-items-center justify-content-end '>
                            <strong>
                              {e.caseRecord.finish &&
                                e.caseRecord.finish.diagnosis &&
                                e.caseRecord.finish.diagnosis.diagnosis.value}
                            </strong>
                          </div>
                          <h6>
                            {' '}
                            <div class='d-flex align-items-center '>
                              {e.caseRecord &&
                                e.caseRecord.finish &&
                                e.caseRecord.finish.diagnosis &&
                                e.caseRecord.finish.diagnosis.diagnosis &&
                                e.caseRecord.finish.diagnosis.diagnosis.map(
                                  eds => (
                                    <>
                                      <strong>{eds.value}</strong>
                                      <i
                                      data-tooltip-id='followup-tooltip1'
                                        data-tooltip-content={eds.diagnosis}
                                        class='fas fa-info-circle mr-2'
                                        aria-hidden='true'></i>
                                    </>
                                  )
                                )}
                            </div>
                          </h6>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          ) : (
            <div class='tb-body'>
              {list.filter(
                ef =>
                  ef.appointmentData.followup && !ef.followupNotes.is_submitted
              ).length > 0 ? (
                <div class='chat-seprator1'>
                  <span>In-Complete</span>
                </div>
              ) : (
                <NoRecord
                  noLogo={true}
                  header='No Follow-Up Notes Are Pending To Be Filled'
                  text='A Follow-Up template will be visible here to be filled by you when your old patients have booked again.'
                />
              )}

              <ul class='chat-member-list bordered'>
                {list
                  .filter(
                    ef =>
                      ef.appointmentData.followup &&
                      !ef.followupNotes.is_submitted
                  )
                  .map((e, i) => (
                    <li
                      class='cm-item'
                      onClick={() => {
                        // if (
                        //   moment(e.appointment_time).diff(
                        //     moment().add({ hours: 5, minutes: 30 }),
                        //     "second"
                        //   ) > 0
                        // )
                        //   return 0;
                        if (!e.caseRecord) return this.setState({ popup: true })
                        if (!e.caseRecord.finish)
                          return this.setState({ popup: true })
                        if (
                          !e.caseRecord.finish[
                            this.props.doctorProfile.field_type.name ===
                            'Psychiatrist'
                              ? 'diagnosisPsychiatrist'
                              : 'diagnosis'
                          ]
                        )
                          return this.setState({ popup: true })
                        if (
                          e.caseRecord.finish[
                            this.props.doctorProfile.field_type.name ===
                            'Psychiatrist'
                              ? 'diagnosisPsychiatrist'
                              : 'diagnosis'
                          ].diagnosis &&
                          e.caseRecord.finish[
                            this.props.doctorProfile.field_type.name ===
                            'Psychiatrist'
                              ? 'diagnosisPsychiatrist'
                              : 'diagnosis'
                          ].diagnosis.length === 0
                        )
                          return this.setState({ popup: true })
                        this.toggle1(
                          e._id,
                          e.first_name,
                          e.last_name,
                          e.thumb,
                          e.appointment_time,
                          e.isStar,
                          e.yesmindy_identification_number,
                          e.appointmentData._id,
                          e._id,
                          e.caseRecord.finish[
                            e.doctor_id.field_type.name === 'Psychologist'
                              ? 'diagnosis'
                              : 'diagnosisPsychiatrist'
                          ],
                          e.date_of_birth,
                          e.sex
                        )
                      }}>
                      <div class='cm-box d-flex flex-wrap'>
                        <div class='person-details-box d-flex align-items-center flex-grow-1'>
                          <div class='person-img'>
                            {e.thumb ? (
                              <img
                                src={process.env.REACT_APP_S3URL + e.thumb}
                                alt=''
                              />
                            ) : (
                              <AvtarImage />
                            )}
                          </div>
                          <div class='person-text-box'>
                            <h3>
                              {e.first_name + ' ' + e.last_name}{' '}
                              <i>
                                {e.isStar === true ? (
                                  <img src={bluestar} alt='star-icon' />
                                ) : (
                                  <img src={star} alt='star-icon' />
                                )}
                              </i>
                            </h3>
                            <p>{e.yesmindy_identification_number}</p>
                          </div>
                        </div>
                        <div class='pd-right-content'>
                          {e.lastAppointment && (
                            <>
                              Last appointment:{' '}
                              {Moment(e.lastAppointment.appointment_time)
                                .utc()
                                .format('DD-MM-YYYY')}
                            </>
                          )}
                          <div class='d-flex align-items-center justify-content-end'>
                            <strong>
                              {e.caseRecord.finish &&
                                e.caseRecord.finish.diagnosis &&
                                e.caseRecord.finish.diagnosis.diagnosis.value}
                            </strong>
                          </div>
                          <h6>
                            {' '}
                            <div class='d-flex align-items-center '>
                              {e.caseRecord &&
                                e.caseRecord.finish &&
                                e.caseRecord.finish.diagnosis &&
                                e.caseRecord.finish.diagnosis.diagnosis &&
                                e.caseRecord.finish.diagnosis.diagnosis.map(
                                  eds => (
                                    <>
                                      <strong>{eds.value}</strong>
                                      <i
                                      data-tooltip-id='followup-tooltip1'
                                        data-tooltip-content={eds.diagnosis}
                                        class='fas fa-info-circle ml-2'
                                        aria-hidden='true'></i>
                                    </>
                                  )
                                )}
                            </div>
                          </h6>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>

              {list.filter(
                ef =>
                  ef.appointmentData.followup && ef.followupNotes.is_submitted
              ).length > 0 && (
                <div class='chat-seprator1'>
                  <span>Complete</span>
                </div>
              )}

              <ul class='chat-member-list bordered'>
                {list
                  .filter(
                    ef =>
                      ef.appointmentData.followup &&
                      ef.followupNotes.is_submitted
                  )
                  .map((e, i) => (
                    <li
                      class='cm-item'
                      onClick={() => {
                        if (!e.caseRecord.finish)
                          return this.setState({ popup: true })
                        this.toggle1(
                          e._id,
                          e.first_name,
                          e.last_name,
                          e.thumb,
                          e.appointment_time,
                          e.isStar,
                          e.yesmindy_identification_number,
                          e.appointmentData._id,
                          e._id,
                          e.caseRecord,
                          e.date_of_birth,
                          e.sex
                        )
                      }}>
                      <div class='cm-box d-flex flex-wrap'>
                        <div class='person-details-box d-flex align-items-center flex-grow-1'>
                          <div class='person-img'>
                            {e.thumb ? (
                              <img
                                src={process.env.REACT_APP_S3URL + e.thumb}
                                alt=''
                              />
                            ) : (
                              <AvtarImage />
                            )}
                          </div>
                          <div class='person-text-box'>
                            <h3>
                              {e.first_name + ' ' + e.last_name}{' '}
                              <i>
                                {e.isStar === true ? (
                                  <img src={bluestar} alt='star-icon' />
                                ) : (
                                  <img src={star} alt='star-icon' />
                                )}
                              </i>
                            </h3>
                            <p>{e.yesmindy_identification_number}</p>
                          </div>
                        </div>
                        <div class='pd-right-content'>
                          {e.lastAppointment && (
                            <>
                              Last appointment:{' '}
                              {Moment(e.lastAppointment.appointment_time)
                                .utc()
                                .format('DD-MM-YYYY')}
                            </>
                          )}
                          <div class='d-flex align-items-center justify-content-end '>
                            <strong>
                              {e.caseRecord.finish &&
                                e.caseRecord.finish.diagnosis &&
                                e.caseRecord.finish.diagnosis.diagnosis.value}
                            </strong>
                          </div>
                          <h6>
                            {' '}
                            <div class='d-flex align-items-center '>
                              {e.caseRecord &&
                                e.caseRecord.finish &&
                                e.caseRecord.finish.diagnosis &&
                                e.caseRecord.finish.diagnosis.diagnosis &&
                                e.caseRecord.finish.diagnosis.diagnosis.map(
                                  eds => (
                                    <>
                                      <strong>{eds.value}</strong>
                                      <i
                                      data-tooltip-id='followup-tooltip1'
                                        data-tooltip-content={eds.diagnosis}
                                        class='fas fa-info-circle mr-2'
                                        aria-hidden='true'></i>
                                    </>
                                  )
                                )}
                            </div>
                          </h6>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
        {this.state.id && (
          <FollowupForm
            caseRecord={this.state.caseRecord}
            appointment_id={this.state.appointment_id}
            patient_id={this.state.patient_id}
            yesmindy_identification_number={
              this.state.yesmindy_identification_number
            }
            sex={this.state.sex}
            age={this.state.age}
            toggle1={this.toggle1}
            first_name={this.state.first_name}
            last_name={this.state.last_name}
            image={this.state.image}
            star={this.state.star}
            time={this.state.time || this.props.time}
            isShow2={this.state.isShow1}
            toggle2={this.toggle1}
            id={this.state.id}
            loadList={this.props.loadList}
            appointment_date={this.props.appointment_date}
            appointment_date_video={this.props.appointment_date_video}
            video={this.props.video}
          />
        )}
        <div
          className={
            this.state.isShow1 === true ? 'overlay4 show' : 'overlay4'
          }></div>
        <Modal
          aria-labelledby='contained-modal-title-vcenter'
          centered
          dialogClassName='modal-dialog modal-dialog-centered modal-sm'
          className='modal fade '
          show={this.state.popup}>
          <>
            <div class='modal-header'>
              <button
                onClick={() => {
                  this.setState({ popup: false })
                }}
                type='button'
                class='close'
                data-dismiss='modal'>
                <img src={closeIcon} alt='close popup' />
              </button>
            </div>
            <div class='modal-body'>
              <p>Please fill Case Record Form first.</p>
              <div class='modal-action-btn'>
                <a
                  href='#!'
                  class='btn btn-outline no-shadow'
                  data-dismiss='modal'
                  aria-label='Close'
                  onClick={e => {
                    e.preventDefault()
                    this.setState({ popup: false })
                  }}>
                  Ok
                </a>
              </div>
            </div>
          </>
        </Modal>
      </>
    )
  }
}
const mapDispatchToProps = dispatch => ({
  loadList: params => dispatch(loadList(params)),
})
const mapStateToProps = state => ({
  list: getPrescription(state).list,
  doctorProfile: getDoctor(state).profile,
})
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Followup)
)
